import React from "react";
import { Room } from "../models/Room";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import NoteIcon from "@material-ui/icons/Note";
import WarningIcon from "@material-ui/icons/Warning";

import PersonIcon from "@material-ui/icons/Person";
import { lightGreen, amber, grey } from "@material-ui/core/colors";

export const getLockedIcon = (room: Room): JSX.Element | null => {
  switch (room.locked) {
    case true:
      return <LockIcon style={{ color: amber[500] }} />;
    case false:
      return <LockOpenIcon style={{ color: lightGreen[500] }} />;
    case null:
      return null;
  }
};

export const getOccupiedIcon = (room: Room): JSX.Element | null => {
  return room.occupied ? <PersonIcon style={{ color: grey[600] }} /> : null;
};

export const getTicketIcon = (room: Room): JSX.Element | null => {
  return room.unresolved_tickets_count > 0 ? (
    <WarningIcon style={{ color: "#ff5722" }} />
  ) : null;
};

export const getFieldNoteIcon = (room: Room): JSX.Element | null => {
  return room.getFieldNotes().length > 0 ? <NoteIcon /> : null;
};
