import Button from "@material-ui/core/Button";
import { getImageUrl } from "../../helpers/paperclip_helpers";
import Box from "@material-ui/core/Box";
import { store } from "../../init_app/globals";

const { setPartialState } = store.getScopedHelpers("bonus_buck_modal");

type BonusBuckPhotoRowProps = {
  image_file_name: string;
  id: number;
  working: boolean;
};

const renderImg = (
  id: number,
  image_file_name: string | null
): JSX.Element | null => {
  if (image_file_name == null) {
    return null;
  }

  const thumb = getImageUrl(id, "bonus_bucks", "thumb", image_file_name);
  const original = getImageUrl(id, "bonus_bucks", "original", image_file_name);

  return (
    <a href={original} target="_blank" rel="noopener noreferrer">
      <img alt="" width="50" src={thumb} style={{ marginRight: 5 }} />
    </a>
  );
};

export const BonusBuckModalPhotoRow = (
  props: BonusBuckPhotoRowProps
): JSX.Element => {
  const { working, id, image_file_name } = props;
  // const thumb = getImageUrl(id, "bonus_bucks", "thumb", image_file_name);

  return (
    <Box display="flex" className="mt-3 mb-1">
      {renderImg(id, image_file_name)}
      <Button
        size="small"
        className="ms-4"
        onClick={(): void => setPartialState({ image_file_name: null })}
        variant="outlined"
        disabled={working}
      >
        Clear photo
      </Button>
    </Box>
  );
};
