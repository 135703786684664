import sortBy from "lodash/sortBy";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";
import groupBy from "lodash/groupBy";
import { User } from "../../models/User";
import { getActiveUsers } from "../../util/app_util";
import { getModels, router, store } from "../../init_app/globals";
import { unwrapOr } from "../../util/util";
import WorkerTabs from "./WorkerTabs";

const clickRow = (user: User): void => {
  if (store.getNonNullState("currentUser").admin) {
    router.go(`/workers/${user.id}`);
  }
};

const renderWorker = (user: User): JSX.Element => {
  const key = "workuh_row" + user.id;

  return (
    <div
      key={key}
      style={{ height: 50 }}
      onClick={(): void => {
        clickRow(user);
      }}
      className="pl-2 d-flex align-items-center justify-content-between list-group-item"
    >
      <div className="d-flex">{user.workerName()}</div>

      <Link href={`tel:${user.phone}`} className="mx-3">
        <PhoneIcon />
      </Link>
    </div>
  );
};

export const Workers = (): JSX.Element => {
  const tab = store.useNonNullState("tab");
  const currentUser = store.getNonNullState("currentUser");

  if (currentUser.admin) {
    const groupedWorkers = groupBy(getModels("users"), (u) =>
      u.active ? "active" : "inactive"
    ) as Record<"active" | "inactive", User[]>;

    let workers: User[];

    const active_workers_count = groupedWorkers.active.length;
    const inactive_workers_count = unwrapOr(groupedWorkers.inactive, []).length;

    const props = { tab, active_workers_count, inactive_workers_count };

    if (tab === 0) {
      workers = groupedWorkers.active;
    } else {
      workers = groupedWorkers.inactive;
    }

    workers = sortBy(workers, (w) => w.lowerName());

    return (
      <div className="fit">
        <WorkerTabs {...props} />
        {workers.map(renderWorker)}
      </div>
    );
  } else {
    const workers = sortBy(getActiveUsers(), (w) => w.lowerName());

    return <div className="fit">{workers.map(renderWorker)}</div>;
  }
};
