import { Box, Link } from "@material-ui/core";
import { sortInstallsByShort } from "../../helpers/install_helpers";
import { Reservation } from "../../models/Reservation";
import { isIos, simpleDate } from "../../util/app_util";
import PhoneIcon from "@material-ui/icons/Phone";
import MessageIcon from "@material-ui/icons/Message";
import InstallButton from "./InstallButton";
import { Room } from "../../models/Room";

export const ReservationRow = ({
  reservation,
  room,
}: {
  reservation: Reservation;
  room: Room;
}): JSX.Element | null => {
  const key = `reservation_row_${reservation.id}`;

  let installs = room.installs!.filter(
    (i) => i.reservation_id === reservation.id
  );

  if (installs.length === 0) {
    return null;
  }

  installs = sortInstallsByShort(installs);

  const student = reservation.studentDeets();

  const school = room.building.school;
  const school_name = school.short === "wm" ? `William %26 Mary` : school.name;
  const msg = `Hi ${
    student.first_name
  }, this is DormsDirect 👋.  We are contacting you about your rental items for ${room
    .roomStr()
    .replace(",", "")} at ${school_name}`;

  const msgBody = isIos() ? `&body=${msg}` : `?body=${msg}`;

  return (
    <div key={key} className="mt-4">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div className="d-flex align-items-center">
          products for {student.full_name}{" "}
          <span className="ms-1">({simpleDate(student.vacancy)})</span>
        </div>

        <div>
          <Link href={`sms:${student.phone}`} className="mx-3">
            <PhoneIcon />
          </Link>
          <Link href={`sms:${student.phone}${msgBody}`} className="mx-3">
            <MessageIcon />
          </Link>
        </div>
      </Box>

      <div className="d-flex flex-wrap">
        {installs.map((i) => (
          <InstallButton
            className="mx-1 mt-2"
            key={`install_button_${i.id}`}
            install={i}
          />
        ))}
      </div>
    </div>
  );
};
