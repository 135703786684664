import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import blue from "@material-ui/core/colors/blue";
import lime from "@material-ui/core/colors/lime";
import { AppDrawer } from "./components/AppDrawer";
import { Main } from "./components/Main";
import { store } from "./init_app/globals";

const App = (): JSX.Element => {
  const colormode = store.useState("colormode");
  const theme = createMuiTheme({
    palette: {
      type: colormode,
      primary: blue,
      secondary: lime,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Main />

      <AppDrawer />
    </ThemeProvider>
  );
};

export default App;
