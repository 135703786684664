import { Install } from "../../models/Install";
import Button from "@material-ui/core/Button";
import { getColor } from "../../helpers/install_view_helpers";
import { store } from "../../init_app/globals";
import { getInstallActionModalState } from "../../helpers/install_helpers";

type InstallButtonProps = {
  install: Install;
  className?: string;
};

const clickInstall = (i: Install): void => {
  const state = getInstallActionModalState(i);
  store.setState("install_action_modal", state);
};

const InstallButton = (props: InstallButtonProps): JSX.Element => {
  const color = getColor(props.install.status);

  const style = {
    color: store.state.colormode === "dark" ? "white" : "black",
    borderColor: color,
    backgroundColor: color,
  };

  return (
    <Button
      style={style}
      variant="contained"
      className="mx-1 mt-1"
      onClick={(): void => clickInstall(props.install)}
    >
      {props.install.getShort()}
    </Button>
  );
};

export default InstallButton;
