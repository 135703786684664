import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import sortBy from "lodash/sortBy";
import { User } from "../../models/User";
import { getActiveUsers } from "../../util/app_util";

export const renderUser = (u: User, label: string): JSX.Element => {
  const key = `user-${u.id}-option-${label}`;

  return (
    <option key={key} value={u.id}>
      {u.selectName()}
    </option>
  );
};

type UserIdSelectProps = {
  user_id: number;
  onChange: (user_id: number) => void;
  label: string;
};

export default function UserIdSelect(props: UserIdSelectProps): JSX.Element {
  const { user_id, onChange, label } = props;

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const id = parseInt(e.target.value as string);

    onChange(id);
  };

  const users = sortBy(getActiveUsers(), (u) => u.username);

  return (
    <div>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel>{label}</InputLabel>
        <Select native value={user_id} onChange={handleChange}>
          {users.map((w) => renderUser(w, label))}
        </Select>
      </FormControl>
    </div>
  );
}
