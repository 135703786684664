import Button from "@material-ui/core/Button";
import strftime from "strftime";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { TruckLoad } from "../../models/TruckLoad";
import Typography from "@material-ui/core/Typography";
import sortBy from "lodash/sortBy";
import { andJoin } from "../../util/util";
import { getModels, router, store } from "../../init_app/globals";
import { setTruckLoadModalState } from "../../helpers/truck_load_helpers";
import TruckLoadModal from "./TruckLoadModal";
import { User } from "../../models/User";

const clickNewTruckLoad = (): void => {
  setTruckLoadModalState(null);
};

const clickRow = (truck_load: TruckLoad, user?: User): void => {
  if (user != null) {
    router.go(`/workers/${user.id}/truck_loads/${truck_load.id}`);
  } else {
    router.go(`/truck_loads/${truck_load.id}`);
  }
};

export const renderTruckLoadRow = (
  truck_load: TruckLoad,
  user?: User
): JSX.Element => {
  const date = strftime("%-m/%-d/%y ~ %l:%M %p", truck_load.performed_at);

  const workers = truck_load.getWorkers();

  const workerNames = andJoin(workers.map((w) => w.lowerName()));

  const verb = truck_load.type === "load" ? "loaded truck" : "unloaded truck";

  return (
    <div
      key={"truck_load_" + truck_load.id}
      onClick={(): void => {
        clickRow(truck_load, user);
      }}
      className="truck_load-row next-bottom-border list-group-item d-flex justify-content-between align-items-center p-1"
    >
      <div>
        <span className="fw-500">{truck_load.type}:</span> {workerNames} {verb}{" "}
        {truck_load.total()} products on {date}
      </div>
      <ArrowForwardIcon />
    </div>
  );
};

function TruckLoadList(): JSX.Element {
  let truck_loads = sortBy(getModels("truck_loads"), (tl) => -tl.performed_at);

  const currentUser = store.getNonNullState("currentUser");

  if (!currentUser.admin) {
    truck_loads = truck_loads.filter(
      (d) =>
        d.creator_id === currentUser.id || d.worker_ids.includes(currentUser.id)
    );
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <Typography variant="h6">
          Load Tickets ({truck_loads.length})
        </Typography>

        <Button onClick={clickNewTruckLoad} variant="outlined">
          New Load Ticket
        </Button>
      </div>

      <div id="truck_loads" className="mt-3">
        {truck_loads.map((tl) => renderTruckLoadRow(tl))}
      </div>
      <TruckLoadModal />
    </div>
  );
}

export default TruckLoadList;
