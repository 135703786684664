import { pathToRegexp } from "path-to-regexp";
import { PlainObject } from "../types/types";
import { shallowEqual, then } from "../util/util";

export type RouteMap<T> = {
  [route: string]: (...params: string[]) => T;
};

type Props<T> = {
  routeMappers: RouteMap<T>;
  handler: (state: T) => void;
  badRouterHandler: (path?: string) => T;
};

export default class StateRouter<T> {
  private regexpMap: [RegExp, (...params: string[]) => T][];
  private badRouterHandler: (path?: string) => T;
  private handler: (state: T) => void;

  constructor({ routeMappers, handler, badRouterHandler }: Props<T>) {
    this.regexpMap = Object.entries(routeMappers).map(([path, fn]) => [
      pathToRegexp(path),
      fn,
    ]);
    this.handler = handler;
    this.badRouterHandler = badRouterHandler;

    window.onpopstate = (e: PopStateEvent): void => {
      const state = this.stateFromPath(window.location.pathname);
      this.handler(state);
    };
  }

  replace(path: string): void {
    const state = this.stateFromPath(path);
    const currentState = then(window.history.state, (s) =>
      s == null
        ? (this.stateFromPath(window.location.pathname) as
            | PlainObject
            | undefined)
        : s
    );

    if (state == null) {
      this.badRouterHandler(path);
      return;
    }

    if (currentState == null || !shallowEqual(state, currentState)) {
      window.history.replaceState(state, "", path);

      if (state == null) {
        this.badRouterHandler(path);
      } else {
        this.handler(state);
      }
    }
  }

  go(path: string): void {
    const state = this.stateFromPath(path);
    const currentState = then(window.history.state, (s) =>
      s == null
        ? (this.stateFromPath(window.location.pathname) as
            | PlainObject
            | undefined)
        : s
    );

    if (state == null) {
      this.badRouterHandler(path);
      return;
    }

    if (currentState == null || !shallowEqual(state, currentState)) {
      window.history.pushState(state, "", path);

      if (state == null) {
        this.badRouterHandler(path);
      } else {
        this.handler(state);
      }
    }
  }

  stateFromPath(path: string): T {
    const arr = this.regexpMap.find((m) => m[0].test(path));
    if (!arr) {
      return this.badRouterHandler(path);
    }

    const [regexp, stateMapper] = arr;

    const execArr = regexp.exec(path);
    const params = execArr ? execArr.slice(1, execArr.length) : null;

    return params ? stateMapper(...params) : stateMapper();
  }
}
