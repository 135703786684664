import { PlainObject } from "../types/types";
import intersection from "lodash/intersection";

export const doesIntersect = function (
  arr1: Array<unknown>,
  arr2: Array<unknown>
): boolean {
  return intersection(arr1, arr2).length > 0;
};

/**
 * transform a value via callback
 *
 * @doctest
 * ```js
 * t.is(then(1, (n) => n + 1), 2)
 * ```
 */
export const then = <A, B>(a: A, cb: (a: A) => B): B => {
  return cb(a);
};

/**
 * splice an item from an array; mutating; item must be in array
 *
 * @doctest
 * ```js
 * const arr = [1, 2, 3];
 * spliceFromArray(arr, 2);
 * t.deepEqual(arr, [1, 3]);
 * ```
 */
export const spliceFromArray = <T>(arr: Array<T>, item: T): void => {
  const index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
  } else {
    throw new Error(`item wasnt in array`);
  }
};

/**
 * shallow equal comparison
 *
 * @doctest
 * ```js
 * t.true(shallowEqual({a: 1}, {a: 1}))
 * t.false(shallowEqual({a: 2}, {a: 1}))
 * t.false(shallowEqual({a: 1, b: 2}, {a: 1}))
 * ```
 */
export function shallowEqual(a: PlainObject, b: PlainObject): boolean {
  const keys1 = Object.keys(a);
  const keys2 = Object.keys(b);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

export const unwrap = <T>(
  value: T | undefined | null,
  errorMessage?: string
): T => {
  if (value == null) {
    throw new Error(
      errorMessage == null ? "Value should be here!" : errorMessage
    );
  } else {
    return value;
  }
};

export const unwrapOr = <A>(a: A | null | undefined, b: A): A => {
  if (a == null) {
    return b;
  } else {
    return a;
  }
};

export const unwrap2Or = <A>(
  a: A | null | undefined,
  b: A | null | undefined,
  c: A
): A => {
  if (a == null) {
    if (b == null) {
      return c;
    }
    return b;
  }

  return a;
};

export const isPresent = function (str?: string | null): boolean {
  if (str == null || str.trim() === "") {
    return false;
  }

  return true;
};

// andJoin(['a', 'b', 'c'])  => 'a, b & c'
export const andJoin = function (
  arr: string[],
  delimiter = "&",
  w_html = false
): string {
  const len = arr.length;

  if (len === 0) return "";
  if (len === 1) return arr[0]!;
  if (len === 2) return `${arr[0]} ${delimiter} ${arr[1]}`;

  delimiter = w_html
    ? `<span class='and-join-delim'>${delimiter}</span>`
    : delimiter;

  return arr
    .map((a, i) => {
      return i === len - 2 ? `${a} ${delimiter} ` : i < len - 1 ? `${a}, ` : a;
    })
    .join("");
};

export const myRound = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const parse4StateBoolStr = (str: string): boolean | null | undefined => {
  switch (str) {
    case "true":
      return true;
    case "false":
      return false;
    case "null":
      return null;
    case "undefined":
      return undefined;
    default:
      return undefined;
  }
};

export const areSameDay = function (date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth()
  );
};

export const centsToDollarString = function (cents: number): string {
  const dollars = cents / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const zeroPad = (n: number): string => {
  const zero = n < 10 ? "0" : "";
  return `${zero}${n}`;
};

/**
 * get YYYY-MM-DD formatted date string
 *
 * @doctest
 * ```js
 * const d = new Date();
 * d.setFullYear(2018);
 * // 1 === feb in JS world
 * d.setMonth(1);
 * d.setDate(21);
 * t.is(getDateStr(d), '2018-02-21');
 * ```
 */
export const getDateStr = (d: Date): string => {
  const year = d.getFullYear();
  const month = zeroPad(d.getMonth() + 1);
  const day = zeroPad(d.getDate());

  return `${year}-${month}-${day}`;
};

/**
 * get date from YYYY-MM-DD formatted date string
 *
 * @doctest
 * ```js
 * const d = getDateFromDateStr("2020-02-19");
 * t.is(d.getFullYear(), 2020);
 * t.is(d.getMonth(), 1);
 * t.is(d.getDate(), 19);
 * ```
 */
export const getDateFromDateStr = (str: string): Date => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_year, month, day] = str.split("-").map((n) => parseInt(n));
  const date = new Date();
  date.setFullYear(2022);
  date.setMonth(month! - 1);
  date.setDate(day!);
  date.setHours(12);

  return date;
};

export function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max);
}
