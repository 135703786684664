import sortBy from "lodash/sortBy";
import { getModels } from "../init_app/globals";
import { unwrapOr, then, unwrap } from "../util/util";
import { User } from "./User";

export type PayscaleJSON = {
  id: number;
  label: string;
  type: PayscaleType;
  group: PayscaleGroup;
  bl_cents: number | null;
  pm_cents: number | null;
  mf_cents: number | null;
  sr_cents: number | null;
  ft_cents: number | null;
  bs_cents: number | null;
  lbx_cents: number | null;
  ffc_cents: number | null;
  tv_cents: number | null;
  mf7_cents: number | null;
  mf3_cents: number | null;
  ffs_cents: number | null;
  mfs_cents: number | null;
  lof_cents: number | null;
  created_at: string;
};

export type PayscaleType =
  | "uninstalled"
  | "removed"
  | "dropoff"
  | "pickup"
  | "driver"
  | "fridge_cleaning";

export type PayscaleGroup = "basic" | "brich" | "midyear";
export const payscale_groups: PayscaleGroup[] = ["basic", "brich", "midyear"];

export const payscale_types: PayscaleType[] = [
  "uninstalled",
  "removed",
  "pickup",
  "driver",
  "dropoff",
  "fridge_cleaning",
];

export interface ShortCents {
  [short: string]: number;
}

export const orderPayscalesByType = (payscales: Payscale[]): Payscale[] => {
  return sortBy(payscales, (ps) => payscale_types.indexOf(ps.type));
};

export class Payscale {
  id: number;
  label: string;
  type: PayscaleType;
  group: PayscaleGroup;
  short_cents: ShortCents;
  created_at: Date;

  constructor(json: PayscaleJSON) {
    this.id = json.id;
    this.label = json.label;
    this.type = json.type;
    this.group = json.group;
    this.short_cents = {
      BL: unwrapOr(json.bl_cents, 0),
      SR: unwrapOr(json.sr_cents, 0),
      MF: unwrapOr(json.mf_cents, 0),
      FT: unwrapOr(json.ft_cents, 0),
      BS: unwrapOr(json.bs_cents, 0),
      LBX: unwrapOr(json.lbx_cents, 0),
      FFC: unwrapOr(json.ffc_cents, 0),
      PM: unwrapOr(json.pm_cents, 0),
      TV: unwrapOr(json.tv_cents, 0),
      MF7: unwrapOr(json.mf7_cents, 0),
      MF3: unwrapOr(json.mf3_cents, 0),
      MFS: unwrapOr(json.mfs_cents, 0),
      FFS: unwrapOr(json.ffs_cents, 0),
      LOF: unwrapOr(json.lof_cents, 0),
    };
    this.created_at = new Date(json.created_at + "Z");
  }

  static find(type: PayscaleType, user: User): Payscale {
    const is_install_action = type === "uninstalled" || type === "removed";

    const group = then(is_install_action, (ia): PayscaleGroup => {
      if (ia) {
        if (user.id === 15) return "brich";
      }

      return "basic";
    });

    const payscale = getModels("payscales").find(
      (ps) => ps.type === type && ps.group === group
    );

    if (payscale == null) {
      throw new Error(`couldnt find payscale of type ${type} and group basic`);
    }

    return payscale;
  }

  getCents(short: string): number {
    return unwrap(this.short_cents[short]);
  }
}
