import Button from "@material-ui/core/Button";
import { spliceFromArray } from "../../util/util";
import { colors } from "../../constants/colors";
import { getModels, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { isErrorResponse } from "../../util/app_util";
import { findModelById } from "../../util/data_util";

const { setPartialState } = store.getScopedHelpers("paycheck_modal");

const destroyPaycheck = (paycheck_id: number, working: boolean): void => {
  if (working) {
    return;
  }

  const request = new ApiRequest({
    url: `/worker_api/paychecks/${paycheck_id}.json`,
    method: "DELETE",
    onSuccess: (): void => {
      const allPaychecks = getModels("paychecks");
      const paycheck = findModelById(allPaychecks, paycheck_id);
      spliceFromArray(allPaychecks, paycheck);

      store.state.paycheck_modal = undefined;
      store.forceUpdate("user");
    },
    onError: (json: unknown): void => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors, working: false });
      } else {
        alert("some funky error occurred");
      }
    },
  });
  request.perform();
};

interface PaycheckModalDestroyButtonProps {
  working: boolean;
  paycheck_id: number;
}

const PaycheckModalDestroyButton = (
  props: PaycheckModalDestroyButtonProps
): JSX.Element => {
  return (
    <Button
      style={{ color: colors.danger, borderColor: colors.danger }}
      onClick={(): void => destroyPaycheck(props.paycheck_id, props.working)}
      variant="outlined"
      className="next-button"
      size="large"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default PaycheckModalDestroyButton;
