export type ProductJSON = {
  id: number;
  name: string;
  short: string;
  short_short: string;
};

export class Product {
  id: number;
  name: string;
  short: string;
  short_short: string;

  constructor(json: ProductJSON) {
    this.id = json.id;
    this.name = json.name;
    this.short = json.short;
    this.short_short = json.short_short;
  }
}
