/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Button from "@material-ui/core/Button";
import { getModels, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { Paycheck, PaycheckJSON } from "../../models/Paycheck";
import { SentPaycheckJSON } from "../../types/json_types";
import { isErrorResponse } from "../../util/app_util";
import { findModelById } from "../../util/data_util";

const { getNonNullState, setPartialState } = store.getScopedHelpers(
  "paycheck_modal"
);

type PaycheckResp = {
  paycheck: PaycheckJSON;
};

const update = (paycheck_id: number): void => {
  const state = getNonNullState();

  if (state.working) {
    return;
  }

  const data: SentPaycheckJSON = {
    date_at: state.date_at,
    type: state.type,
    notes: state.notes,
    check_number: state.check_number,
    amount: state.amount?.toString() || null,
    payee_id: state.payee_id,
  };

  ApiRequest.perform({
    url: `/worker_api/paychecks/${paycheck_id}.json`,
    method: "PUT",
    onSuccess: (resp: unknown): void => {
      const allPaychecks = getModels("paychecks");
      const paycheck = findModelById(allPaychecks, paycheck_id);

      const json = resp as PaycheckResp;
      const nextPaycheck = new Paycheck(json.paycheck);

      const index = allPaychecks.indexOf(paycheck);
      allPaychecks[index] = nextPaycheck;

      store.state.paycheck_modal = undefined;
      store.forceUpdate("viewShown");
    },
    onError: (json: unknown): void => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors });
      } else {
        alert("some funky error occurred");
      }
    },
    data: { paycheck: data },
  });
};

const create = (): void => {
  const state = getNonNullState();

  if (state.working) {
    return;
  }

  const data: SentPaycheckJSON = {
    date_at: state.date_at,
    type: state.type,
    notes: state.notes,
    check_number: state.check_number,
    amount: state.amount?.toString() || null,
    payee_id: state.payee_id,
  };

  ApiRequest.perform({
    url: "/worker_api/paychecks.json",
    method: "POST",
    onSuccess: (resp: unknown) => {
      const json = resp as PaycheckResp;
      const paycheck = new Paycheck(json.paycheck);
      getModels("paychecks").push(paycheck);

      store.state.paycheck_modal = undefined;
      store.forceUpdate("viewShown");
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors, working: false });
      } else {
        alert("some funky error occurred");
      }
    },
    data: {
      paycheck: data,
    },
  });
};

interface PaycheckSaveButtonProps {
  id: number | null;
  working: boolean;
}

const PaycheckSaveButton = (props: PaycheckSaveButtonProps): JSX.Element => {
  const { id, working } = props;
  return (
    <Button
      onClick={(): void => (id ? update(id) : create())}
      className="next-button"
      variant="outlined"
      size="large"
      disabled={working}
    >
      Save
    </Button>
  );
};

export default PaycheckSaveButton;
