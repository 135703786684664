import { CSSProperties } from "react";
import Box from "@material-ui/core/Box";
import { renderShort } from "../../helpers/install_view_helpers";
import { Room } from "../../models/Room";
import { router, store } from "../../init_app/globals";
import {
  getLockedIcon,
  getTicketIcon,
  getOccupiedIcon,
  getFieldNoteIcon,
} from "../../helpers/icon_helpers";

const clickRoom = (room_id: number): void => {
  const search = store.state.search;
  const path =
    search == null || search.trim() === ""
      ? `/jobs/${room_id}`
      : `/jobs/${room_id}/search/${search}`;

  router.go(path);
};

type RoomRowProps = {
  style: CSSProperties;
  room: Room;
};

export type DataJSON = {
  title: string;
  subtitle: string;
  subsubtitle: string;
};

const getVacancyDates = (room: Room): JSX.Element | null => {
  const reservations = room.getReservations();
  if (reservations == null) {
    return null;
  }

  return (
    <span className="d-flex align-items-center mx-1" style={{ fontSize: 11 }}>
      {reservations
        .map((r) => {
          const dd = r.departure_date;
          return `${dd.getMonth() + 1}/${dd.getDate()}`;
        })
        .join(" & ")}
    </span>
  );
};

export const RoomRow = ({ style, room }: RoomRowProps): JSX.Element => {
  const installs = room.installs!;

  return (
    <Box
      onClick={(): void => clickRoom(room.id)}
      className="pl-2 light-bottom-border"
      style={style}
      data-title={room.building.shown_name}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      <div className="mt-1">{room.wingName()}</div>
      {getFieldNoteIcon(room)}
      {getVacancyDates(room)}
      {getLockedIcon(room)}
      {getTicketIcon(room)}
      {getOccupiedIcon(room)}
      {installs.map(renderShort)}
    </Box>
  );
};
