import { counts } from "../constants/shorts";
import { getModels } from "../init_app/globals";
import { findModelsByIdInclusion } from "../util/app_util";
import { Building } from "./Building";
import { Payscale } from "./Payscale";
import { School } from "./School";
import { User } from "./User";
import { Warehouse } from "./Warehouse";

export type TruckLoadType = "unload" | "load";

export type TruckLoadJSON = {
  id: number;
  bl_count: number;
  mf_count: number;
  sr_count: number;
  ft_count: number;
  dft_count: number;
  bs_count: number;
  ffc_count: number;
  lbx_count: number;
  pm_count: number;
  tv32_count: number;
  tv40_count: number;
  mf7_count: number;
  mf3_count: number;
  mfs_count: number;
  ffs_count: number;
  lof_count: number;
  notes: string;
  building_ids: number[];
  worker_ids: number[];
  creator_id: number;
  school_id: number;
  driver_id: number | null;
  driver: User | null;
  warehouse_id: number | null;
  type: TruckLoadType;
  performed_at: Date;
};

export class TruckLoad {
  id: number;
  bl_count: number;
  mf_count: number;
  sr_count: number;
  ft_count: number;
  dft_count: number;
  bs_count: number;
  ffc_count: number;
  lbx_count: number;
  pm_count: number;
  tv32_count: number;
  tv40_count: number;
  mf7_count: number;
  mf3_count: number;
  mfs_count: number;
  ffs_count: number;
  lof_count: number;
  notes: string;
  building_ids: number[];
  worker_ids: number[];
  creator_id: number;
  creator: User;
  school_id: number;
  school: School;
  driver_id: number | null;
  driver: User | null;
  warehouse_id: number | null;
  warehouse: Warehouse | null;
  type: TruckLoadType;
  performed_at: Date;
  namey: "TruckLoad";

  constructor(
    json: TruckLoadJSON,
    school: School,
    creator: User,
    driver: User | null,
    warehouse: Warehouse | null
  ) {
    this.id = json.id;
    this.bl_count = json.bl_count || 0;
    this.ffc_count = json.ffc_count || 0;
    this.mf_count = json.mf_count || 0;
    this.sr_count = json.sr_count || 0;
    this.ft_count = json.ft_count || 0;
    this.dft_count = json.dft_count || 0;
    this.bs_count = json.bs_count || 0;
    this.lbx_count = json.lbx_count || 0;
    this.pm_count = json.pm_count || 0;
    this.tv32_count = json.tv32_count || 0;
    this.tv40_count = json.tv40_count || 0;
    this.mf7_count = json.mf7_count || 0;
    this.mf3_count = json.mf3_count || 0;
    this.mfs_count = json.mfs_count || 0;
    this.ffs_count = json.ffs_count || 0;
    this.lof_count = json.lof_count || 0;
    this.notes = json.notes;
    this.building_ids = json.building_ids;
    this.worker_ids = json.worker_ids;
    this.creator_id = json.creator_id;
    this.creator = creator;
    this.school_id = json.school_id;
    this.driver_id = json.driver_id;
    this.driver = driver;
    this.warehouse_id = json.warehouse_id;
    this.warehouse = warehouse;
    this.school = school;
    this.type = json.type;
    this.performed_at = new Date(json.performed_at + "Z");
    this.namey = "TruckLoad";
  }

  getWorkers(): User[] {
    const users = getModels("users");
    return findModelsByIdInclusion(users, this.worker_ids);
  }

  getBuildings(): Building[] {
    const buildings = getModels("buildings");
    return findModelsByIdInclusion(buildings, this.building_ids);
  }

  individualCents(payscale: Payscale): number {
    return this.totalCents(payscale) / this.worker_ids.length;
  }

  loadedTruck(user: User): boolean {
    return this.worker_ids.includes(user.id);
  }

  workedTruckLoad(user: User): boolean {
    return this.driver_id === user.id || this.loadedTruck(user);
  }

  totalCents(payscale: Payscale): number {
    return (
      this.bl_count * payscale.getCents("BL") +
      this.mf_count * payscale.getCents("MF") +
      this.sr_count * payscale.getCents("SR") +
      this.ft_count * payscale.getCents("FT") +
      this.dft_count * payscale.getCents("FT") +
      this.bs_count * payscale.getCents("BS") +
      this.lbx_count * payscale.getCents("LBX") +
      this.ffc_count * payscale.getCents("FFC") +
      this.pm_count * payscale.getCents("PM") +
      this.tv32_count * payscale.getCents("TV") +
      this.tv40_count * payscale.getCents("TV") +
      this.mf7_count * payscale.getCents("MF7") +
      this.mf3_count * payscale.getCents("MF3") +
      this.mfs_count * payscale.getCents("MFS") +
      this.ffs_count * payscale.getCents("FFS") +
      this.lof_count * payscale.getCents("LOF")
    );
  }

  total(): number {
    let sum = 0;
    for (let index = 0; index < counts.length; index++) {
      const count = counts[index]!;
      sum += this[count];
    }

    return sum;
  }
}
