import Typography from "@material-ui/core/Typography";
import strftime from "strftime";
import { Box } from "@material-ui/core";
import PendingOutlinedIcon from "@material-ui/icons/PendingOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutline";
import PendingIcon from "@material-ui/icons/Pending";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import CameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import sortBy from "lodash/sortBy";
import { andJoin, isPresent, centsToDollarString } from "../../util/util";
import { getModels, store } from "../../init_app/globals";
import { BonusBuck } from "../../models/BonusBuck";
import BonusBuckModal from "./BonusBuckModal";
import IconButton from "@material-ui/core/IconButton";

const { setState } = store.getScopedHelpers("bonus_buck_modal");

const clickAdd = (): void => {
  const state = BonusBuck.getModalState(
    null,
    store.getNonNullState("currentUser")
  );

  setState(state);
};

const clickRow = (bb: BonusBuck): void => {
  const currentUser = store.getNonNullState("currentUser");

  if (currentUser.admin || currentUser.id === bb.creator_id) {
    const state = BonusBuck.getModalState(bb, null);

    setState(state);
  }
};

const renderConfirmed = (confirmed: boolean): JSX.Element | null => {
  return confirmed ? null : <span className="bold italic me-2">PENDING:</span>;
};

const renderPhotoIcon = (
  image_file_name: string | null
): JSX.Element | null => {
  return image_file_name == null ? null : <CameraIcon />;
};

const clickPending = (): void => {
  const next = store.state.showPending !== true;

  store.setState("showPending", next);
};

const clickPhotos = (): void => {
  const next = store.state.showPhotos !== true;

  store.setState("showPhotos", next);
};

export const renderBB = (bb: BonusBuck): JSX.Element => {
  const date = strftime("%-m/%-d/%y", bb.performed_at);
  const users = andJoin(bb.getWorkers().map((u) => u.lowerName()));
  const reason = isPresent(bb.reason) ? bb.reason : "reasons";
  const each_cents = bb.individualDollars() * 100;

  return (
    <Box
      key={`bb_${bb.id}`}
      className="next-bottom-border"
      style={{ minHeight: 50 }}
      display="flex"
      alignItems="center"
      onClick={(): void => clickRow(bb)}
    >
      <div className="d-flex align-items-center">
        {renderPhotoIcon(bb.image_file_name)}
        {renderConfirmed(bb.confirmed)}
        {users} received ${bb.dollars} on {date} for {reason} (
        {centsToDollarString(each_cents)} each)
      </div>
    </Box>
  );
};

const BonusBucks = (): JSX.Element => {
  let bonus_bucks = sortBy(getModels("bonus_bucks"), (bb) => -bb.performed_at);

  const showPending = store.useState("showPending") === true;
  const showPhotos = store.useState("showPhotos") === true;

  const currentUser = store.getNonNullState("currentUser");

  if (!currentUser.admin) {
    bonus_bucks = bonus_bucks.filter(
      (bb) =>
        bb.creator_id === currentUser.id ||
        bb.worker_ids.includes(currentUser.id)
    );
  }

  if (showPending) {
    bonus_bucks = bonus_bucks.filter((bb) => !bb.confirmed);
  }

  if (showPhotos) {
    bonus_bucks = bonus_bucks.filter((bb) => bb.image_file_name != null);
  }

  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex">
          <IconButton size="medium" onClick={clickPending}>
            {showPending ? <PendingIcon /> : <PendingOutlinedIcon />}
          </IconButton>

          <IconButton size="medium" onClick={clickPhotos}>
            {showPhotos ? <CameraIcon /> : <CameraOutlinedIcon />}
          </IconButton>
        </div>
        Bonus Bucks ({bonus_bucks.length})
        <div className="d-flex">
          <div style={{ width: 48 }}></div>
          <IconButton size="medium" onClick={clickAdd}>
            <AddCircleOutlinedIcon />
          </IconButton>
        </div>
      </Typography>

      {bonus_bucks.map(renderBB)}

      <BonusBuckModal />
    </div>
  );
};

export default BonusBucks;
