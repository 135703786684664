import { FormControlLabel } from "@material-ui/core";
import { User } from "../../models/User";
import { InstallAction, InstallActionType } from "../../models/InstallAction";
import capitalize from "lodash/capitalize";
import { install_status_colors } from "../../constants/colors";
import { withStyles, CheckboxProps, Checkbox } from "@material-ui/core";
import strftime from "strftime";
import MultiUserIdSelect from "./MultiIdUserSelect";
import { findModelById } from "../../util/data_util";
import UserIdSelect from "../selects/UserIdSelect";
import { getModels } from "../../init_app/globals";

const UninstalledCheckbox = withStyles({
  root: {
    color: install_status_colors.uninstalled,
    "&$checked": {
      color: install_status_colors.uninstalled,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const RemovedCheckbox = withStyles({
  root: {
    color: install_status_colors.removed,
    "&$checked": {
      color: install_status_colors.removed,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const getInstallActionCheckbox = (
  type: InstallActionType
): React.ComponentType<CheckboxProps> => {
  switch (type) {
    case "uninstalled":
      return UninstalledCheckbox;
    case "removed":
      return RemovedCheckbox;
  }

  throw new Error(
    `${type} InstallActionType passed in to get checkbox, which is bad`
  );
};

export type InstallActionCheckboxProps = {
  actor_id: number | null;
  splitter_ids: number[];
  currentUser: User;
  action: InstallAction | null | undefined;
  type: InstallActionType;
  onChangeActor: (actor_id: number | null) => void;
  onChangeSplitters: (splitter_ids: number[]) => void;
};

const InstallActionCheckbox = ({
  type,
  actor_id,
  currentUser,
  splitter_ids,
  onChangeActor,
  onChangeSplitters,
  action,
}: InstallActionCheckboxProps): JSX.Element => {
  const IACheckbox = getInstallActionCheckbox(type);

  const typeLabel = capitalize(type);
  const actor =
    actor_id == null ? null : findModelById(getModels("users"), actor_id);
  let label = typeLabel;
  if (actor != null) {
    if (action) {
      const dateStr = `on ${strftime(
        "%b %e %Y @ %l:%M %p",
        action.created_at
      )}`;
      label = `${typeLabel} by ${actor.username} ${dateStr}`;
    } else {
      label = `${typeLabel} by ${actor.username}`;
    }
  }

  const enabled =
    currentUser.admin || !action || action.creator_id === currentUser.id;

  return (
    <div>
      <FormControlLabel
        control={
          <IACheckbox
            disabled={!enabled}
            checked={actor_id != null}
            onChange={(e): void =>
              onChangeActor(e.target.checked ? currentUser.id : null)
            }
          />
        }
        label={label}
      />
      {enabled && actor_id && (
        <div className="d-flex justify-content-between mb-4">
          {currentUser.admin && (
            <UserIdSelect
              user_id={actor_id}
              label={`Change ${type.replace(/d$/, "r")}`}
              onChange={onChangeActor}
            />
          )}

          <MultiUserIdSelect
            user_ids={splitter_ids}
            label="Split task with"
            onChange={onChangeSplitters}
          />
        </div>
      )}
    </div>
  );
};

export default InstallActionCheckbox;
