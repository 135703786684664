import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  Payscale,
  payscale_groups,
  orderPayscalesByType,
} from "../../models/Payscale";
import Typography from "@material-ui/core/Typography";
import groupBy from "lodash/groupBy";
import { getModels, router, store } from "../../init_app/globals";

const clickRow = (payscale: Payscale): void => {
  router.go(`/payscales/${payscale.id}`);
};

const renderPayscaleRow = (payscale: Payscale): JSX.Element => {
  return (
    <div
      key={"payscale_" + payscale.id}
      style={{ minHeight: 45 }}
      onClick={(): void => {
        clickRow(payscale);
      }}
      className="payscale-row list-group-item d-flex justify-content-between align-items-center p-1 next-bottom-border"
    >
      <div>
        <span className="fw-500 me-2">{payscale.type}:</span>
        <span>{payscale.label}</span>
      </div>
      <ArrowForwardIcon />
    </div>
  );
};

let yeg = 12;
const renderGroup = (
  group: string,
  payscales?: Payscale[]
): JSX.Element | null => {
  if (payscales == null || payscales.length === 0) {
    return null;
  }

  return (
    <div key={`yeez_${yeg++}`} className="mt-3">
      <Typography style={{ fontWeight: "bold" }}>{group}</Typography>
      {orderPayscalesByType(payscales).map(renderPayscaleRow)}
    </div>
  );
};

function PayscaleList(): JSX.Element {
  const payscales = getModels("payscales");

  const currentUser = store.getNonNullState("currentUser");

  const length = payscales.length;

  React.useEffect(() => {
    const title = `Payscales by group (${length})`;

    store.setState("title", title);
  }, [length]);

  if (!currentUser.admin) {
    router.go("/");
  }

  const groupedPayscales = groupBy(payscales, (ps) => ps.group);

  return (
    <div>
      <div id="payscales" className="mt-3">
        {payscale_groups.map((pg) => renderGroup(pg, groupedPayscales[pg]))}
      </div>
    </div>
  );
}

export default PayscaleList;
