import uniq from "lodash/uniq";
import { getModels } from "../init_app/globals";
import { findModelsByIdInclusion } from "../util/app_util";
import { filterModelsByAttr } from "../util/data_util";
import { doesIntersect, isPresent } from "../util/util";
import { Building } from "./Building";
import { FieldNote } from "./FieldNote";
import { Install, InstallStatus } from "./Install";
import { InstallAction } from "./InstallAction";
import { Reservation } from "./Reservation";
import { Ticket } from "./Ticket";
import { User } from "./User";

export type Activity = {
  id: number;
  key: string;
  txt: string;
  room_id: number;
  creator: User;
  namey: "Activity";
  performed_at: Date;
};

export type RoomJSON = {
  id: number;
  tickets_count: number;
  unresolved_tickets_count: number;
  installs_count: number;
  number: string;
  wing: string;
  occupied: boolean;
  locked: boolean | null;
  building_id: number;
  created_at: string;
};

export class Room {
  id: number;
  tickets_count: number;
  unresolved_tickets_count: number;
  installs_count: number;
  number: string;
  wing: string;
  occupied: boolean;
  locked: boolean | null;
  building_id: number;
  created_at: Date;
  building: Building;
  installs?: Install[];
  reservations?: Reservation[];
  field_notes?: FieldNote[];

  constructor(json: RoomJSON, building: Building) {
    this.id = json.id;
    this.tickets_count = json.tickets_count;
    this.unresolved_tickets_count = json.unresolved_tickets_count;
    this.installs_count = json.installs_count;
    this.number = json.number;
    this.wing = json.wing;
    this.occupied = json.occupied;
    this.locked = json.locked;
    this.building_id = json.building_id;
    this.building = building;
    this.created_at = new Date(json.created_at + "Z");
  }

  isBuilding(): boolean {
    return this.constructor.name === "Building";
  }

  wingName(): string {
    if (this.wing?.trim()) {
      return `#${this.number}, ${this.wing}`;
    } else {
      return "#" + this.number;
    }
  }

  showName(sep = "-"): string {
    return `${this.wingName()} ${sep} ${this.building.shown_name}`;
  }

  roomStr(): string {
    return `${this.building.shown_name}, ${this.number} ${this.wing}`.trim();
  }

  searchStudent(search: string): boolean {
    const regexp = new RegExp(search.replace(/\s/g, ""), "i");
    return (
      this.getReservations().find((r) =>
        regexp.test(r.fullName().replace(/\s/g, ""))
      ) != null
    );
  }

  getFieldNotes(): FieldNote[] {
    if (this.field_notes == null) {
      this.field_notes = filterModelsByAttr(
        getModels("field_notes"),
        "room_id",
        this.id
      );
    }

    return this.field_notes;
  }

  getReservations(): Reservation[] {
    if (this.reservations == null) {
      const res_ids = uniq(this.installs!.map((i) => i.reservation_id));

      this.reservations = findModelsByIdInclusion(
        getModels("reservations"),
        res_ids
      );
    }

    return this.reservations;
  }

  getTickets(): Ticket[] {
    return filterModelsByAttr(getModels("tickets"), "room_id", this.id);
  }

  getInstallActions(): InstallAction[] {
    return this.installs!.flatMap((i) => i.getInstallActions());
  }

  getActivity(): Activity[] {
    return [this.getInstallActions().map((ia) => ia.toActivity())].flat();
  }

  hasUpgrades(upgrades_ids: number[]): boolean {
    const result = this.installs!.find((i) =>
      doesIntersect(upgrades_ids, i.upgrade_ids)
    );

    return result != null;
  }

  hasProductsWithNoUpgrades(
    product_ids: number[],
    install_statuses?: InstallStatus[] | null
  ): boolean {
    if (install_statuses != null && install_statuses.length > 0) {
      return (
        this.installs!.find(
          (i) =>
            i.upgrade_ids.length === 0 &&
            product_ids.includes(i.product.id) &&
            install_statuses.includes(i.status)
        ) != null
      );
    } else {
      return (
        this.installs!.find(
          (i) =>
            i.upgrade_ids.length === 0 && product_ids.includes(i.product.id)
        ) != null
      );
    }
  }

  hasAvailableJobs(): boolean {
    if (this.occupied) {
      return false;
    }

    const hasAvailableInstall =
      this.installs!.find((i) => i.isAvailable()) != null;
    const hasUnresolvedTicket = this.unresolved_tickets_count > 0;

    return hasAvailableInstall || hasUnresolvedTicket;
  }

  hasProducts(
    product_ids: number[],
    install_statuses?: InstallStatus[] | null
  ): boolean {
    if (install_statuses != null && install_statuses.length > 0) {
      return (
        this.installs!.find(
          (i) =>
            product_ids.includes(i.product.id) &&
            install_statuses.includes(i.status)
        ) != null
      );
    } else {
      return (
        this.installs!.find((i) => product_ids.includes(i.product.id)) != null
      );
    }
  }

  hasSpecialInstructions(): boolean {
    const result = this.getReservations().find((r) =>
      isPresent(r.special_instructions)
    );

    return result != null;
  }

  hasNoUpgrades(): boolean {
    return this.installs!.find((i) => i.upgrade_ids.length === 0) != null;
  }

  hasInstallStatuses(statuses: InstallStatus[]): boolean {
    return this.installs!.find((i) => statuses.includes(i.status)) != null;
  }

  leavingBefore(d: Date): boolean {
    return this.getReservations().find((r) => r.leavingBefore(d)) != null;
  }
}
