import { departureDateStrForSchool } from "../util/app_util";
import { getDateFromDateStr } from "../util/util";
import { Room } from "./Room";

export type ReservationJSON = {
  id: number;
  student_id: number;
  student_first_name: string;
  student_last_name: string;
  student_phone: string;
  student_email: string;
  room_id: number;
  school_id: number;
  special_instructions: string;
  has_invoice: boolean;
  created_at: string;
  departure_date: string | null;
};

type StudentDeets = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  vacancy: Date;
  full_name: string;
};

export class Reservation {
  id: number;
  student_id: number;
  room_id: number;
  school_id: number;
  student_first_name: string;
  student_last_name: string;
  student_phone: string;
  student_email: string;
  room: Room;
  special_instructions: string;
  departure_date: Date;
  created_at: Date;

  constructor(json: ReservationJSON, room: Room) {
    this.id = json.id;
    this.student_id = json.student_id;
    this.room_id = json.room_id;
    this.student_first_name = json.student_first_name;
    this.student_last_name = json.student_last_name;
    this.student_email = json.student_email;
    this.student_phone = json.student_phone;
    this.special_instructions = json.special_instructions;
    this.created_at = new Date(json.created_at + "Z");
    this.school_id = json.school_id;
    this.departure_date =
      json.departure_date == null
        ? getDateFromDateStr(departureDateStrForSchool(json.school_id))
        : getDateFromDateStr(json.departure_date);
    this.room = room;
  }

  studentDeets(): StudentDeets {
    return {
      first_name: this.student_first_name,
      last_name: this.student_last_name,
      email: this.student_email,
      phone: this.student_phone,
      vacancy: this.departure_date,
      full_name: this.fullName(),
    };
  }

  leavingBefore(d: Date): boolean {
    return this.departure_date < d;
  }

  fullName(): string {
    return `${this.student_first_name} ${this.student_last_name}`;
  }
}
