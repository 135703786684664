import { PaycheckModalState } from "../types/state_types";
import { then, unwrap2Or, unwrapOr } from "../util/util";

export type PaycheckJSON = {
  id: number;
  date_at: Date;
  type: string;
  check_number: string;
  amount_cents: number;
  payee_id: number;
  creator_id: number;
  notes: string | null;
  created_at: string;
};

export class Paycheck {
  id: number;
  date_at: Date;
  type: string;
  check_number: string;
  amount_cents: number;
  payee_id: number;
  creator_id: number;
  notes: string | null;
  created_at: Date;

  constructor(json: PaycheckJSON) {
    this.id = json.id;
    this.date_at = new Date(json.date_at + "Z");
    this.type = json.type;
    this.check_number = json.check_number;
    this.amount_cents = json.amount_cents;
    this.payee_id = json.payee_id;
    this.creator_id = json.creator_id;
    this.notes = json.notes;
    this.created_at = new Date(json.created_at + "Z");
  }

  static getModalState(
    paycheck: Paycheck | null,
    payee_id: number | null
  ): PaycheckModalState {
    const id = unwrapOr(paycheck?.id, null);
    payee_id = unwrap2Or(payee_id, paycheck?.payee_id, null);
    const amount_cents = unwrapOr(paycheck?.amount_cents, null);
    const amount = then(amount_cents, (c) => (c == null ? null : c / 100));
    const type = unwrapOr(paycheck?.type, "check");
    const check_number = unwrapOr(paycheck?.check_number, null);
    const notes = unwrapOr(paycheck?.notes, null);
    const date_at = unwrapOr(paycheck?.date_at, new Date());
    const working = false;
    const errors: string[] = [];

    return {
      id,
      notes,
      payee_id,
      check_number,
      amount,
      type,
      date_at,
      working,
      errors,
    };
  }
}
