import React from "react";
import Button from "@material-ui/core/Button";
import { Room } from "../../models/Room";
import { ApiRequest } from "../../libs/ApiRequest";
import { isErrorResponse } from "../../util/app_util";
import { store } from "../../init_app/globals";

type fn = React.Dispatch<React.SetStateAction<boolean>>;

const toggleOccupied = (
  occupied: boolean,
  room: Room,
  working: boolean,
  setWorking: fn
): void => {
  if (working) {
    return;
  }

  setWorking(true);
  ApiRequest.perform({
    url: "/worker_api/room_occupations.json",
    method: "POST",
    onSuccess: (resp: unknown) => {
      const json = resp as { occupied: boolean };
      room.occupied = json.occupied;
      store.forceUpdate("room");
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        alert(json.errors.join(". "));
      } else {
        alert("an error occurred while changing occupied status");
      }
    },
    onDone: () => {
      setWorking(false);
    },
    data: {
      room_id: room.id,
      occupied: !occupied,
    },
  });
};

interface OccupiedButtonProps {
  room: Room;
}

const OccupiedButton = ({ room }: OccupiedButtonProps): JSX.Element => {
  const [working, setWorking] = React.useState(false);

  const occupied = room.occupied;

  return (
    <Button
      variant="outlined"
      disabled={working}
      className="next-button occupied-btn"
      size="small"
      onClick={(): void => toggleOccupied(occupied, room, working, setWorking)}
    >
      {occupied ? "set unoccupied" : "set room occupied"}
    </Button>
  );
};

export default React.memo(OccupiedButton);
