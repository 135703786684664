export type WarehouseJSON = {
  id: number;
  name: string;
};

export class Warehouse {
  id: number;
  name: string;

  constructor(json: Warehouse) {
    this.id = json.id;
    this.name = json.name;
  }
}
