import { Filter } from "../../models/Filter";
import Typography from "@material-ui/core/Typography";
import { andJoin } from "../../util/util";
import { getStatsForFilter, getAllStats } from "../../helpers/stats_helpers";
import CenterAll from "../util/CenterAll";
import StatsTable from "./StatsTable";
import SchoolSelectMeh from "../selects/SchoolSelectMeh";
import { store } from "../../init_app/globals";

const renderStatsCriteria = (filter: Filter): JSX.Element => {
  const school = filter.getSchool();
  const buildings = filter.getBuildings();

  return (
    <div className="mt-1 mb-3">
      <Typography variant="h6">Stats filter criteria</Typography>

      <div>
        <b>school: </b> {school.name}
      </div>

      {buildings.length > 0 && (
        <div>
          <div>AND</div>
          <b>buildings: </b>
          {andJoin(
            buildings.map((b) => b.shown_name),
            "OR"
          )}
        </div>
      )}
    </div>
  );
};

const FilteredStats = (): JSX.Element => {
  setTimeout(() => {
    store.setStateIfDifferent("rightIcon", "filter");
  });

  const filter = window.App.filter;
  setTimeout(() => {
    store.setStateIfDifferent("subtitle", undefined);
  });

  if (filter) {
    const school = filter.getSchool();
    const installs = school.getInstalls();

    const stats = filter.building_ids.length
      ? getStatsForFilter(school, filter.building_ids, installs)
      : getAllStats().stats.find((obj) => obj.school === school);

    if (!stats) {
      return <div>????</div>;
    }

    return (
      <div>
        {renderStatsCriteria(filter)}
        {StatsTable(stats.prods, school.name, true)}
      </div>
    );
  } else {
    return (
      <CenterAll className="mt-4">
        <SchoolSelectMeh />
      </CenterAll>
    );
  }
};

export default FilteredStats;
