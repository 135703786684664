import PayscalesTable from "./PayscalesTable";
import { EarningsPageProps } from "./EarningsPage";
import strftime from "strftime";
import TotalsTable from "./TotalsTable";
import MoneyTable from "./MoneyTable";
import sortBy from "lodash/sortBy";
import {
  getStatsForUserOnDay,
  getMoneyForStats,
  computeRoomTotal,
  computeBonusBuckTotalOnDay,
  getDaysWorked,
  actionsForUserAndDate,
  getTruckLoadTotalForUserAndDate,
} from "../../helpers/stats_helpers";
import { andJoin, areSameDay } from "../../util/util";
import { Divider, Typography } from "@material-ui/core";
import { User } from "../../models/User";
import { getModels, router, store } from "../../init_app/globals";
import UserStatsTable from "./UserStatsTable";
import { Activity } from "../../models/Room";
import NativeAppDatePicker from "../selects/NativeAppDatePicker";
import { BonusBuck } from "../../models/BonusBuck";
import { isBonusBuck, isTruckLoad } from "../../util/app_util";
import { renderBB } from "../bonus_bucks/BonusBucks";
import BonusBuckModal from "../bonus_bucks/BonusBuckModal";
import { TruckLoad } from "../../models/TruckLoad";
import { renderTruckLoadRow } from "../truck_loads/TruckLoadList";

const handleDateChange = (date: Date | null): void => {
  const d = date || new Date();

  store.setState("date", d);
};

const renderAction =
  (user: User) =>
  (a: Activity | BonusBuck | TruckLoad): JSX.Element => {
    if (isBonusBuck(a)) {
      return renderBB(a);
    } else if (isTruckLoad(a)) {
      return renderTruckLoadRow(a, user);
    } else {
      return (
        <div
          className="job-row pl-1 py-2"
          key={a.key}
          onClick={(): void =>
            router.go(`/workers/${user.id}/rooms/${a.room_id}`)
          }
        >
          {a.txt}
        </div>
      );
    }
  };

const renderActions = (
  activities: Array<Activity | BonusBuck | TruckLoad>,
  user: User
): JSX.Element | null => {
  if (activities.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Activity ({activities.length})</Typography>
        <div className="pl-1">{activities.map(renderAction(user))}</div>
      </div>
    );
  } else {
    return null;
  }
};

const DailyEarnings = (props: EarningsPageProps): JSX.Element => {
  const { user, date } = props;

  if (date) {
    const stats = getStatsForUserOnDay(user, date);

    const money = getMoneyForStats(stats, user);

    const room_total = computeRoomTotal(money);

    const bonus_buck_total = computeBonusBuckTotalOnDay(user, date);
    const truck_load_total = getTruckLoadTotalForUserAndDate(user, date);

    const total = room_total + truck_load_total + bonus_buck_total * 100;

    const totals = {
      total,
      room_total,
      truck_load_total,
      bonus_buck_total,
    };

    const days = sortBy(getDaysWorked(user), (d) => d.date).map((d) => d.str);

    let activities: Array<Activity | BonusBuck | TruckLoad> =
      actionsForUserAndDate(user, date).map((ia) => ia.toActivity());
    const bonus_bucks = getModels("bonus_bucks").filter(
      (bb) =>
        bb.worker_ids.includes(user.id) && areSameDay(bb.performed_at, date)
    );

    const truck_loads = getModels("truck_loads").filter(
      (tl) =>
        tl.worker_ids.includes(user.id) && areSameDay(tl.performed_at, date)
    );

    activities = activities.concat(bonus_bucks).concat(truck_loads);

    activities = sortBy(activities, (a) => a.performed_at);

    return (
      <div>
        <div className="mt-2">{PayscalesTable({ user })}</div>
        <div className="mt-3">Days worked: {andJoin(days)}</div>
        <div className="mt-1 d-flex justify-content-between align-items-center">
          <div>{strftime("%b %e %Y", date)} earnings:</div>
          <NativeAppDatePicker
            label="change date"
            date={date}
            onChangeDate={handleDateChange}
          />
        </div>

        <TotalsTable {...totals} />

        <MoneyTable money={money} />
        <Divider />
        <UserStatsTable stats={stats} />
        {renderActions(activities, user)}

        <BonusBuckModal />
      </div>
    );
  } else {
    return (
      <div>
        <span>Please select a date:</span>
        <NativeAppDatePicker
          date={date}
          label="select date"
          onChangeDate={handleDateChange}
        />
      </div>
    );
  }
};

export default DailyEarnings;
