import React, { CSSProperties } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { TicketRespJSON } from "../../types/json_types";
import { Ticket } from "../../models/Ticket";
import { ApiRequest } from "../../libs/ApiRequest";
import { getModels, store } from "../../init_app/globals";
import { findModelById } from "../../util/data_util";
import { then } from "../../util/util";
import { User } from "../../models/User";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const { setState, setPartialState, useStoreState } = store.getScopedHelpers(
  "ticket_resolution_modal"
);

function getModalStyle(): CSSProperties {
  const top = 50;

  return {
    top: `${top}%`,
    left: 0,
    transform: `translateY(-${top}%)`,
    outline: "0",
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  })
);

function clickSubmit(ticket_id: number, note: string, working: boolean): void {
  if (working) {
    return;
  }

  const data = {
    resolution_notes: note,
  };

  ApiRequest.perform({
    url: `/worker_api/tickets/${ticket_id}/mark_resolved.json`,
    method: "POST",
    onSuccess: (resp: unknown) => {
      const json = resp as TicketRespJSON;

      const ticket = findModelById(getModels("tickets"), ticket_id);
      const room = findModelById(getModels("rooms"), ticket.room_id);

      const creator = findModelById(getModels("users"), json.ticket.creator_id);
      const resolver: User | null = then(json.ticket.resolved_by_id, (id) =>
        id == null ? null : findModelById(getModels("users"), id)
      );

      const heldTicket = new Ticket(json.ticket, creator, room, resolver);
      Object.assign(ticket, heldTicket);

      room.tickets_count = json.room.tickets_count;
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      store.state.ticket_resolution_modal = undefined;

      store.forceUpdate("room");
    },
    onError: () => {
      alert("an error occurred");
    },
    data,
  });
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  setPartialState({ resolution_notes: event.target.value });
};

const handleClose = (): void => {
  setState(undefined);
};

const TicketResolutionModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  if (state == null) {
    return null;
  }

  const { ticket_id, working, resolution_notes } = state;

  if (ticket_id == null) {
    return null;
  }

  const ticket = findModelById(getModels("tickets"), ticket_id);

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            Add a resolution note for:
          </Typography>
          <div>{ticket.text}</div>

          <TextField
            style={{ width: "100%" }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={resolution_notes}
            onChange={onChange}
            variant="outlined"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          <Button
            onClick={(): void =>
              clickSubmit(ticket_id, resolution_notes, working)
            }
            variant="outlined"
            disabled={working}
          >
            Resolve
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TicketResolutionModal;
