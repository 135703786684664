import { EarningsPageProps } from "./EarningsPage";
import { Divider } from "@material-ui/core";
import MoneyTable from "./MoneyTable";
import { getTotalCentsForUser } from "../../helpers/user_helpers";
import PayscalesTable from "./PayscalesTable";
import TotalsTable from "./TotalsTable";
import UserStatsTable from "./UserStatsTable";

const AllTimeEarnings = (props: EarningsPageProps): JSX.Element => {
  const { user } = props;

  const { totals, stats, money } = getTotalCentsForUser(user);

  return (
    <div>
      <div className="mt-2">{PayscalesTable({ user })}</div>
      <div className="mt-2">Total all-time earnings:</div>
      <TotalsTable {...totals} />
      <MoneyTable money={money} />
      <Divider />
      <UserStatsTable stats={stats} />
    </div>
  );
};

export default AllTimeEarnings;
