import React from "react";
import UserWorkTabs from "./UserWorkTabs";
import { EarningsPage } from "./EarningsPage";
import { User } from "../../models/User";
import UserWorkBonuses from "./UserWorkBonuses";
import { getTotalCentsForUser } from "../../helpers/user_helpers";
import Received from "./Received";
import { store } from "../../init_app/globals";

const getView = (
  user: User,
  tab: number,
  date: Date | null,
  earned_tab: number
): JSX.Element | null => {
  switch (tab) {
    case 0:
      return <EarningsPage user={user} earned_tab={earned_tab} date={date} />;
    case 1:
      return <UserWorkBonuses user={user} />;
    case 2:
      const { totals } = getTotalCentsForUser(user);
      return <Received user={user} total={totals.total} />;
    default:
      return null;
  }
};

const UserWork = (): JSX.Element | null => {
  const user = store.useNonNullState("user");
  const date = store.useNonNullState("date");
  const tab = store.useNonNullState("tab");
  const subtab = store.useNonNullState("subtab");
  const viewShown = store.getNonNullState("viewShown");

  React.useEffect(() => {
    let title: string;
    if (tab === 1) {
      title = `${user.username}'s Bonuses`;
    } else if (tab === 0) {
      title = `${user.username}'s Work`;
    } else {
      title = `${user.username}'s Paychecks`;
    }

    if (viewShown === "UserWork") {
      store.setState("title", title);
    }
  }, [tab, user.username, viewShown]);

  return (
    <div>
      <UserWorkTabs tab={tab} user={user} />
      {getView(user, tab, date, subtab)}
    </div>
  );
};

export default UserWork;
