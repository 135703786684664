import Button from "@material-ui/core/Button";
import { filterKey, store } from "../init_app/globals";

const clickFilter = (): void => {
  localStorage.removeItem(filterKey);
  window.App.filter = undefined;
  store.forceUpdate("viewShown");
};

export const Home = (): JSX.Element => {
  return (
    <div className="p-1">
      <Button variant="outlined" onClick={clickFilter} className="mt-4">
        Remove Your Filter
      </Button>
    </div>
  );
};
