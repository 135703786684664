import TextField from "@material-ui/core/TextField";
import { getDateFromDateStr, getDateStr } from "../../util/util";

export type NativeAppDatePickerProps = {
  onChangeDate: (date: Date | null) => void;
  date: Date | null;
  label: string;
};

export default function NativeAppDatePicker(
  props: NativeAppDatePickerProps
): JSX.Element {
  const onChange = (e: any): void => {
    const str = e.target.value as string;
    const date = getDateFromDateStr(str);
    props.onChangeDate(date);
  };

  const date = props.date == null ? new Date() : props.date;

  return (
    <TextField
      label={props.label}
      type="date"
      onChange={onChange}
      value={getDateStr(date)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
