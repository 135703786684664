import { Store } from "set-state-is-great";
import {
  BasicInfoFormState,
  LoginFormState,
  SignupFormState,
} from "../helpers/auth_helpers";
import { Building } from "../models/Building";
import { Payscale } from "../models/Payscale";
import { Room } from "../models/Room";
import { TruckLoad } from "../models/TruckLoad";
import { User } from "../models/User";
import { View } from "../types/app_types";
import {
  BonusBuckModalState,
  FieldNoteModalState,
  FilterModalState,
  FullProfileFormState,
  InstallActionModalState,
  PaycheckModalState,
  PayscaleModalState,
  TicketModalState,
  TicketResolutionModalState,
  TruckLoadModalState,
} from "../types/state_types";
import { router } from "./router";

export type AppState = {
  colormode: "dark" | "light";
  currentUser?: User;
  viewShown?: View;
  drill_href?: string;
  title?: string;
  subtitle?: string;
  toptitle?: string;
  search?: string;
  rightIcon?: "jobs" | "filter" | "pencil" | null;
  room?: Room;
  building?: Building;
  user?: User;
  payscale?: Payscale;
  truck_load?: TruckLoad;
  working: boolean;
  showPending?: boolean;
  showPhotos?: boolean;
  drawerOpen: boolean;
  tab?: number;
  subtab?: number;
  date?: Date;
  errors?: string[];
  payscale_modal?: PayscaleModalState;
  field_note_modal?: FieldNoteModalState;
  basic_info_form?: BasicInfoFormState;
  ticket_modal?: TicketModalState;
  ticket_resolution_modal?: TicketResolutionModalState;
  signup_form?: SignupFormState;
  login_form?: LoginFormState;
  filter_modal?: FilterModalState;
  install_action_modal?: InstallActionModalState;
  paycheck_modal?: PaycheckModalState;
  bonus_buck_modal?: BonusBuckModalState;
  full_profile_form?: FullProfileFormState;
  truck_load_modal?: TruckLoadModalState;
};

const appState: AppState = {
  drawerOpen: false,
  colormode: localStorage.getItem("lightMode") == null ? "dark" : "light",
  working: false,
};

const store = new Store<AppState>(appState);

export { store, router };
