import { CSSProperties } from "react";
import Box from "@material-ui/core/Box";
import { Building } from "../../models/Building";
import { router, store } from "../../init_app/globals";
import { InstallStats, RoomTotals } from "../../helpers/stats_helpers";
import { install_status_colors } from "../../constants/colors";
import { shorts } from "../../constants/shorts";

const clickBuilding = (building_id: number): void => {
  const search = store.state.search;
  const path =
    search == null
      ? `/buildings/${building_id}`
      : `/buildings/${building_id}/search/${search}`;
  router.go(path);
};

let key1 = 0;

const renderSpans = (totals: RoomTotals, short: string): JSX.Element | null => {
  if (totals == null) return null;

  const total = totals.total;
  if (total == null) return null;

  const { pending_removal, uninstalled } = totals;

  return (
    <span key={`span${(key1 += 1)}`}>
      <span className="mx-1">
        {`${short} `}
        <span style={{ color: install_status_colors.pending_removal }}>
          {pending_removal}
        </span>
        ,
        <span style={{ color: install_status_colors.uninstalled }}>
          {uninstalled}
        </span>
      </span>
    </span>
  );
};

type BuildingRowProps = {
  style: CSSProperties;
  building: Building;
  stats: InstallStats;
};
export const BuildingRow = ({
  style,
  building,
  stats,
}: BuildingRowProps): JSX.Element => {
  return (
    <Box
      onClick={(): void => clickBuilding(building.id)}
      className="building-row pl-2 light-bottom-border"
      data-title={building.shown_name}
      style={style}
      display="flex"
      alignItems="center"
    >
      {building.shown_name}

      <div style={{ fontSize: 13 }} className="d-flex flex-wrap">
        {shorts.map((s) => renderSpans(stats[s]!, s))}
      </div>
    </Box>
  );
};
