import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { User } from "../../models/User";
import { router } from "../../init_app/globals";

type UserWorkTabsProps = {
  tab: number;
  user: User;
};

function UserWorkTabs({ tab, user }: UserWorkTabsProps): JSX.Element {
  const changeTab = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ): void => {
    if (newValue === 0) {
      router.replace(`/workers/${user.id}`);
    } else if (newValue === 1) {
      router.replace(`/workers/${user.id}/bonus_bucks`);
    } else {
      router.replace(`/workers/${user.id}/received`);
    }
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={changeTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Earned" />
          <Tab label="Bonus Bucks" />
          <Tab label="Received" />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default UserWorkTabs;
