import uniq from "lodash/uniq";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import toUpper from "lodash/toUpper";
import { getModels, store } from "../init_app/globals";
import { Building } from "../models/Building";
import { User } from "../models/User";
import { filterModelsByAttr } from "./data_util";
import { Count } from "../constants/shorts";
import { BonusBuck } from "../models/BonusBuck";
import { TruckLoad } from "../models/TruckLoad";
import { Activity } from "../models/Room";

export type ErrorResponse = {
  errors: Array<string>;
};

export function isErrorResponse<T>(
  json: T | ErrorResponse
): json is ErrorResponse {
  return (json as ErrorResponse).errors !== undefined;
}

export type ServerUrl =
  | `https://removals.dormsdirect.com`
  | "https://mfr.dormsdirect.com"
  | "http://localhost:3000";

export const getServer = (): ServerUrl => {
  return window.location.host === `removals.dormsdirect.com`
    ? `https://removals.dormsdirect.com`
    : window.location.host === "mfr.dormsdirect.com"
    ? `https://mfr.dormsdirect.com`
    : "http://localhost:3000";
};

export const intersperseBuildings = function <
  T extends { building_id: number }
>(items: T[], buildings: Array<Building>): Array<T | Building> {
  const building_ids = uniq(map(items, "building_id"));
  buildings = sortBy(
    buildings.filter((b) => building_ids.includes(b.id)),
    (b) => b.shown_name
  );

  return buildings
    .map((building) =>
      [building, items.filter((r) => r.building_id === building.id)].flat()
    )
    .flat();
};

export const parseBoolStr = (str: string): boolean | null => {
  return str === "true" ? true : str === "false" ? false : null;
};

export const isIos = (): boolean => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    (window as any).MSStream == null
  );
};

export const getActiveUsers = (): User[] => {
  return filterModelsByAttr(getModels("users"), "active", true);
};

export const findModelsByIdInclusion = function <T extends { id: number }>(
  arr: Array<T>,
  ids: number[]
): T[] {
  const length = arr.length;
  const models = [];
  let model: T;

  for (let index = 0; index < length; index++) {
    model = arr[index]!;
    if (ids.includes(model.id)) {
      models.push(model);
    }
  }

  return models;
};

export const filterModelsByAttrInclusion = function <T, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  id: number
): T[] {
  const length = arr.length;
  const models = [];
  let model: T;
  let ids: unknown;

  for (let index = 0; index < length; index++) {
    model = arr[index]!;
    ids = model[attr];
    if ((ids as number[]).includes(id)) {
      models.push(model);
    }
  }

  return models;
};

export const toggleDarkmode = (): void => {
  const state = store.state;

  if (localStorage.getItem("lightMode") != null) {
    localStorage.removeItem("lightMode");
    state.colormode = "dark";
    document.body.className = "darkmode";
  } else {
    localStorage.setItem("lightMode", "yup");
    state.colormode = "light";
    document.body.className = "lightmode";
  }

  store.state.drawerOpen = false;
  store.forceUpdate("colormode");
};

export const shortFromCount = (count: Count): string => {
  return toUpper(count.split("_")[0]);
};

export const departureDateStrForSchool = (school_id: number): string => {
  switch (school_id) {
    case 1:
      return "2021-05-24";
    case 2:
      return "2021-05-10";
    case 3:
      return "2021-05-07";
    case 4:
      return "2021-05-07";
    case 5:
      return "2021-05-07";
    case 6:
      return "2021-05-07";
    case 7:
      return "2021-05-07";
    case 8:
      return "2021-05-07";
    case 9:
      return "2021-05-07";
    default:
      throw new Error(
        `school_id should be 1, 2, 3 or 4... hell it could even be 9; instead it was ${school_id}; something has gone horribly, utterly wrong`
      );
  }
};

export const simpleDate = (date: Date): string => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

export const isBonusBuck = (thing: { namey: string }): thing is BonusBuck => {
  return thing.namey === "BonusBuck";
};

export const isTruckLoad = (thing: { namey: string }): thing is TruckLoad => {
  return thing.namey === "TruckLoad";
};

export const isActivity = (thing: { namey: string }): thing is Activity => {
  return thing.namey === "Activity";
};
