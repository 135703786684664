import sortBy from "lodash/sortBy";
import { Install, InstallStatus, install_statuses } from "../models/Install";
import { shorts } from "../constants/shorts";
import { unwrapOr } from "../util/util";
import { InstallActionModalState } from "../types/state_types";

export const getInstallActionModalState = (
  install: Install
): InstallActionModalState => {
  return {
    install,
    working: false,
    uninstalled_by_id: unwrapOr(install.uninstalled_action?.actor_id, null),
    uninstalled_by_splitter_ids: unwrapOr(
      install.uninstalled_action?.splitter_ids,
      []
    ),
    removed_by_id: unwrapOr(install.removed_action?.actor_id, null),
    removed_by_splitter_ids: unwrapOr(install.removed_action?.splitter_ids, []),
  };
};

export const sortInstallsByShort = (installs: Install[]): Install[] => {
  return sortBy(installs, (i) => {
    return shorts.indexOf(i.product.short);
  });
};

export const sortStatuses = (statuses: InstallStatus[]): InstallStatus[] => {
  return sortBy(statuses, (s) => {
    return install_statuses.indexOf(s);
  });
};
