import React from "react";
import { School } from "../../models/School";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Filter, FilterJSON } from "../../models/Filter";
import { store, getModels } from "../../init_app/globals";

const renderOption = (school: School): JSX.Element => {
  const key = `school_${school.id}_option`;

  return (
    <option value={school.id} key={key}>
      {school.name}
    </option>
  );
};

const handleChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
  const school_id = parseInt(e.target.value as string);
  const json: FilterJSON = Filter.emptyJSON();
  json.school_id = school_id;
  const filter = new Filter(json);
  filter.saveToLocalStorage();
  window.App.filter = filter;

  store.forceUpdate("viewShown");
};

const SchoolSelectMeh = (): JSX.Element => {
  const id = `school-select-meh`;

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Select School</InputLabel>
      <Select id={id} native onChange={handleChange} label="Select School">
        <option>Please select a school</option>
        {getModels("schools").map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default React.memo(SchoolSelectMeh);
