import { Typography, Box, TextField, Button } from "@material-ui/core";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { SignupFormState } from "../../helpers/auth_helpers";
import { router, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { ReactFormEvent } from "../../types/types";
import { isErrorResponse } from "../../util/app_util";
import CenterAll from "../util/CenterAll";
import CloseableAlert from "../util/CloseableAlert";
import GoogleOauthButton from "./GoogleOauthButton";

const { setPartialState } = store.getScopedHelpers("signup_form");

const closeAlert = (): void => {
  store.setState("errors", []);
};

const clickGoLogin = (): void => {
  router.go("/login");
};

const onChange = <Key extends keyof SignupFormState>(
  attr: Key,
  value: SignupFormState[Key]
): void => {
  setPartialState({ [attr]: value });
};

const onSubmit = (e: ReactFormEvent): void => {
  e.preventDefault();
  if (store.getState("working")) {
    return;
  }

  store.setState("working", true);

  ApiRequest.perform({
    url: "/users.json",
    method: "POST",
    data: { user: store.getNonNullState("signup_form") },
    onSuccess: (json: unknown) => {
      window.location.pathname = "/";
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        store.setState("errors", json.errors);
      } else {
        window.alert("an error occurred");
      }

      store.setState("working", false);
    },
  });
};

export const SignupForm = (): JSX.Element => {
  const errors = store.useNonNullState("errors");
  const working = store.useState("working");
  const state = store.useNonNullState("signup_form");

  return (
    <div className="p-2">
      {errors.length === 0 ? null : (
        <CloseableAlert
          open={errors.length > 0}
          closeAlert={closeAlert}
          variant="outlined"
          severity="error"
        >
          <AlertTitle>The following errors occurred:</AlertTitle>
          <ul>
            {errors.map((e, index) => (
              <li key={`signup_err_idx_${index}`}>{e}</li>
            ))}
          </ul>
        </CloseableAlert>
      )}

      <Typography variant="h4">Sign Up</Typography>

      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            value={state.email}
            onChange={(e): void => onChange("email", e.target.value)}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.password}
            onChange={(e): void => onChange("password", e.target.value)}
            label="Password"
            type="password"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.password_confirmation}
            onChange={(e): void =>
              onChange("password_confirmation", e.target.value)
            }
            label="Password confirmation"
            type="password"
            variant="outlined"
            className="mt-2"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="mt-2"
            disabled={working}
            disableElevation
          >
            sign up
          </Button>
        </Box>
      </form>

      <Box display="flex" flexDirection="column">
        <Box my="2">
          <Typography variant="h6" style={{ textAlign: "center" }}>
            OR
          </Typography>
        </Box>

        <CenterAll>
          <GoogleOauthButton />
        </CenterAll>
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography
          className="mt-2"
          variant="h6"
          style={{ textAlign: "center" }}
        >
          OR
        </Typography>

        <Button
          onClick={clickGoLogin}
          variant="outlined"
          color="secondary"
          size="large"
          className="mt-2"
        >
          Go to login page
        </Button>
      </Box>
    </div>
  );
};
