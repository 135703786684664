import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import sum from "lodash/sum";
import compact from "lodash/compact";
import concat from "lodash/concat";
import { School } from "../models/School";
import { User } from "../models/User";
import { Payscale } from "../models/Payscale";
import { Building } from "../models/Building";
import { Room } from "../models/Room";
import { Install } from "../models/Install";
import { shorts, short_shorts } from "../constants/shorts";
import { InstallAction } from "../models/InstallAction";
import { getModels } from "../init_app/globals";
import { areSameDay } from "../util/util";
import { filterModelsByAttrInclusion } from "../util/app_util";
import { findModelByAttr } from "../util/data_util";

export interface RoomTotals {
  total: number;
  pending_removal: number;
  uninstalled: number;
  removed: number;
  missing: number;
}

export interface InstallStats {
  [short: string]: RoomTotals;
}

export type ShortShortInstallStats = {
  [short_short: string]: RoomTotals;
};

export interface MoneyNumbers {
  total: number;
  uninstalled: number;
  removed: number;
}

export interface ShortShortMoneyNumbers {
  [short_short: string]: MoneyNumbers;
}

export interface MoneyAmounts {
  uninstalled_amount: number;
  removed_amount: number;
  total_amount: number;
}

export type ShortShortMoneyAmounts = {
  [short_short: string]: RoomTotals;
};

export type ShortShortMoneyStats = {
  [short_short: string]: MoneyAmounts;
};

export const getTruckLoadTotalForUser = (user: User): number => {
  const payscale = Payscale.find("dropoff", user);
  return sum(
    getModels("truck_loads").map((tl) =>
      tl.loadedTruck(user) ? tl.individualCents(payscale) : 0
    )
  );
};

export const getTruckLoadTotalForUserAndDate = (
  user: User,
  date: Date
): number => {
  const payscale = Payscale.find("pickup", user);
  return sum(
    getModels("truck_loads").map((tl) =>
      tl.loadedTruck(user) && areSameDay(tl.performed_at, date)
        ? tl.individualCents(payscale)
        : 0
    )
  );
};

export const getStatsForBuilding = (
  building: Building,
  rooms: Room[]
): InstallStats => {
  rooms = rooms.filter((r) => r.building.id === building.id);
  const installs = flatten(rooms.map((r) => r.installs));

  const stats: InstallStats = {};
  let short, install, room_stats: RoomTotals;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i]!;
    short = install.product.short;
    if (short === "HB") {
      continue;
    }

    if (stats[short]) {
      room_stats = stats[short]!;
    } else {
      room_stats = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      };
    }
    stats[short] = room_stats;
    stats[short]!.total += 1;
    stats[short]![install.status] += 1;
  }

  return stats;
};

const calc_statuses = ["pending_removal", "uninstalled", "removed"];

export type SchoolStats = {
  school: School;
  prods: InstallStats;
};
export const getStatsForSchool = function (
  school: School,
  installs: Install[]
): SchoolStats {
  const prods: InstallStats = {};
  const stats = {
    school: school,
    prods: prods,
  };

  shorts.forEach(
    (s) =>
      (prods[s] = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      })
  );

  installs = installs.filter((i) => {
    return i.school.id === school.id;
  });

  let install: Install, short: string;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i]!;

    short = install.product.short;

    const prod = prods[short]!;

    if (calc_statuses.includes(install.status)) {
      prod.total += 1;
    }

    prods[short]![install.status] += 1;
  }

  return stats;
};

export const getStatsForFilter = function (
  school: School,
  building_ids: number[],
  installs: Install[]
): SchoolStats {
  const prods: InstallStats = {};
  const stats = {
    school: school,
    prods: prods,
  };

  shorts.forEach(
    (s) =>
      (prods[s] = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      })
  );

  installs = installs.filter(
    (i) =>
      i.school.id === school.id && building_ids.includes(i.room.building_id)
  );

  let install: Install, short: string;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i]!;

    short = install.product.short;

    if (calc_statuses.includes(install.status)) {
      prods[short]!.total += 1;
    }

    prods[short]![install.status] += 1;
  }

  return stats;
};

type AllStats = {
  all_stats: InstallStats;
  stats: SchoolStats[];
};

export const getAllStats = function (): AllStats {
  const schools = getModels("schools");
  const installs = getModels("installs");

  const stats = schools.map((s) => getStatsForSchool(s, installs));
  const all_stats: InstallStats = {};

  shorts.forEach(
    (s) =>
      (all_stats[s] = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      })
  );

  stats.forEach((stat) => {
    shorts.forEach((short) => {
      const counts = stat.prods[short]!;

      all_stats[short]!.total += counts.total;
      all_stats[short]!.pending_removal += counts.pending_removal;
      all_stats[short]!.uninstalled += counts.uninstalled;
      all_stats[short]!.removed += counts.removed;
    });
  });

  return {
    all_stats,
    stats,
  };
};

export const actionsForUser = (user: User): InstallAction[] => {
  const actions: InstallAction[] = [];

  const install_actions = getModels("install_actions");
  const len = install_actions.length;

  let ia: InstallAction;

  for (let i = 0; i < len; i++) {
    ia = install_actions[i]!;
    if (ia.touchedByUser(user)) {
      actions.push(ia);
    }
  }

  return actions;
};

export const actionsForUserAndDate = (
  user: User,
  date: Date
): InstallAction[] => {
  const actions: InstallAction[] = [];

  const install_actions = getModels("install_actions");
  const len = install_actions.length;

  let ia: InstallAction;

  for (let i = 0; i < len; i++) {
    ia = install_actions[i]!;
    if (ia.touchedByUser(user) && areSameDay(ia.created_at, date)) {
      actions.push(ia);
    }
  }

  return actions;
};

export const getStatsForUser = function (user: User): ShortShortMoneyNumbers {
  const stats: ShortShortMoneyNumbers = {};

  short_shorts.forEach(
    (s) =>
      (stats[s] = {
        total: 0,
        uninstalled: 0,
        removed: 0,
      })
  );

  let action: InstallAction, short_short: string, cnt: number;

  const actions = actionsForUser(user);

  for (let i = 0; i < actions.length; i++) {
    action = actions[i]!;

    if (!action.set) continue;
    if (action.money_type == null) continue;

    short_short = action.install.product.short_short;

    cnt = action.individualCount();

    stats[short_short]!.total += cnt;

    stats[short_short]![action.money_type] += cnt;
  }

  return stats;
};

const dayMonth = (date: Date): string => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

type DateAction = {
  str: string;
  date: Date;
};

export const getDaysWorked = (user: User): DateAction[] => {
  const actionDays: DateAction[] = actionsForUser(user).map((a) => ({
    str: dayMonth(a.created_at),
    date: a.created_at,
  }));

  const truckLoadDays: DateAction[] = compact(
    getModels("truck_loads").map((d) =>
      d.workedTruckLoad(user)
        ? { str: dayMonth(d.performed_at), date: d.performed_at }
        : null
    )
  );

  const bbDays: DateAction[] = compact(
    getModels("bonus_bucks").map((bb) =>
      bb.worker_ids.includes(user.id)
        ? { str: dayMonth(bb.performed_at), date: bb.performed_at }
        : null
    )
  );

  let days = concat(actionDays, truckLoadDays, bbDays);

  days = sortBy(
    uniqBy(days, (d) => d.str),
    (d) => d.date
  );

  return days;
};

export const getStatsForUserOnDay = function (
  user: User,
  date: Date
): ShortShortMoneyNumbers {
  const stats: ShortShortMoneyNumbers = {};

  short_shorts.forEach(
    (s) =>
      (stats[s] = {
        total: 0,
        uninstalled: 0,
        removed: 0,
      })
  );

  let action: InstallAction, short_short: string, cnt: number;

  const actions = actionsForUser(user);

  for (let i = 0; i < actions.length; i++) {
    action = actions[i]!;

    if (!action.set) continue;
    if (action.money_type == null) continue;
    if (!areSameDay(action.created_at, date)) continue;

    short_short = action.install.product.short_short;

    cnt = action.individualCount();

    stats[short_short]!.total += cnt;

    stats[short_short]![action.money_type] += cnt;
  }

  return stats;
};

export const getMoneyForStats = function (
  stats: ShortShortMoneyNumbers,
  user: User
): ShortShortMoneyStats {
  const uninstall_payscale = Payscale.find("uninstalled", user);
  const removal_payscale = Payscale.find("removed", user);

  const money: Partial<ShortShortMoneyStats> = {};

  let s: MoneyAmounts, room_stats: MoneyNumbers;

  short_shorts.forEach((short) => {
    room_stats = stats[short]!;

    const uninstalled_cents = uninstall_payscale.short_cents[short]!;
    const removed_cents = removal_payscale.short_cents[short]!;

    const uninstalled_amount = uninstalled_cents * room_stats.uninstalled;
    const removed_amount = removed_cents * room_stats.removed;

    s = {
      uninstalled_amount: uninstalled_amount,
      removed_amount: removed_amount,
      total_amount: uninstalled_amount + removed_amount,
    };

    money[short] = s;
  });

  return money as ShortShortMoneyStats;
};

export const computeBonusBuckTotal = (user: User): number => {
  const bonus_bucks = filterModelsByAttrInclusion(
    getModels("bonus_bucks"),
    "worker_ids",
    user.id
  ).filter((bb) => bb.confirmed);

  return sumBy(bonus_bucks, (bb) => bb.individualDollars());
};

export const computeBonusBuckTotalOnDay = (user: User, date: Date): number => {
  const bonus_bucks = getModels("bonus_bucks").filter(
    (bb) =>
      bb.worker_ids.includes(user.id) &&
      areSameDay(bb.performed_at, date) &&
      bb.confirmed
  );

  return sumBy(bonus_bucks, (bb) => bb.individualDollars());
};

export const computeRoomTotal = (money: ShortShortMoneyStats): number => {
  return sum(map(money, (nums) => nums.total_amount));
};

export const getDrivingTotalForUser = (user: User): number => {
  const truck_loads = getModels("truck_loads");
  const dropoff_drivings = truck_loads.filter(
    (d) => d.type === "unload" && d.driver_id === user.id
  );
  const pickup_drivings = truck_loads.filter(
    (d) => d.type === "load" && d.driver_id === user.id
  );

  const driverPayscale = findModelByAttr(
    getModels("payscales"),
    "type",
    "driver"
  );
  const dropoff_cents = sumBy(dropoff_drivings, (d) =>
    d.totalCents(driverPayscale)
  );

  const pickup_cents = sumBy(pickup_drivings, (d) =>
    d.totalCents(driverPayscale)
  );

  return dropoff_cents + pickup_cents;
};

export const getDrivingTotalForUserAndDate = (
  user: User,
  date: Date
): number => {
  const truck_loads = getModels("truck_loads");
  const dropoff_drivings = truck_loads.filter(
    (d) =>
      d.type === "unload" &&
      d.driver_id === user.id &&
      areSameDay(d.performed_at, date)
  );
  const pickup_drivings = truck_loads.filter(
    (d) =>
      d.type === "load" &&
      d.driver_id === user.id &&
      areSameDay(d.performed_at, date)
  );

  const driverPayscale = Payscale.find("driver", user);
  const dropoff_cents = sumBy(dropoff_drivings, (tl) =>
    tl.totalCents(driverPayscale)
  );

  const pickup_cents = sumBy(pickup_drivings, (tl) =>
    tl.totalCents(driverPayscale)
  );

  return dropoff_cents + pickup_cents;
};
