import Link from "@material-ui/core/Link";
import { Tabs, Tab, Typography } from "@material-ui/core";
import { Building } from "../models/Building";
import { getFilteredRooms } from "../helpers/filter_helpers";
import { install_status_colors } from "../constants/colors";
import { getModels, store } from "../init_app/globals";
import { getStatsForBuilding } from "../helpers/stats_helpers";
import { filterModelsByAttr } from "../util/data_util";
import StatsTable from "./stats/StatsTable";
import React from "react";

const renderStats = (building: Building, tab: number): JSX.Element => {
  if (tab === 0) {
    const filter = window.App.filter;

    if (filter) {
      const rooms = getFilteredRooms(filter);

      const stats = getStatsForBuilding(building, rooms);

      return StatsTable(stats, building.shown_name);
    } else {
      return <Typography>No filter set</Typography>;
    }
  } else {
    const rooms = filterModelsByAttr(
      getModels("rooms"),
      "building_id",
      building.id
    );

    const stats = getStatsForBuilding(building, rooms);

    return StatsTable(stats, building.shown_name);
  }
};

export const BuildingDetail = (): JSX.Element => {
  const building = store.useNonNullState("building");
  const tab = store.useNonNullState("tab");

  const gmaps_link = building.gmaps_link?.trim();

  React.useEffect(() => {
    store.state.subtitle = undefined;
    store.state.toptitle = undefined;
    store.setState("title", building.shown_name);
  }, [building.shown_name]);

  return (
    <div className="p-1">
      <p>{building.notes}</p>
      {gmaps_link == null || gmaps_link === "" ? null : (
        <Link target="_blank" rel="noopener noreferrer" href={gmaps_link}>
          {gmaps_link}
        </Link>
      )}

      <Tabs
        className="mt-2"
        value={tab}
        onChange={(_, newTab): void => {
          store.setState("tab", newTab);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Filtered stats" />
        <Tab label="All stats" />
      </Tabs>
      {renderStats(building, tab)}

      <div className="d-flex flex-wrap justify-content-between">
        <span
          style={{ color: install_status_colors.pending_removal }}
          className="ml-1"
        >
          pending removal
        </span>
        <span
          style={{ color: install_status_colors.uninstalled }}
          className="ml-1"
        >
          uninstalled
        </span>
        <span style={{ color: install_status_colors.removed }} className="ml-1">
          removed
        </span>
        <span style={{ color: install_status_colors.missing }} className="ml-1">
          missing
        </span>
      </div>
    </div>
  );
};
