import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { store } from "../../init_app/globals";

type WorkerTabsProps = {
  tab: number;
  active_workers_count: number;
  inactive_workers_count: number;
};

const changeTab = (event: any, tab: number): void => {
  store.setState("tab", tab);
};

function WorkerTabs(props: WorkerTabsProps): JSX.Element {
  const { tab, active_workers_count, inactive_workers_count } = props;

  return (
    <AppBar position="static" color="default" style={{ width: "100%" }}>
      <Tabs
        value={tab}
        onChange={changeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={`Active workers (${active_workers_count})`} />
        <Tab label={`Inactive workers (${inactive_workers_count})`} />
      </Tabs>
    </AppBar>
  );
}

export default React.memo(WorkerTabs);
