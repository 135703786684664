/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { User } from "../models/User";
import { FullProfileFormState } from "../types/state_types";

export const getFullProfileFormState = (user: User): FullProfileFormState => {
  return {
    user: user,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    username: user.lowerName(),
    phone: user.phone,
    address1: user.address1 || "",
    city: user.city || "",
    state: user.state || "",
    zip_code: user.zip_code || "",
    active: user.active || false,
    ssn: user.ssn || "",
    working: false,
    errors: [],
  };
};
