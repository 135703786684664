import { getModels } from "../init_app/globals";
import { filterModelsByAttr } from "../util/data_util";
import { School } from "./School";
import { Ticket } from "./Ticket";

export type BuildingJSON = {
  id: number;
  name: string;
  shown_name: string;
  notes: string | null;
  gmaps_link: string | null;
  school_id: number;
  school: School;
};

export class Building {
  id: number;
  name: string;
  shown_name: string;
  notes: string | null;
  gmaps_link: string | null;
  school_id: number;
  school: School;

  constructor(json: BuildingJSON, school: School) {
    this.id = json.id;
    this.name = json.name;
    this.shown_name = json.shown_name;
    this.notes = json.notes;
    this.gmaps_link = json.gmaps_link;
    this.school_id = json.school_id;
    this.school = school;
  }

  isBuilding(): this is Building {
    return true;
  }

  getUnresolvedTickets(): Ticket[] {
    const room_ids = filterModelsByAttr(
      getModels("rooms"),
      "building_id",
      this.id
    ).map((r) => r.id);

    return getModels("tickets").filter(
      (t) => !t.resolved_at && room_ids.includes(t.room_id)
    );
  }
}
