import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CloseableErrorAlert from "../util/CloseableErrorAlert";
import { getModels, store } from "../../init_app/globals";
import { User, UserJSON } from "../../models/User";
import { ApiRequest } from "../../libs/ApiRequest";
import { isErrorResponse } from "../../util/app_util";
import { findModelById } from "../../util/data_util";
import { FullProfileFormState } from "../../types/state_types";

const {
  getNonNullState,
  setPartialState,
  useNonNullState,
} = store.getScopedHelpers("full_profile_form");

type UserResp = {
  user: UserJSON;
};

const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
  event.preventDefault();

  const state = getNonNullState();

  if (state.working) {
    return;
  }

  setPartialState({ working: true });

  const data = {
    user_id: state.user.id,
    profile: state,
  };

  ApiRequest.perform({
    url: `/worker_api/full_profile_update.json`,
    method: "PATCH",
    onSuccess: (resp: unknown): void => {
      const json = resp as UserResp;
      const heldUser = new User(json.user);
      const user = findModelById(getModels("users"), state.user.id);

      Object.assign(user, heldUser);

      getNonNullState().working = false;
      store.forceUpdate("viewShown");
    },
    onError: (json): void => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors, working: false });
      } else {
        alert("an error occurred");
        setPartialState({ working: false });
      }
    },
    data,
  });
};

const onChange = <Key extends keyof FullProfileFormState>(
  attr: Key,
  value: FullProfileFormState[Key]
): void => {
  setPartialState({ [attr]: value });
};

const onCloseErrorAlert = (): void => {
  setPartialState({ errors: [] });
};

const renderSsn = (
  admin: boolean,
  isUser: boolean,
  ssn: string
): JSX.Element | null => {
  if (admin || isUser) {
    return (
      <TextField
        value={ssn}
        onChange={(e): void => onChange("ssn", e.target.value)}
        label="SSN"
        variant="outlined"
        className="mt-2"
      />
    );
  }

  return null;
};

const FullProfileForm = (): JSX.Element => {
  const state = useNonNullState();

  const currentUser = store.getNonNullState("currentUser");

  const admin = currentUser.admin;
  const isUser = currentUser.id === state.user.id;

  return (
    <div className="p-1 pb-4">
      <CloseableErrorAlert
        errors={state.errors}
        closeAlert={onCloseErrorAlert}
      />
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            value={state.email}
            onChange={(e): void => onChange("email", e.target.value)}
            label="Email"
            variant="outlined"
            disabled
            className="mt-2"
          />
          <TextField
            value={state.first_name || ""}
            onChange={(e): void => onChange("first_name", e.target.value)}
            label="First name"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.last_name || ""}
            onChange={(e): void => onChange("last_name", e.target.value)}
            label="Last name"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.username || ""}
            onChange={(e): void => onChange("username", e.target.value)}
            label="Username"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.phone || ""}
            onChange={(e): void => onChange("phone", e.target.value)}
            label="Phone number"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.address1 || ""}
            onChange={(e): void => onChange("address1", e.target.value)}
            label="Street Address"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.city || ""}
            onChange={(e): void => onChange("city", e.target.value)}
            label="City"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.state || ""}
            onChange={(e): void => onChange("state", e.target.value)}
            label="State"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.zip_code || ""}
            onChange={(e): void => onChange("zip_code", e.target.value)}
            label="Zip code"
            variant="outlined"
            className="mt-2"
          />

          {renderSsn(admin, isUser, state.ssn || "")}

          <Button
            disabled={state.working}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="mt-2"
            disableElevation
          >
            Save
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default FullProfileForm;
