import Typography from "@material-ui/core/Typography";
import { BonusBuck } from "../../models/BonusBuck";
import strftime from "strftime";
import Box from "@material-ui/core/Box";
import { User } from "../../models/User";
import { andJoin, isPresent, centsToDollarString } from "../../util/util";
import sortBy from "lodash/sortBy";
import { getModels } from "../../init_app/globals";

const renderBB = (bb: BonusBuck): JSX.Element => {
  const date = strftime("%-m/%-d/%y", bb.performed_at);
  const users = andJoin(bb.getWorkers().map((u) => u.lowerName()));
  const reason = isPresent(bb.reason) ? bb.reason : "reasons";
  const each_cents = bb.individualDollars() * 100;

  return (
    <Box
      key={`bb_${bb.id}`}
      className="job-row"
      style={{ minHeight: 50 }}
      display="flex"
      alignItems="center"
    >
      {users} received ${bb.dollars} on {date} for {reason} (
      {centsToDollarString(each_cents)} each)
    </Box>
  );
};

interface UserWorkBonusesProps {
  user: User;
}

const UserWorkBonuses = (props: UserWorkBonusesProps): JSX.Element => {
  const { user } = props;
  let bonus_bucks = getModels("bonus_bucks").filter((bb) =>
    bb.worker_ids.includes(user.id)
  );

  bonus_bucks = sortBy(bonus_bucks, (bb) => -bb.performed_at);

  return (
    <div className="mt-3">
      <Typography
        variant="h6"
        gutterBottom
        className="d-flex justify-content-between align-items-center"
      >
        Your Bonus Bucks ({bonus_bucks.length})
      </Typography>

      {bonus_bucks.map(renderBB)}
    </div>
  );
};

export default UserWorkBonuses;
