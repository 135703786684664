/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { TruckLoad } from "../../models/TruckLoad";
import { Count, counts, getShortShort } from "../../constants/shorts";
import { Payscale } from "../../models/Payscale";
import { centsToDollarString } from "../../util/util";
import { shortFromCount } from "../../util/app_util";

const renderCount = (
  truck_load: TruckLoad,
  count: Count,
  payscale: Payscale
): JSX.Element => {
  const short = shortFromCount(count);
  const short_short = getShortShort(short);
  const cents = payscale.getCents(short_short);

  const count_amount = truck_load[count];

  const total = count_amount * cents;

  return (
    <tr key={`td_${count}`}>
      <td className="fw-500" style={{ minWidth: 65 }}>
        {short}:
      </td>
      <td>{truck_load[count]}</td>
      <td className="px-3">{centsToDollarString(cents)}</td>
      <td>{centsToDollarString(total)}</td>
    </tr>
  );
};

interface TruckLoadCountsTableType {
  truck_load: TruckLoad;
  payscale: Payscale;
}

const TruckLoadCountsTable = (props: TruckLoadCountsTableType): JSX.Element => {
  const { truck_load, payscale } = props;
  const total = truck_load.total();
  const workers_length = truck_load.worker_ids.length;

  const total_cents = truck_load.totalCents(payscale);
  const each_made_cents =
    payscale.type === "driver" ? null : total_cents / workers_length;

  return (
    <div>
      <table id="truck_load_calc_table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style={{ textAlign: "center" }}>per</th>
            <th>total</th>
          </tr>
        </thead>
        <tbody>
          {counts.map((count: Count) =>
            renderCount(truck_load, count, payscale)
          )}
          <tr>
            <td className="fw-500">Total:</td>
            <td>{total}</td>
            <td></td>
            <td className="fw-500">{centsToDollarString(total_cents)}</td>
          </tr>
        </tbody>
      </table>

      {each_made_cents ? (
        <div className="fw-500 mt-3">
          Each worker earned {centsToDollarString(each_made_cents)}
        </div>
      ) : (
        <div className="fw-500 mt-3">
          Driver earned {centsToDollarString(total_cents)}
        </div>
      )}
    </div>
  );
};

export default TruckLoadCountsTable;
