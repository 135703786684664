export type Environment = "production" | "development";
const host = window.location.host;
export const getEnvironment = (): Environment | null => {
  if (host === "removals.dormsdirect.com" || host === "mfr.dormsdirect.com") {
    return "production";
  } else if (/^localhost/.test(host)) {
    return "development";
  }

  return null;
};

const env = getEnvironment();
const bucket = env === "production" ? "dd4-paperclip-prod" : "nada";

// https://stackoverflow.com/a/1772978/548170
function chunk(str: string, n: number, join = "/"): string {
  const ret: string[] = [];
  let i: number;
  let len: number;

  for (i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substr(i, n));
  }

  return ret.join(join);
}

const getPaperclipIdPath = (id: number): string => {
  return chunk(id.toString().padStart(9, "0"), 3);
};

export type ImageSize = "original" | "thumb";
export const getImageUrl = (
  id: number,
  table: string,
  size: ImageSize,
  filename: string
): string => {
  const pc_id = getPaperclipIdPath(id);

  if (env === "development") {
    return `/system/${table}/images/${pc_id}/${size}/${filename}`;
  } else {
    return `
      https://${bucket}.s3.amazonaws.com/${table}/images/${pc_id}/${size}/${filename}
    `;
  }
};
