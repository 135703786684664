import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { router, store } from "../../init_app/globals";
import Typography from "@material-ui/core/Typography";
import BuildIcon from "@material-ui/icons/Build";
import EditIcon from "@material-ui/icons/Edit";

const openDrawer = (): void => {
  store.setState("drawerOpen", true);
};

const clickFilter = (): void => {
  const state = window.App.filter!.modalState();

  store.setState("filter_modal", state);
};

const goBack = (href: string) => (): void => {
  router.go(href);
};

const clickJobs = (): void => {
  router.go("/jobs");
};

const clickEdit = (): void => {
  const search = store.state.search;
  if (search != null) {
    const resp = window.prompt("search by student name", search);

    if (resp != null && resp.trim() !== "") {
      const path = `/jobs/search/${encodeURIComponent(resp)}`;
      window.resetList();
      router.go(path);
    }
  }
};

const getRightIcon = (
  icon?: "filter" | "jobs" | "pencil" | null
): JSX.Element | null => {
  if (icon == null) {
    return null;
  }

  if (icon === "filter") {
    return (
      <IconButton onClick={clickFilter} edge="start" color="inherit">
        <FilterListIcon />
      </IconButton>
    );
  } else if (icon === "jobs") {
    return (
      <IconButton onClick={clickJobs} edge="start" color="inherit">
        <BuildIcon />
      </IconButton>
    );
  } else if (icon === "pencil") {
    return (
      <IconButton onClick={clickEdit} edge="start" color="inherit">
        <EditIcon />
      </IconButton>
    );
  } else {
    return null;
  }
};

export default function MyAppBar(): JSX.Element {
  const title = store.useState("title");
  const subtitle = store.useState("subtitle");
  const toptitle = store.useState("toptitle");
  const drill_href = store.useState("drill_href");
  const rightIcon = store.useState("rightIcon");

  return (
    <AppBar position="static" style={{ width: "100%" }}>
      <Toolbar
        style={{ paddingRight: 2 }}
        className="d-flex justify-content-between"
      >
        {drill_href == null ? (
          <IconButton onClick={openDrawer} edge="start" color="inherit">
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton onClick={goBack(drill_href)} edge="start" color="inherit">
            <ArrowBackIcon />
          </IconButton>
        )}
        <div className="flex-grow-1 text-align-center">
          {toptitle == null ? null : (
            <Typography style={{ fontSize: 12 }}>{toptitle}</Typography>
          )}
          {title == null ? null : <Typography>{title}</Typography>}
          {subtitle == null ? null : (
            <div style={{ fontSize: 10 }}>{subtitle}</div>
          )}
        </div>

        {getRightIcon(rightIcon)}
      </Toolbar>
    </AppBar>
  );
}
