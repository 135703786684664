import React, { CSSProperties } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Ticket } from "../../models/Ticket";
import { getModels, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { findModelById } from "../../util/data_util";
import { TicketRespJSON } from "../../types/json_types";
import { User } from "../../models/User";
import { then } from "../../util/util";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const { setPartialState, setState, useStoreState } = store.getScopedHelpers(
  "ticket_modal"
);

function getModalStyle(): CSSProperties {
  const top = 50;

  return {
    top: `${top}%`,
    left: 0,
    right: 0,
    transform: `translateY(-${top}%)`,
    outline: 0,
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  })
);

const clickUpdate = (id: number, text: string, working: boolean): void => {
  if (working) {
    return;
  }

  const data = {
    text: text,
  };
  setPartialState({ working: true });
  ApiRequest.perform({
    url: `/worker_api/tickets/${id}.json`,
    method: "PATCH",
    onSuccess: (resp: unknown) => {
      const allTickets = getModels("tickets");
      const ticket = findModelById(allTickets, id);
      const room = findModelById(getModels("rooms"), ticket.room_id);

      const json = resp as TicketRespJSON;

      const creator = findModelById(getModels("users"), json.ticket.creator_id);
      const resolver: User | null = then(json.ticket.resolved_by_id, (id) =>
        id == null ? null : findModelById(getModels("users"), id)
      );

      const nextTicket = new Ticket(json.ticket, creator, room, resolver);
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;
      room.tickets_count = json.room.tickets_count;

      Object.assign(ticket, nextTicket);

      store.state.ticket_modal = undefined;

      store.forceUpdate("room");
    },

    onError: () => {
      alert("an error occurred");
    },
    data,
  });
};

function clickSubmit(text: string, working: boolean): void {
  if (working) {
    return;
  }

  const room = store.getNonNullState("room");

  setPartialState({ working: true });

  const data = {
    room_id: room.id,
    text: text,
  };

  ApiRequest.perform({
    url: "/worker_api/tickets.json",
    method: "POST",
    onSuccess: (resp: unknown): void => {
      const json = resp as TicketRespJSON;
      const creator = findModelById(getModels("users"), json.ticket.creator_id);
      const resolver: User | null = then(json.ticket.resolved_by_id, (id) =>
        id == null ? null : findModelById(getModels("users"), id)
      );

      const ticket = new Ticket(json.ticket, creator, room, resolver);
      getModels("tickets").unshift(ticket);

      room.tickets_count = json.room.tickets_count;
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      store.state.ticket_modal = undefined;

      store.forceUpdate("room");
    },
    onError: (): void => {
      alert("an error occurred....  or did it?  yes it did");
      setPartialState({ working: false });
    },

    data,
  });
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  setPartialState({ text: event.target.value });
};

const handleClose = (): void => {
  setState(undefined);
};

export const TicketModal = (): JSX.Element | null => {
  const state = useStoreState();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  if (state == null) {
    return null;
  }

  const { id, working, text } = state;

  const title =
    id == null
      ? "Why does this room need attention?"
      : "Update needs attention ticket";

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            {title}
          </Typography>

          <TextField
            style={{ width: "100%" }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={text}
            onChange={onChange}
            variant="outlined"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button
            className="next-button"
            onClick={handleClose}
            variant="outlined"
            disabled={working}
          >
            Cancel
          </Button>

          {id != null ? (
            <Button
              onClick={(): void => clickUpdate(id, text, working)}
              className="next-button"
              variant="outlined"
              disabled={working}
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={(): void => clickSubmit(text, working)}
              className="next-button"
              variant="outlined"
              disabled={working}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
