/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Button from "@material-ui/core/Button";
import { getModels, store } from "../../init_app/globals";
import { FieldNote } from "../../models/FieldNote";
import { findModelById } from "../../util/data_util";
import { FieldNoteJsonResp } from "./FieldNoteModal";

const { getNonNullState, setPartialState } =
  store.getScopedHelpers("field_note_modal");

function clickSave(id: number, working: boolean): void {
  const { note, image_file_name } = getNonNullState();
  if (working) {
    return;
  }

  const room = store.getState("room");

  if (room == null) {
    throw new Error("no room, wtf");
  }

  const formData = new FormData();

  const fileInput = document.querySelector("input#image") as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append("image", fileInput.files[0]!);
  }

  formData.append("note", note);
  formData.append("image_file_name", image_file_name || "null");

  setPartialState({ working: true });
  fetch(`/worker_api/field_notes/${id}`, {
    method: "PUT",
    body: formData,
  })
    .then((response) => response.json())
    .then((resp: unknown): void => {
      const json = resp as FieldNoteJsonResp;
      const allFieldNotes = getModels("field_notes");
      const field_note = findModelById(allFieldNotes, id);

      const nextFieldNote = new FieldNote(json.field_note, field_note.creator);
      room.field_notes = undefined;

      const index = allFieldNotes.indexOf(field_note);
      allFieldNotes[index] = nextFieldNote;

      store.state.field_note_modal = undefined;
      store.forceUpdate("viewShown");
    })
    .catch(() => {
      setPartialState({ working: false });
    });
}

interface FieldNoteSaveButtonProps {
  id: number;
  working: boolean;
}

export const FieldNoteSaveButton = (
  props: FieldNoteSaveButtonProps
): JSX.Element => {
  const { id, working } = props;
  return (
    <Button
      onClick={(): void => clickSave(id, working)}
      variant="outlined"
      className="next-button"
      size="large"
      disabled={working}
    >
      Save
    </Button>
  );
};
