export const findModelById = function <T extends { id: number }>(
  arr: Array<T>,
  id: number
): NonNullable<T> {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index]!.id === id) return arr[index]!;
  }

  throw new Error(`Couldn't find ${arr[0]!.constructor.name} by id ${id}`);
};

export const findModelByAttr = function <T, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key]
): T {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index]![attr] === value) return arr[index]!;
  }

  throw new Error(`Couldn't find thing by attr ${attr} & val ${value}`);
};

export const filterModelsByAttr = function <T, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key]
): T[] {
  const length = arr.length;

  const foundItems: Array<T> = [];
  let item: T;

  for (let index = 0; index < length; index++) {
    item = arr[index]!;
    if (item[attr] === value) {
      foundItems.push(item);
    }
  }

  return foundItems;
};

export const groupById = <T extends { id: number }>(
  arr: T[]
): { [id: number]: T } => {
  const obj: { [id: number]: T } = {};
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index]!;

    obj[element.id] = element;
  }

  return obj;
};

export const filterModelsByInclusion = function <
  T extends { id: number },
  Key extends keyof T
>(arr: Array<T>, attr: Key, ids: number[]): T[] {
  const length = arr.length;
  const models = [];
  let model: T;

  for (let index = 0; index < length; index++) {
    model = arr[index]!;
    const id: unknown = model[attr];
    if (ids.includes(id as number)) {
      models.push(model);
    }
  }

  return models;
};

export const groupByIdAttr = <T, Key extends keyof T>(
  arr: T[],
  attr: Key
): { [id: number]: T[] } => {
  const obj: { [id: number]: T[] } = {};

  for (let index = 0; index < arr.length; index++) {
    const ele = arr[index]!;
    const val = ele[attr] as unknown as number;

    if (obj[val] == null) {
      obj[val] = [ele];
    } else {
      obj[val]!.push(ele);
    }
  }

  return obj;
};
