import { install_status_colors } from "../constants/colors";
import { Install, InstallStatus } from "../models/Install";
import { store } from "../init_app/globals";

export const getColor = (status: InstallStatus): string => {
  const color = install_status_colors[status];

  if (color) {
    return color;
  } else {
    throw new Error(`couldnt determine short color for status ${status}`);
  }
};

export const renderShort = (install: Install): JSX.Element => {
  const color = getColor(install.status);
  const style = {
    color: store.getState("colormode") === "dark" ? "black" : "white",
    backgroundColor: color,
    border: "1px solid color",
    borderRadius: 5,
  };

  const short = install.getShort();

  return (
    <span
      style={style}
      key={`short_${install.id}`}
      className="p-0 px-1 short mx-1 mt-1 short"
    >
      {short}
    </span>
  );
};
