import React from "react";
import { centsToDollarString } from "../../util/util";

interface TotalsTableProps {
  total: number;
  bonus_buck_total: number;
  truck_load_total: number;
  room_total: number;
}

function TotalsTable(props: TotalsTableProps): JSX.Element {
  const { total, room_total, truck_load_total, bonus_buck_total } = props;
  return (
    <table className="muhtable mb-2">
      <thead>
        <tr>
          <th>rooms</th>
          <th>truck</th>
          <th>bonus</th>
          <th>total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{centsToDollarString(room_total)}</td>
          <td>{centsToDollarString(truck_load_total)}</td>
          <td>{centsToDollarString(bonus_buck_total * 100)}</td>
          <td>{centsToDollarString(total)}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default React.memo(TotalsTable);
