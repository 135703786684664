import sumBy from "lodash/sumBy";
import sortBy from "lodash/sortBy";
import Button from "@material-ui/core/Button";
import { Paycheck } from "../../models/Paycheck";
import { User } from "../../models/User";
import strftime from "strftime";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { centsToDollarString, then } from "../../util/util";
import { getModels, store } from "../../init_app/globals";
import PaycheckModal from "../selects/PaycheckModal";

const { setState } = store.getScopedHelpers("paycheck_modal");

const clickAdd = (user: User): void => {
  const state = Paycheck.getModalState(null, user.id);
  state.payee_id = user.id;

  setState(state);
};

const clickEdit = (paycheck: Paycheck): void => {
  const state = Paycheck.getModalState(paycheck, null);

  setState(state);
};

const renderType = (type: string, check_number: string): string => {
  const str = type;

  if (type === "check" && check_number?.trim()) {
    return `${str} (#${check_number})`;
  } else {
    return str;
  }
};

const renderPaycheck = (paycheck: Paycheck): JSX.Element => {
  const key = `pc_${paycheck.id}`;
  const date_str = strftime("%b %e %Y", paycheck.date_at);
  const amt = centsToDollarString(paycheck.amount_cents);
  const { type, notes, check_number } = paycheck;

  return (
    <div key={key} className="my-3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          Received {amt} on {date_str} via {renderType(type, check_number)}
        </div>

        <Button
          onClick={(): void => clickEdit(paycheck)}
          size="small"
          variant="outlined"
        >
          Edit
        </Button>
      </Box>
      {then(notes?.trim(), (n) =>
        n == null || n === "" ? null : (
          <div>
            <b>notes:</b> {notes}
          </div>
        )
      )}
    </div>
  );
};

interface ReceivedProps {
  user: User;
  total: number;
}

function Received(props: ReceivedProps): JSX.Element {
  const { user, total } = props;

  let paychecks = getModels("paychecks").filter((p) => p.payee_id === user.id);
  paychecks = sortBy(paychecks, (p) => -p.date_at);

  const total_cents = sumBy(paychecks, (p) => p.amount_cents);

  const remaining = total_cents < 0 ? total_cents + total : total - total_cents;

  return (
    <div className="p-1">
      <div className="mb-2">
        <table style={{ width: "100%" }} className="muhtable">
          <thead>
            <tr>
              <th>Received</th>
              <th>Earned</th>
              <th>Remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{centsToDollarString(total_cents)}</td>
              <td>{centsToDollarString(total)}</td>
              <td>{centsToDollarString(remaining)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <Typography
          variant="h6"
          gutterBottom
          className="d-flex justify-content-between align-items-center"
        >
          Paychecks ({paychecks.length})
          <Button
            onClick={(): void => clickAdd(user)}
            className="next-button my-2"
            size="small"
            variant="outlined"
          >
            Add Paycheck
          </Button>
        </Typography>
        {paychecks.map(renderPaycheck)}
      </div>

      <PaycheckModal />
    </div>
  );
}

export default Received;
