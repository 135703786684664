import { Product } from "./Product";

export type UpgradeJSON = {
  id: number;
  product_id: number;
  short: string;
  name: string;
};

export class Upgrade {
  id: number;
  product_id: number;
  product: Product;
  short: string;
  name: string;

  constructor(json: UpgradeJSON, product: Product) {
    this.id = json.id;
    this.name = json.name;
    this.product_id = json.product_id;
    this.product = product;
    this.short = json.short;
  }

  key(): string {
    return `Upgrade_${this.id}`;
  }

  labelName(): string {
    return this.name
      .replace(/\(.*?\)/g, "")
      .replace(/\s\s+/, " ")
      .trim();
  }

  formLabel(): string {
    if (this.product.short_short === "TV") {
      return `${this.short.split("_")[1]} ${this.name}`;
    } else {
      return this.name;
    }
  }
}
