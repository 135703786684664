import strftime from "strftime";
import { install_status_colors } from "../../constants/colors";
import { shorts } from "../../constants/shorts";
import { getStatsForBuilding, RoomTotals } from "../../helpers/stats_helpers";
import { getModels, store } from "../../init_app/globals";
import { Building } from "../../models/Building";
import { Ticket } from "../../models/Ticket";
import { findModelsByIdInclusion } from "../../util/app_util";
import { filterModelsByAttr } from "../../util/data_util";
import { then } from "../../util/util";
import SchoolSelectMeh from "../selects/SchoolSelectMeh";
import CenterAll from "../util/CenterAll";

const renderTicket = (ticket: Ticket): JSX.Element => {
  const key = `building-row-ticket-${ticket.id}`;
  const created_at_str = strftime("%b %e %l:%M %p", ticket.created_at);
  const txt = `"${ticket.text}"`;

  return (
    <div key={key} className="d-flex align-items-center">
      <div>{txt}</div>

      <div>
        by {ticket.creator.username} @ {created_at_str}
      </div>
    </div>
  );
};

const renderBuilding = (b: Building): JSX.Element => {
  const rooms = filterModelsByAttr(getModels("rooms"), "building_id", b.id);
  const stats = getStatsForBuilding(b, rooms);

  const jsx = shorts.map((s) => renderSpans(stats[s], s));

  const tickets = b.getUnresolvedTickets();

  return (
    <div
      key={`building_${b.id}`}
      style={{ minHeight: 55 }}
      className="d-flex align-items-center next-bottom-border py-1"
    >
      <div>
        {b.shown_name}: {jsx}
        {tickets.length > 0 ? <div>{tickets.map(renderTicket)}</div> : null}
      </div>
    </div>
  );
};

let key1 = 0;

const renderSpans = (
  totals: RoomTotals | undefined,
  short: string
): JSX.Element | null => {
  if (totals == null) return null;

  const total = totals.total;
  if (total == null) return null;

  const { pending_removal, uninstalled } = totals;

  return (
    <span key={`span${(key1 += 1)}`}>
      <span className="mx-1">
        {`${short} `}
        <span style={{ color: install_status_colors.pending_removal }}>
          {pending_removal}
        </span>
        ,
        <span style={{ color: install_status_colors.uninstalled }}>
          {uninstalled}
        </span>
      </span>
    </span>
  );
};

export const BuildingStats = (): JSX.Element => {
  const filter = window.App.filter;

  if (filter == null) {
    return (
      <CenterAll className="mt-4">
        <SchoolSelectMeh />
      </CenterAll>
    );
  } else {
    const [buildings, title] = then(filter.building_ids, (bids) => {
      if (bids.length) {
        return [
          findModelsByIdInclusion(getModels("buildings"), bids),
          `filtering by ${bids.length} buildings`,
        ];
      } else {
        return [filterModelsByAttr(
          getModels("buildings"),
          "school_id",
          filter.school_id
        ), "filtering by school"];
      }
    });

    setTimeout(() => {
      store.setStateIfDifferent('subtitle', title)
    })

    return <div>{buildings.map(renderBuilding)}</div>;
  }
};
