import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import sortBy from "lodash/sortBy";
import React from "react";
import {
  getFieldNoteIcon,
  getLockedIcon,
  getOccupiedIcon,
} from "../../helpers/icon_helpers";
import { getImageUrl } from "../../helpers/paperclip_helpers";
import { setTicketModalState } from "../../helpers/ticket_helpers";
import { store } from "../../init_app/globals";
import { FieldNote } from "../../models/FieldNote";
import { Activity } from "../../models/Room";
import { FieldNoteModal } from "./FieldNoteModal";
import InstallActionModal from "./InstallActionModal";
import LockedSection from "./LockedSection";
import OccupiedButton from "./OccupiedButton";
import { ReservationRow } from "./ReservationRow";
import { TicketModal } from "./TicketModal";
import TicketResolutionModal from "./TicketResolutionModal";
import { TicketsSection } from "./TicketsSection";
import NoteIcon from "@material-ui/icons/Note";
import InstallButton from "./InstallButton";

const clickNeedsAttention = (): void => {
  setTicketModalState(null);
};

const clickEditNote = (note: FieldNote): void => {
  const state = FieldNote.getModalState(note);
  store.setState("field_note_modal", state);
};

const renderImg = (
  id: number,
  image_file_name: string | null
): JSX.Element | null => {
  if (image_file_name == null) {
    return null;
  }

  const thumb = getImageUrl(id, "field_notes", "thumb", image_file_name);
  const original = getImageUrl(id, "field_notes", "original", image_file_name);

  return (
    <a href={original} target="_blank" rel="noopener noreferrer">
      <img alt="" width="50" src={thumb} style={{ marginRight: 5 }} />
    </a>
  );
};

const renderFieldNote = (field_note: FieldNote): JSX.Element => {
  const currentUser = store.getNonNullState("currentUser");

  return (
    <div
      key={`field_note-list-${field_note.id}`}
      className="d-flex mt-3 justify-content-between"
    >
      <Box display="flex">
        {renderImg(field_note.id, field_note.image_file_name)}
        <div>{field_note.getText()}</div>
      </Box>

      <div>
        {(field_note.creator_id === currentUser.id ||
          currentUser.isAdmin()) && (
          <Button
            onClick={(): void => clickEditNote(field_note)}
            size="small"
            variant="outlined"
          >
            edit
          </Button>
        )}
      </div>
    </div>
  );
};

const renderFieldNotes = (field_notes: FieldNote[]): JSX.Element | null => {
  if (field_notes.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6" className="d-flex align-items-center">
          <NoteIcon /> Field Notes ({field_notes.length})
        </Typography>
        <div className="pl-1">{field_notes.map(renderFieldNote)}</div>
      </div>
    );
  } else {
    return null;
  }
};

const clickAddFieldNote = (): void => {
  const state = FieldNote.getModalState(null);

  store.setState("field_note_modal", state);
};

const renderActions = (activities: Array<Activity>): JSX.Element | null => {
  if (activities.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Activity ({activities.length})</Typography>
        <div className="pl-1">
          {activities.map((a) => (
            <div key={a.key}>{a.txt}</div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const JobDetail = (): JSX.Element => {
  const room = store.useNonNullState("room");
  const schoolName = room.building.school.name;

  const activities = sortBy(room.getActivity(), (a) => a.performed_at);

  React.useEffect(() => {
    store.state.toptitle = undefined;
    store.state.subtitle = undefined;
    store.setState("title", schoolName);
  }, [schoolName]);

  const installsWithoutReservation =
    room.installs?.filter((i) => i.reservation_id === null) || [];
  return (
    <div>
      <Typography
        variant="h6"
        className="my-2 d-flex align-items-center justify-content-center"
        style={{ textAlign: "center", fontWeight: 400 }}
      >
        {room.showName()}
        {getLockedIcon(room)}
        {getOccupiedIcon(room)}
        {getFieldNoteIcon(room)}
      </Typography>
      <LockedSection room={room} />
      <Box display="flex" justifyContent="center" className="mt-3">
        <OccupiedButton room={room} />

        <Button
          variant="outlined"
          size="small"
          className="mx-2 next-button na-btn"
          onClick={clickNeedsAttention}
        >
          needs attention?
        </Button>

        <Button
          className="next-button fn-btn"
          size="small"
          variant="outlined"
          onClick={clickAddFieldNote}
        >
          add a field note
        </Button>
      </Box>
      <div className="pl-2 mt-2 mb-3">
        {room.getReservations().map((r) => (
          <ReservationRow room={room} reservation={r} key={`res_row_${r.id}`} />
        ))}
        {installsWithoutReservation.length ? (
          <div>
            <div>no associated reservation: </div>
            <div className="d-flex">
              {installsWithoutReservation.map((i) => (
                <InstallButton
                  className="mx-1"
                  key={`insta_${i.id}`}
                  install={i}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {renderFieldNotes(room.getFieldNotes())}
      <TicketsSection tickets={room.getTickets()} />
      {renderActions(activities)}

      <TicketModal />
      <TicketResolutionModal />
      <FieldNoteModal />
      <InstallActionModal />
    </div>
  );
};
