import Modal from "@material-ui/core/Modal";
import {
  Typography,
  Divider,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { renderShort } from "../../helpers/install_view_helpers";
import { handleData } from "../../util/handle_data";
import { getAllData, getModels, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { isErrorResponse } from "../../util/app_util";
import { AllJSONResponse } from "../../types/data_types";
import { findModelById } from "../../util/data_util";
import InstallActionCheckbox from "./InstallActionCheckbox";
import { unwrap } from "../../util/util";
import { CSSProperties } from "react";

const style: CSSProperties = {
  top: `50%`,
  left: "0",
  right: "0",
  transform: `translateY(-50%)`,
  outline: 0,
  width: "100%",
  border: "none",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
    },
  })
);

const onSubmit = (): void => {
  const state = getNonNullState();

  setPartialState({ working: true });
  const data = { ...state, install: null };
  ApiRequest.perform({
    url: "/removals_api/install_actions.json",
    method: "POST",
    data: { install_id: state.install.id, install_actions: data },
    onSuccess: (resp: unknown): void => {
      const json = resp as AllJSONResponse;
      handleData(unwrap(json.data));
      window.D = getAllData();
      setState(undefined);
      const room = store.getState("room");
      if (room) {
        store.state.room = findModelById(getModels("rooms"), room.id);
      }
      store.forceUpdate("viewShown");
    },
    onError: (resp: unknown): void => {
      if (isErrorResponse(resp)) {
        alert(resp.errors.join(" & "));
      } else {
        alert("an error occurred");
      }

      setPartialState({ working: false });
    },
  });
};

const clickMissing = (): void => {
  const state = getNonNullState();

  setPartialState({ working: true });

  ApiRequest.perform({
    url: `/removals_api/install_actions/${state.install.id}/toggle_missing.json`,
    method: "POST",
    onSuccess: (resp: unknown): void => {
      const json = resp as AllJSONResponse;
      handleData(json.data!);
      window.D = getAllData();
      setState(undefined);
      const room = store.getState("room");

      if (room != null) {
        store.state.room = findModelById(getModels("rooms"), room.id);
      }
      store.forceUpdate("viewShown");
    },
    onError: (resp: unknown): void => {
      if (isErrorResponse(resp)) {
        alert(resp.errors.join(" & "));
      } else {
        alert("an error occurred");
      }

      setPartialState({ working: false });
    },
  });
};

const {
  useStoreState: useModal,
  setPartialState,
  setState,
  getNonNullState,
} = store.getScopedHelpers("install_action_modal");

export default function InstallActionModal(): JSX.Element | null {
  const currentUser = store.getNonNullState("currentUser");
  const classes = useStyles();

  const state = useModal();

  if (state == null) {
    return null;
  }

  return (
    <Modal
      open={true}
      onClose={(): void => {
        setState(undefined);
      }}
    >
      <div style={style} className={classes.paper}>
        <div className="my-2 px-1">
          <Typography>
            Please select the actions you did for {renderShort(state.install)}
          </Typography>
          <>
            <InstallActionCheckbox
              type="uninstalled"
              currentUser={currentUser}
              splitter_ids={state.uninstalled_by_splitter_ids}
              actor_id={state.uninstalled_by_id}
              action={state.install.uninstalled_action}
              onChangeActor={(actor_id: number | null): void =>
                setPartialState({ uninstalled_by_id: actor_id })
              }
              onChangeSplitters={(splitter_ids): void => {
                setPartialState({
                  uninstalled_by_splitter_ids: splitter_ids,
                });
              }}
            />

            <div>
              <InstallActionCheckbox
                type="removed"
                currentUser={currentUser}
                action={state.install.removed_action}
                splitter_ids={state.removed_by_splitter_ids}
                actor_id={state.removed_by_id}
                onChangeSplitters={(splitter_ids): void => {
                  setPartialState({
                    removed_by_splitter_ids: splitter_ids,
                  });
                }}
                onChangeActor={(actor_id): void =>
                  setPartialState({ removed_by_id: actor_id })
                }
              />
            </div>
          </>
        </div>

        <Divider />

        <div className="d-flex justify-content-between py-3 px-1">
          <Button
            variant="outlined"
            className="next-button"
            disabled={state.working}
            onClick={(): void => setState(undefined)}
          >
            Cancel
          </Button>

          <Button
            onClick={clickMissing}
            className="next-button"
            variant="outlined"
            disabled={state.working}
          >
            {state.install.status === "missing" ? "Not missing" : "Missing"}
          </Button>

          <Button
            variant="outlined"
            className="next-button"
            onClick={onSubmit}
            disabled={state.working}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}
