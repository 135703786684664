import strftime from "strftime";
import { getModels } from "../init_app/globals";
import { findModelById } from "../util/data_util";
import { andJoin, myRound } from "../util/util";
import { Install } from "./Install";
import { Activity } from "./Room";
import { User } from "./User";

export type MoneyType = "uninstalled" | "removed";

export type InstallActionType = MoneyType | "set_missing";

export const money_types: InstallActionType[] = ["uninstalled", "removed"];

export type InstallActionJSON = {
  id: number;
  set: boolean;
  type: InstallActionType;
  creator_id: number;
  actor_id: number;
  install_id: number;
  splitter_ids: number[];
  created_at: string;
};

export class InstallAction {
  id: number;
  set: boolean;
  type: InstallActionType;
  creator_id: number;
  creator?: User;
  actor_id: number;
  actor?: User;
  install_id: number;
  splitter_ids: number[];
  splitters?: User[];
  created_at: Date;
  install: Install;
  money_type: MoneyType | null;

  constructor(json: InstallActionJSON, install: Install) {
    this.id = json.id;
    this.set = json.set;
    this.type = json.type;
    this.creator_id = json.creator_id;
    this.actor_id = json.actor_id;
    this.splitter_ids = json.splitter_ids;
    this.install_id = json.install_id;
    this.created_at = new Date(json.created_at + "Z");
    this.install = install;

    this.money_type =
      this.type === "set_missing"
        ? null
        : money_types.includes(this.type)
        ? this.type
        : null;
  }

  individualCount(): number {
    return myRound(1 / (this.splitter_ids.length + 1));
  }

  getCreator(): User {
    if (this.creator == null) {
      this.creator = findModelById(getModels("users"), this.creator_id);
    }

    return this.creator;
  }

  getActor(): User {
    if (this.actor == null) {
      return findModelById(getModels("users"), this.actor_id);
    }

    return this.actor;
  }

  getSplitters(): User[] {
    if (this.splitters == null) {
      const users = getModels("users");
      this.splitters = this.splitter_ids.map((id) => findModelById(users, id));
    }

    return this.splitters;
  }

  getText(): string {
    const creator = this.getCreator();
    const creatorName = creator.username;
    const short = this.install.product.short;
    const dateStr = `on ${strftime("%b %e %Y @ %l:%M %p", this.created_at)}`;

    let splitterTxt = "";

    if (this.type === "set_missing") {
      return `${creatorName} set ${short} missing on ${dateStr}`;
    } else {
      if (this.splitter_ids.length > 0) {
        const splitters = this.getSplitters();
        const usernames = andJoin(splitters.map((s) => s.lowerName()));
        splitterTxt = `+ ${usernames}`;
      }

      if (this.creator_id === this.actor_id) {
        return `${creatorName} ${splitterTxt} ${this.type} ${short} ${dateStr}`;
      } else {
        const actorName = this.getActor().username;
        return `${creatorName} set that ${actorName} ${splitterTxt} ${this.type} ${short} ${dateStr}`;
      }
    }
  }

  touchedByUser(user: User): boolean {
    return this.actor_id === user.id || this.splitter_ids.includes(user.id);
  }

  toActivity(): Activity {
    return {
      id: this.id,
      txt: this.getText(),
      room_id: this.install.room_id,
      creator: this.getCreator(),
      performed_at: this.created_at,
      namey: "Activity",
      key: `install_action_${this.id}_activity`,
    };
  }
}
