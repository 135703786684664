import { Ticket } from "../../models/Ticket";
import Typography from "@material-ui/core/Typography";
import strftime from "strftime";
import Button from "@material-ui/core/Button";
import {
  setTicketModalState,
  setTicketResolutionModalState,
} from "../../helpers/ticket_helpers";
import { store } from "../../init_app/globals";
import { then } from "../../util/util";
import { UnresolveTicketButton } from "./UnresolveTicketButton";
import WarningIcon from "@material-ui/icons/Warning";

const clickEditTicket = (ticket: Ticket): void => {
  setTicketModalState(ticket);
};

const clickResolveTicket = (ticket: Ticket): void => {
  setTicketResolutionModalState(ticket);
};

const renderResolution = (ticket: Ticket): JSX.Element | null => {
  if (ticket.resolved_by) {
    if (!ticket.resolved_at) {
      throw new Error("resolved_at should be present here");
    }

    const resolved_at_str = strftime("%b %e %l:%M %p", ticket.resolved_at);

    const notes = then(ticket.resolution_notes?.trim(), (n) =>
      n == null || n === "" ? null : n
    );

    return (
      <div className="d-flex">
        {notes == null || notes === "" ? null : (
          <div className="mr-2">{notes}</div>
        )}
        <div>
          resolved by {ticket.resolved_by.username} @ {resolved_at_str}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const renderTicket = (ticket: Ticket): JSX.Element => {
  const key = `ticket-list-${ticket.id}`;
  const created_at_str = strftime("%b %e %l:%M %p", ticket.created_at);
  const currentUser = store.getNonNullState("currentUser");
  const txt = `"${ticket.text}"`;

  return (
    <div
      key={key}
      className="d-flex justify-content-between align-items-center my-2 mb-4"
    >
      <div className="mr-1 d-flex flex-column" style={{ fontSize: 12 }}>
        <div className="d-flex">
          <div className="mr-2">{txt}</div>
          <div>
            created by {ticket.creator.username} @ {created_at_str}
          </div>
        </div>
        {renderResolution(ticket)}
      </div>

      <div className="d-flex flex-column">
        {(ticket.creator_id === currentUser.id || currentUser.isAdmin()) && (
          <Button
            onClick={(): void => clickEditTicket(ticket)}
            size="small"
            variant="outlined"
          >
            edit
          </Button>
        )}

        {!ticket.resolved_by && (
          <Button
            variant="outlined"
            size="small"
            className="mt-2"
            onClick={(): void => clickResolveTicket(ticket)}
          >
            Resolve
          </Button>
        )}

        {ticket.resolved_by != null &&
          (currentUser.isAdmin() || ticket.resolved_by === currentUser) && (
            <UnresolveTicketButton ticket={ticket} />
          )}
      </div>
    </div>
  );
};

interface TicketsSectionProps {
  tickets: Ticket[];
}

export const TicketsSection = (
  props: TicketsSectionProps
): JSX.Element | null => {
  const { tickets } = props;

  if (tickets.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6" className="d-flex align-items-center">
          <WarningIcon style={{ color: "#ff5722" }} className="me-1" />
          Needs Attention Tickets ({tickets.length})
        </Typography>
        <div>{tickets.map(renderTicket)}</div>
      </div>
    );
  } else {
    return null;
  }
};
