import { Payscale } from "../models/Payscale";
import { PayscaleModalState } from "../types/state_types";
import clone from "lodash/clone";
import { store } from "../init_app/globals";

export const getPayscaleModalState = (
  payscale: Payscale
): PayscaleModalState => {
  return {
    working: false,
    errors: [],

    id: payscale.id,
    label: payscale.label,
    short_cents: clone(payscale.short_cents),
    group: payscale.group,
    type: payscale.type,
  };
};

export const getBlankPayscaleModalState = (): PayscaleModalState => {
  return {
    working: false,
    errors: [],
    id: 9998,
    label: "nada",
    short_cents: {},
    group: "basic",
    type: "pickup",
  };
};

export const setPayscaleModalState = (payscale: Payscale): void => {
  const state = getPayscaleModalState(payscale);
  store.setState("payscale_modal", state);
};
