import { Store } from "set-state-is-great";
import { RouterState } from "./router";
import { AppState } from "./store";
import StateRouter from "../libs/StateRouter";
import { AllModels } from "../types/app_types";
import { Filter } from "../models/Filter";

// export const account_id: number = 2 as number;
export const account_id: number =
  window.location.hostname === "mfr.dormsdirect.com" ? 2 : 1;

export const filterKey = account_id === 2 ? "mfr_filter" : "filter";

export type App = {
  store: Store<AppState>;
  filter?: Filter | undefined;
};

declare global {
  interface Window {
    App: App;
    yar: boolean;
    D: Partial<AllModels>;
    resetList: () => void;
  }
}

export const data: Partial<AllModels> = {};
export let store: Store<AppState>;
export let router: StateRouter<RouterState>;

export const getStore = (): Store<AppState> => {
  return store;
};

export const setGlobals = (
  theStore: Store<AppState>,
  theRouter: StateRouter<RouterState>,
  filter: Filter | null
): void => {
  store = theStore;
  router = theRouter;

  window.App = {
    store,
    filter: filter || undefined,
  };
};

export function getAllData(): Partial<AllModels> {
  return data;
}

export function setModels<Key extends keyof AllModels>(
  key: Key,
  models: AllModels[Key]
): AllModels[Key] {
  data[key] = models;

  return models;
}

export function getModels<Key extends keyof AllModels>(
  key: Key
): NonNullable<Partial<AllModels>[Key]> {
  const models = data[key];

  if (models) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return models!;
  } else {
    throw new Error(`model ${key} missing`);
  }
}
