import reject from "lodash/reject";
import { Room } from "../../models/Room";
import CenterAll from "../util/CenterAll";
import { LockedSelect } from "./LockedSelect";
import { RoomLocking, RoomLockingJSON } from "../../models/RoomLocking";
import { ApiRequest } from "../../libs/ApiRequest";
import { getModels, setModels, store } from "../../init_app/globals";
import { isErrorResponse } from "../../util/app_util";
import { findModelById } from "../../util/data_util";

interface LockedSectionProps {
  room: Room;
}

type RoomLockingResp = {
  room_locking: RoomLockingJSON;
};

const onChangedLocked = (nextLocked: boolean | null, room: Room): void => {
  ApiRequest.perform({
    url: `/worker_api/room_lockings.json`,
    method: "POST",
    onSuccess: (resp: unknown) => {
      const { room_locking } = resp as RoomLockingResp;
      const user = findModelById(getModels("users"), room_locking.set_by_id);
      const roomLocking = new RoomLocking(
        room_locking as RoomLockingJSON,
        user,
        room
      );
      const room_lockings = reject(
        getModels("room_lockings"),
        (rl) => rl.room_id === room.id
      );
      room_lockings.unshift(roomLocking);
      setModels("room_lockings", room_lockings);
      room.locked = roomLocking.locked;
      store.forceUpdate("room");
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        alert(json.errors.join(". "));
      } else {
        alert("an error occurred while changing locked status");
      }
    },
    data: {
      room_id: room.id,
      locked: nextLocked,
    },
  });
};

const LockedSection = (props: LockedSectionProps): JSX.Element => {
  const { room } = props;
  return (
    <CenterAll className="mt-2">
      <LockedSelect
        locked={room.locked}
        onChange={(nextLocked): void => onChangedLocked(nextLocked, room)}
      />
    </CenterAll>
  );
};

export default LockedSection;
