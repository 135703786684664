import React from "react";
import { Ticket } from "../../models/Ticket";
import Button from "@material-ui/core/Button";
import { TicketRespJSON } from "../../types/json_types";
import { ApiRequest } from "../../libs/ApiRequest";
import { getModels, store } from "../../init_app/globals";
import { findModelById } from "../../util/data_util";

type fn = React.Dispatch<React.SetStateAction<boolean>>;

const clickUnresolveTicket = (
  ticket: Ticket,
  working: boolean,
  setWorking: fn
): void => {
  if (working) {
    return;
  }

  setWorking(true);

  ApiRequest.perform({
    url: `/worker_api/tickets/${ticket.id}/mark_unresolved.json`,
    method: "POST",
    onSuccess: (resp: unknown) => {
      const json = resp as TicketRespJSON;

      const heldTicket = new Ticket(
        json.ticket,
        ticket.creator,
        ticket.room,
        ticket.resolved_by
      );
      Object.assign(ticket, heldTicket);

      const room = findModelById(getModels("rooms"), ticket.room_id);
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      store.state.ticket_resolution_modal = undefined;

      store.forceUpdate("room");
    },
    onError: () => {
      setWorking(false);
      alert("an error occurred");
    },
  });
};

interface UnresolveTicketButtonProps {
  ticket: Ticket;
}

export const UnresolveTicketButton = (
  props: UnresolveTicketButtonProps
): JSX.Element => {
  const { ticket } = props;

  const [working, setWorking] = React.useState(false);

  return (
    <Button
      variant="outlined"
      className="mt-2"
      disabled={working}
      size="small"
      onClick={(): void => clickUnresolveTicket(ticket, working, setWorking)}
    >
      Unresolve
    </Button>
  );
};
