import { Room } from "./Room";
import { User } from "./User";

export type RoomLockingJSON = {
  id: number;
  locked: boolean | null;
  room_id: number;
  room: Room;
  set_by_id: number;
  set_by: User;
  created_at: string;
};

export class RoomLocking {
  id: number;
  locked: boolean | null;
  room_id: number;
  room: Room;
  set_by_id: number;
  set_by: User;
  created_at: Date;

  constructor(json: RoomLockingJSON, set_by: User, room: Room) {
    this.id = json.id;
    this.locked = json.locked;
    this.room_id = json.room_id;
    this.room = room;
    this.set_by_id = json.set_by_id;
    this.set_by = set_by;
    this.created_at = new Date(json.created_at + "Z");
  }
}
