import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { parseBoolStr } from "../../util/app_util";

type LockedSelectProps = {
  locked: boolean | null;
  onChange: (locked: boolean | null) => void;
};

export const LockedSelect = (props: LockedSelectProps): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
    props.onChange(parseBoolStr(e.target.value as string));
  };

  const val = `${props.locked}`;

  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel htmlFor="locked-select">Locked Status</InputLabel>
        <Select
          label="Locked Status"
          id="locked-select"
          native
          value={val}
          onChange={handleChange}
        >
          <option value={"null"}>Unknown</option>
          <option value={"true"}>Locked</option>
          <option value={"false"}>Unlocked</option>
        </Select>
      </FormControl>
    </div>
  );
};
