import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";

type Props<T extends string> = {
  options: T[];
  value: T[];
  onChange: (value: T[]) => void;
  label: string;
};

function MultiGenericStringSelect<T extends string>(
  props: Props<T>
): JSX.Element {
  function handleOnChange(event: React.ChangeEvent<{ value: unknown }>): void {
    const { options } = event.target as HTMLSelectElement;
    const strings: T[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i]!.selected) {
        strings.push(options[i]!.value as T);
      }
    }

    props.onChange(strings);
  }

  const id = "gen-str-mult-ntive";
  return (
    <div>
      <FormControl>
        <InputLabel shrink htmlFor={id}>
          {props.label}
        </InputLabel>
        <Select
          id={id}
          multiple
          native
          value={props.value}
          onChange={handleOnChange}
        >
          {props.options.map((opt) => (
            <option key={`gn-${opt}`} value={opt}>
              {opt}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiGenericStringSelect;
