import { View } from "../../types/app_types";
import { LoginForm } from "../auth/LoginForm";
import { SignupForm } from "../auth/SignupForm";
import BadRoute from "../BadRoute";
import BonusBucks from "../bonus_bucks/BonusBucks";
import { BuildingDetail } from "../BuildingDetail";
import { Home } from "../Home";
import { JobDetail } from "../job_detail/JobDetail";
import { JobsView } from "../job_list/JobsView";
import PayscaleDetail from "../payscales/PayscaleDetail";
import PayscaleList from "../payscales/PayscaleList";
import FullProfileForm from "../profile/FullProfileForm";
import Stats from "../stats/Stats";
import TicketsList from "../tickets/TicketsList";
import TruckLoadDetail from "../truck_loads/TruckLoadDetail";
import TruckLoadList from "../truck_loads/TruckLoadList";
import UserWork from "../user_work/UserWork";
import { Workers } from "../user_work/Workers";

export const getView = (view: View): JSX.Element => {
  switch (view) {
    case "Home":
      return <Home />;
    case "Login":
      return <LoginForm />;
    case "Signup":
      return <SignupForm />;
    case "JobsView":
      return (
        <div>
          <JobsView viewShown={view} />
        </div>
      );
    case "JobDetail":
      return (
        <div>
          <JobsView viewShown={view} />
          <JobDetail />
        </div>
      );
    case "WorkerJobDetail":
      return <JobDetail />;
    case "PayscaleList":
      return <PayscaleList />;
    case "PayscaleDetail":
      return <PayscaleDetail />;
    case "Profile":
      return <FullProfileForm />;
    case "Stats":
      return <Stats />;
    case "BuildingDetail":
      return (
        <div>
          <JobsView viewShown={view} />
          <BuildingDetail />
        </div>
      );
    case "UserWork":
      return <UserWork />;
    case "TicketsList":
      return <TicketsList />;
    case "TruckLoadDetail":
      return <TruckLoadDetail />;
    case "TruckLoads":
      return <TruckLoadList />;
    case "Workers":
      return <Workers />;
    case "BonusBucks":
      return <BonusBucks />;
    case "BadRoute":
      return <BadRoute />;
  }

  assertUnreachable(view);
};

function assertUnreachable(x: never): never {
  throw new Error(`Unreachable case: ${JSON.stringify(x)}`);
}
