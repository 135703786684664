import compact from "lodash/compact";
import { getModels } from "../init_app/globals";
import { findModelById } from "../util/data_util";
import { InstallAction } from "./InstallAction";
import { Product } from "./Product";
import { Reservation } from "./Reservation";
import { Room } from "./Room";
import { School } from "./School";
import { Upgrade } from "./Upgrade";

export type InstallStatus =
  | "pending_removal"
  | "uninstalled"
  | "missing"
  | "removed";

export const install_statuses: InstallStatus[] = [
  "pending_removal",
  "uninstalled",
  "missing",
  "removed",
];

export const available_statuses: InstallStatus[] = [
  "pending_removal",
  "uninstalled",
];

export type InstallJSON = {
  id: number;
  product_id: number;
  room_id: number;
  quantity: number;
  student_id: number;
  invoice_id: number;
  reservation_id: number;
  line_item_id: number;
  upgrade_ids: number[];
  status: InstallStatus;
  created_at: string;
};

export class Install {
  id: number;
  product_id: number;
  product: Product;
  school: School;
  quantity: number;
  room_id: number;
  reservation_id: number;
  reservation: Reservation | undefined;
  invoice_id: number | null;
  room: Room;
  status: InstallStatus;
  upgrade_ids: number[];

  uninstalled_action?: InstallAction | null;
  removed_action?: InstallAction | null;
  set_missing_action?: InstallAction | null;

  upgrades?: Upgrade[];

  constructor(
    json: InstallJSON,
    product: Product,
    reservation: Reservation | undefined,
    room: Room,
    school: School
  ) {
    this.id = json.id;
    this.school = school;
    this.product_id = json.product_id;
    this.product = product;
    this.quantity = json.quantity;
    this.room_id = json.room_id;
    this.room = room;
    this.reservation_id = json.reservation_id;
    this.invoice_id = json.invoice_id;
    this.upgrade_ids = json.upgrade_ids;
    this.status = json.status;
    this.reservation = reservation;
  }

  setActions(install_actions: InstallAction[]): void {
    this.set_missing_action =
      install_actions.find((ia) => ia.type === "set_missing") || null;
    this.uninstalled_action =
      install_actions.find((ia) => ia.type === "uninstalled") || null;
    this.removed_action =
      install_actions.find((ia) => ia.type === "removed") || null;
  }

  getShort(): string {
    const prod = findModelById(getModels("products"), this.product_id);
    const upgrades = this.getUpgrades();

    const up_shorts = upgrades.map((u) => u.short.split("_")[0]).sort();

    let str = [prod.short, ...up_shorts].join("_");

    if (this.quantity > 1) {
      str = `${str} x${this.quantity}`;
    }

    return str;
  }

  getInstallActions(): InstallAction[] {
    return compact([
      this.uninstalled_action,
      this.removed_action,
      this.set_missing_action,
    ]);
  }

  getUpgrades(): Upgrade[] {
    if (this.upgrades == null) {
      this.upgrades = this.upgrade_ids.map((uid) =>
        findModelById(getModels("upgrades"), uid)
      );
    }

    return this.upgrades;
  }

  isAvailable(): boolean {
    return available_statuses.includes(this.status);
  }
}
