import { getModels } from "../init_app/globals";
import { filterModelsByAttr } from "../util/data_util";
import { Install } from "./Install";

export type SchoolJSON = {
  id: number;
  name: string;
  short: string;
};

export class School {
  id: number;
  name: string;
  short: string;

  constructor(json: SchoolJSON) {
    this.id = json.id;
    this.name = json.name;
    this.short = json.short;
  }

  getInstalls(): Install[] {
    return filterModelsByAttr(getModels("installs"), "school", this);
  }

  isMethodist(): boolean {
    return this.short === "methodist";
  }

  isRichmond(): boolean {
    return this.short === "richmond";
  }

  isWm(): boolean {
    return this.short === "wm";
  }
}
