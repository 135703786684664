/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import clsx from "clsx";
import sortBy from "lodash/sortBy";
import React from "react";
import {
  ListChildComponentProps,
  ListOnScrollProps,
  VariableSizeList,
} from "react-window";
import {
  getFilteredRooms,
  getSearchedRooms,
} from "../../helpers/filter_helpers";
import { getStatsForBuilding, InstallStats } from "../../helpers/stats_helpers";
import { account_id, getModels } from "../../init_app/globals";
import { store } from "../../init_app/store";
import { Building } from "../../models/Building";
import { View } from "../../types/app_types";
import { intersperseBuildings } from "../../util/app_util";
import { then } from "../../util/util";
import SchoolSelectMeh from "../selects/SchoolSelectMeh";
import CenterAll from "../util/CenterAll";
import { BuildingRow } from "./BuildingRow";
import { RoomRow } from "./RoomRow";

let listEle: Element | undefined | null = null;

let list: VariableSizeList | null = null;

export type BuildingStats = {
  [building_id: number]: InstallStats;
};

window.resetList = (): void => {
  if (list == null) return;

  list.resetAfterIndex(0);
};

export const resetListAtIndex = (index: number): void => {
  if (list == null) return;

  list.resetAfterIndex(index);
};

const onScroll = ({ scrollOffset }: ListOnScrollProps): void => {
  if (!listEle) {
    return;
  }
  const ele = scrollOffset < 50 ? listEle.children[0] : listEle.children[2];

  if (!ele) {
    return;
  }

  const title = ele.getAttribute("data-title");
  if (title == null) {
    return;
  }

  const search = store.state.search;
  const hasSearch = search != null && search.trim() !== "";

  const school = window.App.filter?.getSchool();

  if (!hasSearch && school != null) {
    store.state.toptitle = school.name;
  }

  if (roomsLen == null) {
    store.state.subtitle = "";
  } else {
    store.state.subtitle = hasSearch
      ? `Searching for "${search}" (${roomsLen} results)`
      : `${roomsLen} rooms in list`;
  }

  setTimeout(() => {
    store.setStateIfDifferent("title", title);
  }, 1);
};

let roomsLen: number | null = null;

export const JobsView = ({ viewShown }: { viewShown: View }): JSX.Element => {
  const search = store.state.search;

  React.useEffect(() => {
    return (): void => {
      roomsLen = null;
      list = null;
    };
  }, []);

  const hasSearch = search != null && search.trim() !== "";

  const school = window.App.filter?.getSchool();

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (viewShown !== "JobsView") {
        return;
      }

      listEle = document.querySelector(".List")?.firstElementChild;

      if (listEle != null) {
        then(listEle.children[0]?.getAttribute("data-title"), (t) => {
          if (t != null) {
            if (roomsLen === null) {
              return;
            }

            if (!hasSearch) {
              store.state.toptitle = school == null ? "" : school.name;
            }

            store.state.subtitle = hasSearch
              ? `Searching for "${search}" (${roomsLen} results)`
              : `${roomsLen} rooms in list`;

            setTimeout(() => {
              store.setState("title", t);
            }, 1);
          } else if (search != null && search.trim() !== "") {
            setTimeout(() => {
              store.setState(
                "subtitle",
                `Searching for ${search} (${
                  roomsLen == null ? 0 : roomsLen
                } results)`
              );
            }, 1);
          } else {
            setTimeout(() => {
              store.state.subtitle = "";
              store.state.toptitle = "";
              store.setState("title", school != null ? school.name : "");
            }, 1);
          }
        });
      }

      return (): void => {
        listEle = undefined;
      };
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school, roomsLen, hasSearch, search, viewShown]);

  const classes = clsx("List", {
    hidden: viewShown === "JobDetail" || viewShown === "BuildingDetail",
  });

  const filter = window.App.filter;

  if (filter == null) {
    setTimeout(() => {
      const state = store.state;
      state.title = "Jobs";
      store.forceUpdate("title");
    }, 1);

    return (
      <CenterAll className="mt-4">
        <SchoolSelectMeh />
      </CenterAll>
    );
  } else {
    const school = filter.getSchool();
    let rooms =
      search == null || search.trim() === ""
        ? getFilteredRooms(filter)
        : getSearchedRooms(search);
    roomsLen = rooms.length;

    const hasSearch = search != null && search.trim() !== "";

    const buildings = hasSearch
      ? getModels("buildings")
      : getModels("buildings").filter(
          (b: Building) => b.school_id === school.id
        );

    if (account_id === 2) {
      rooms = sortBy(rooms, ["number", "wing"]);
    }

    const rooms_and_buildings = intersperseBuildings(rooms, buildings);

    const building_stats: BuildingStats = {};
    for (let index = 0; index < rooms_and_buildings.length; index++) {
      const place = rooms_and_buildings[index]!;

      if (place.isBuilding()) {
        const building = place;
        building_stats[building.id] = getStatsForBuilding(building, rooms);
      }
    }

    return (
      <VariableSizeList
        className={classes}
        ref={(l): void => {
          list = l;
        }}
        onScroll={onScroll}
        height={window.innerHeight - 65}
        itemCount={rooms_and_buildings.length}
        itemSize={(index): number => 50}
        width="100%"
      >
        {({ index, style }: ListChildComponentProps): JSX.Element | null => {
          const item = rooms_and_buildings[index]!;
          if (item.isBuilding()) {
            return (
              <BuildingRow
                style={style}
                building={item}
                stats={building_stats[item.id]!}
              />
            );
          } else {
            return <RoomRow style={style} room={item} />;
          }
        }}
      </VariableSizeList>
    );
  }
};
