import { Typography, Box, TextField, Button } from "@material-ui/core";
import { LoginFormState } from "../../helpers/auth_helpers";
import { router, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { ReactFormEvent } from "../../types/types";
import { isPresent, unwrapOr } from "../../util/util";
import CenterAll from "../util/CenterAll";
import CloseableAlert from "../util/CloseableAlert";
import GoogleOauthButton from "./GoogleOauthButton";

const { setPartialState } = store.getScopedHelpers("login_form");

const onSubmit = (e: ReactFormEvent): void => {
  e.preventDefault();

  if (store.getState("working")) {
    return;
  }

  store.setState("working", true);

  const request = new ApiRequest({
    url: "/users/sign_in.json",
    method: "POST",
    onSuccess: (json: unknown): void => {
      window.location.pathname = "/";
    },
    onError: (): void => {
      store.setPartialState("login_form", { error: true });

      store.setState("working", false);
    },
    data: { user: store.getNonNullState("login_form") },
  });
  request.perform();
};

const onChange = <Key extends keyof LoginFormState>(
  attr: Key,
  value: LoginFormState[Key]
): void => {
  setPartialState({ [attr]: value });
};

export const LoginForm = (): JSX.Element => {
  const { error, email, password, emailError } = store.useNonNullState(
    "login_form"
  );

  return (
    <div className="p-2">
      {error ? (
        <CloseableAlert
          open={error}
          closeAlert={(): void => setPartialState({ error: false })}
          variant="outlined"
          severity="error"
        >
          Invalid email/password combo
        </CloseableAlert>
      ) : null}

      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            value={email}
            onChange={(e): void => onChange("email", e.target.value)}
            className="mt-2"
            id="outlined-basic"
            label="Email"
            variant="outlined"
            error={isPresent(emailError)}
            helperText={unwrapOr(emailError, null)}
          />

          <TextField
            className="mt-2"
            value={password}
            onChange={(e): void => onChange("password", e.target.value)}
            label="Password"
            type="password"
            variant="outlined"
          />

          <Button
            className="mt-2"
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disableElevation
          >
            login
          </Button>
        </Box>
      </form>

      <Box display="flex" flexDirection="column" marginTop={1}>
        <Box my="2">
          <Typography variant="h6" style={{ textAlign: "center" }}>
            OR
          </Typography>
        </Box>

        <CenterAll>
          <GoogleOauthButton />
        </CenterAll>
      </Box>

      <Box display="flex" flexDirection="column" marginTop={1}>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          OR
        </Typography>

        <Button
          onClick={(): void => {
            router.go("/signup");
          }}
          variant="outlined"
          color="secondary"
          size="large"
          className="mt-2"
        >
          Go to sign up page
        </Button>
      </Box>
    </div>
  );
};
