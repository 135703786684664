import { Payscale } from "../../models/Payscale";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { centsToDollarString } from "../../util/util";
import { short_shorts } from "../../constants/shorts";
import { store } from "../../init_app/globals";
import { setPayscaleModalState } from "../../helpers/payscale_helpers";
import PayscaleModal from "./PayscaleModal";

const clickEdit = (payscale: Payscale): void => {
  setPayscaleModalState(payscale);
};

const renderShort = (short: string, cents: number): JSX.Element | null => {
  return (
    <div key={`td_ps_${short}`} className="d-flex mt-3 align-items-center">
      <div>{short}:</div>
      <div className="ms-2">{centsToDollarString(cents)}</div>
    </div>
  );
};
const PayscaleDetail = (): JSX.Element => {
  const payscale = store.useNonNullState("payscale");

  const currentUser = store.getNonNullState("currentUser");

  return (
    <div className="p-1 mt-2">
      {currentUser.admin && (
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button
            onClick={(): void => clickEdit(payscale)}
            size="small"
            variant="outlined"
          >
            Edit
          </Button>
        </div>
      )}

      <div>
        <span className="fw-500 me-2">Name:</span>
        {payscale.label}
      </div>

      <div>
        <span className="fw-500 me-2">Type:</span>
        {payscale.type}
      </div>

      <div>
        <span className="fw-500 me-2">Group:</span>
        {payscale.group}
      </div>

      <Box flexGrow="1" style={{ overflow: "scroll" }} className="pl-1">
        <div>
          {short_shorts.map((ss) => renderShort(ss, payscale.getCents(ss)))}
        </div>
      </Box>

      <PayscaleModal />
    </div>
  );
};

export default PayscaleDetail;
