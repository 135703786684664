import { getAllStats } from "../../helpers/stats_helpers";
import StatsTable from "./StatsTable";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import FilteredStats from "./FilteredStats";
import { store } from "../../init_app/globals";
import { BuildingStats } from "./BuildingStats";

const allStatsView = (): JSX.Element => {
  setTimeout(() => {
    store.setStateIfDifferent("rightIcon", "jobs");
  });

  const stats = getAllStats();

  setTimeout(() => {
    store.setStateIfDifferent("subtitle", undefined);
  });

  return (
    <div>
      {stats.stats.map((s) => StatsTable(s.prods, s.school.name, true))}
    </div>
  );
};

const getView = (tab: number): JSX.Element => {
  switch (tab) {
    case 0:
      return allStatsView();
    case 1:
      return <FilteredStats />;
    case 2:
      return <BuildingStats />;
  }

  throw new Error("shouldnt get here");
};

const Stats = (): JSX.Element => {
  const tab = store.useNonNullState("tab");

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={(_, tab): void => {
            store.setState("tab", tab);
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="All stats" />
          <Tab label="Filtered stats" />
          <Tab label="Building" />
        </Tabs>
      </AppBar>
      {getView(tab)}
    </div>
  );
};

export default Stats;
