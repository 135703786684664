import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { BonusBuckModalState } from "../../types/state_types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BonusBuckDestroyButton from "./BonusBuckDestroyButton";
import strftime from "strftime";
import CloseableErrorAlert from "../util/CloseableErrorAlert";
import { store } from "../../init_app/globals";
import MultiUserIdSelect from "../job_detail/MultiIdUserSelect";
import BonusBuckSaveButton from "./BonusBuckSaveButton";
import NativeAppDatePicker from "../selects/NativeAppDatePicker";
import Box from "@material-ui/core/Box";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { BonusBuckModalPhotoRow } from "./BonusBuckPhotoRow";

const { setState, useStoreState, setPartialState } =
  store.getScopedHelpers("bonus_buck_modal");

function getModalStyle(): React.CSSProperties {
  return {
    zIndex: 99999,
    top: "0",
    left: "0",
    outline: "0",
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const handleClose = (): void => {
  setState(undefined);
};

const onChange = <Key extends keyof BonusBuckModalState>(
  attr: Key,
  value: BonusBuckModalState[Key]
): void => {
  setPartialState({ [attr]: value });
};

const renderPhotoRow = (
  image_file_name: string | null,
  id: number | null,
  working: boolean
): JSX.Element => {
  if (image_file_name != null && id != null) {
    return <BonusBuckModalPhotoRow {...{ image_file_name, id, working }} />;
  } else {
    return (
      <div style={{ position: "relative" }} className="mt-3">
        <label
          htmlFor="bbimage"
          style={{ position: "absolute", left: 3, top: -3 }}
          className="file-cover-btn MuiButton-root MuiButton-outlined MuiButtonBase-root MuiButton-outlinedSizeSmall next-button"
        >
          Attach a photo
        </label>

        <input
          type="file"
          id="bbimage"
          name="image"
          accept="image/png, image/jpeg"
          style={{ marginLeft: 47, outline: "none" }}
        />
      </div>
    );
  }
};

const closeErrorAlert = (): void => {
  setPartialState({ errors: [] });
};

const BonusBuckModal = (): JSX.Element | null => {
  const classes = useStyles();
  const modalStyle = getModalStyle();

  const state = useStoreState();

  if (!state) {
    return null;
  }

  const { id, image_file_name, working } = state;

  const currentUser = store.getNonNullState("currentUser");

  return (
    <div>
      <Modal open={true} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" className="center-all">
            {state.id == null ? (
              "Add Bonus Bucks"
            ) : (
              <span>
                Update
                {state.confirmed ? (
                  " "
                ) : (
                  <span className="italic mx-1 ft12">(pending)</span>
                )}
                Bonus Buck
              </span>
            )}
          </Typography>

          <Divider className="mb-2" />

          <Box flexGrow="1" style={{ overflow: "scroll" }}>
            <CloseableErrorAlert
              errors={state.errors}
              closeAlert={closeErrorAlert}
              className="mt-2 mb-3"
            />

            <MultiUserIdSelect
              user_ids={state.worker_ids}
              label="Split BBs with:"
              onChange={(user_ids: number[]): void =>
                onChange("worker_ids", user_ids)
              }
            />

            <div className="mt-3">
              <TextField
                label="Bonus Bucks"
                type="number"
                name="dollars"
                value={state.dollars || ""}
                onChange={(e): void =>
                  onChange("dollars", parseInt(e.target.value))
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="mt-4">
              <div>
                performed on: {strftime("%b %e %Y", state.performed_at)}
              </div>

              <NativeAppDatePicker
                label="change date"
                date={state.performed_at}
                onChangeDate={(d: Date | null): void =>
                  setPartialState({ performed_at: d || new Date() })
                }
              />
            </div>

            <div className="my-3">
              <TextField
                style={{ width: "100%" }}
                className="mt-2"
                label="Reason"
                multiline
                rows="3"
                value={state.reason == null ? "" : state.reason}
                onChange={(e): void => onChange("reason", e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {renderPhotoRow(image_file_name, id, working)}

            {currentUser.admin ? (
              <FormControlLabel
                className="mt-3"
                control={
                  <Checkbox
                    checked={state.confirmed}
                    onChange={(e): void => {
                      setPartialState({
                        confirmed: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label="Confirmed"
              />
            ) : null}
          </Box>

          <Divider className="mb-2" />

          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={handleClose}
              variant="outlined"
              className="next-button"
              size="large"
              disabled={state.working}
            >
              Cancel
            </Button>

            {state.id == null ? null : (
              <BonusBuckDestroyButton id={state.id} working={state.working} />
            )}

            <BonusBuckSaveButton id={state.id} working={state.working} />
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(BonusBuckModal);
