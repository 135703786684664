import { getImageUrl } from "../helpers/paperclip_helpers";
import { unwrapOr } from "../util/util";
import strftime from "strftime";
import { User } from "./User";
import { FieldNoteModalState } from "../types/state_types";

export type FieldNoteJSON = {
  id: number;
  note: string;
  creator_id: number;
  creator: User;
  room_id: number;
  image_file_name: string | null;
  created_at: string;
};

export class FieldNote {
  id: number;
  note: string;
  creator_id: number;
  creator: User;
  room_id: number;
  image_file_name: string | null;
  created_at: Date;

  constructor(json: FieldNoteJSON, creator: User) {
    this.id = json.id;
    this.note = json.note;
    this.creator_id = json.creator_id;
    this.creator = creator;
    this.room_id = json.room_id;
    this.image_file_name = json.image_file_name;
    this.created_at = new Date(json.created_at + "Z");
  }

  hasImage(): boolean {
    return this.image_file_name != null;
  }

  getThumb(): string | null {
    if (this.image_file_name == null) {
      return null;
    } else {
      return getImageUrl(this.id, "field_notes", "thumb", this.image_file_name);
    }
  }

  getText(): string {
    const creatorName = this.creator.username;
    const dateStr = `on ${strftime("%b %e %Y @ %l:%M %p", this.created_at)}`;

    return `"${this.note}" by ${creatorName} ${dateStr}`;
  }

  static getModalState(field_note: FieldNote | null): FieldNoteModalState {
    const id = unwrapOr(field_note?.id, null);
    const note = unwrapOr(field_note?.note, "");
    const image_file_name = unwrapOr(field_note?.image_file_name, null);
    const working = false;

    return { id, note, working, image_file_name };
  }
}
