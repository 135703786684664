import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import { getModels, store } from "../../init_app/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { isErrorResponse } from "../../util/app_util";
import { findModelById } from "../../util/data_util";
import { spliceFromArray } from "../../util/util";

const { setPartialState } = store.getScopedHelpers("bonus_buck_modal");

const destroyBonusBuck = (bonus_buck_id: number, working: boolean): void => {
  if (working) {
    return;
  }

  if (window.confirm("are you sure?")) {
    ApiRequest.perform({
      url: `/worker_api/bonus_bucks/${bonus_buck_id}.json`,
      method: "DELETE",
      onSuccess: (): void => {
        const allBonusBucks = getModels("bonus_bucks");
        const bonus_buck = findModelById(allBonusBucks, bonus_buck_id);
        spliceFromArray(allBonusBucks, bonus_buck);

        store.setState("bonus_buck_modal", undefined);
        setTimeout(() => {
          store.forceUpdate("viewShown");
        });
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          setPartialState({ errors: json.errors });
        } else {
          alert("some funky error occurred");
        }
      },
    });
  }
};

interface BonusBuckDestroyButtonProps {
  working: boolean;
  id: number;
}

const BonusBuckDestroyButton = (
  props: BonusBuckDestroyButtonProps
): JSX.Element => {
  return (
    <Button
      style={{ color: red[900], borderColor: red[900] }}
      onClick={(): void => destroyBonusBuck(props.id, props.working)}
      variant="outlined"
      size="large"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default BonusBuckDestroyButton;
