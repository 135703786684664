import sortBy from "lodash/sortBy";
import { Product } from "../models/Product";
import { Upgrade } from "../models/Upgrade";
import { School } from "../models/School";
import { Building } from "../models/Building";
import { User } from "../models/User";
import { Room } from "../models/Room";
import { Install } from "../models/Install";
import { shorts } from "../constants/shorts";
import { InstallAction } from "../models/InstallAction";
import { Warehouse } from "../models/Warehouse";
import { TruckLoad } from "../models/TruckLoad";
import { BonusBuck } from "../models/BonusBuck";
import { Payscale } from "../models/Payscale";
import { FieldNote } from "../models/FieldNote";
import { RoomLocking } from "../models/RoomLocking";
import { Ticket } from "../models/Ticket";
import { Reservation } from "../models/Reservation";
import { Paycheck } from "../models/Paycheck";
import { setModels } from "../init_app/globals";
import { AllModelsJSONResponse } from "../types/data_types";
import { groupById, groupByIdAttr } from "./data_util";
import { unwrap } from "./util";
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

const groupInstalls = (installs: Install[]): { [key: string]: Install[] } => {
  const obj: { [key: string]: Install[] } = {};

  for (let index = 0; index < installs.length; index++) {
    const i = installs[index]!;

    const rkey = `room_${i.room_id}`;
    if (obj[rkey] == null) {
      obj[rkey] = [i];
    } else {
      obj[rkey]!.push(i);
    }
  }

  return obj;
};

export const handleData = function (allJson: AllModelsJSONResponse): void {
  const users = setModels(
    "users",
    allJson.users.map((u) => new User(u))
  );

  const usersById = groupById(users);

  const schools = setModels(
    "schools",
    allJson.schools.map((s) => new School(s))
  );

  const schoolsById = groupById(schools);

  const warehouses = setModels(
    "warehouses",
    (allJson.warehouses || []).map((w) => new Warehouse(w))
  );

  const warehousesById = groupById(warehouses);

  const products = sortBy(
    allJson.products.map((p) => new Product(p)),
    (prod: Product) => {
      shorts.indexOf(prod.short);
    }
  );
  setModels("products", products);

  const groupedProducts = groupById(products);

  setModels(
    "upgrades",
    (allJson.upgrades || []).map(
      (u) => new Upgrade(u, groupedProducts[u.product_id]!)
    )
  );

  const buildings = setModels(
    "buildings",
    allJson.buildings.map((b) => new Building(b, schoolsById[b.school_id]!))
  );

  const buildingsById = groupById(buildings);

  const rooms = setModels(
    "rooms",
    allJson.rooms.map((r) => new Room(r, buildingsById[r.building_id]!))
  );

  const roomsById = groupById(rooms);

  const reservations = setModels(
    "reservations",
    allJson.reservations.map((r) => {
      const room = roomsById[r.room_id];
      if (room == null) {
        throw new Error("room should be here");
      }
      return new Reservation(r, room!);
    })
  );

  const reservationsById = groupById(reservations);

  const installs = setModels(
    "installs",
    allJson.installs.map((i): Install => {
      const room = roomsById[i.room_id];
      if (room == null) {
        throw new Error("room should be here");
      }

      const reservation = reservationsById[i.reservation_id];

      const school = schoolsById[room.building.school_id];
      if (!school) {
        throw new Error("school should be here");
      }

      return new Install(
        i,
        groupedProducts[i.product_id]!,
        reservation,
        room,
        school
      );
    })
  );

  const installsById = groupById(installs);

  const install_actions = setModels(
    "install_actions",
    (allJson.install_actions || []).map(
      (ia) => new InstallAction(ia, installsById[ia.install_id]!)
    )
  );

  const groupedActions = groupByIdAttr(install_actions, "install_id");

  const groupedInstalls = groupInstalls(installs);

  for (let index = 0; index < rooms.length; index++) {
    const room = rooms[index]!;
    room.installs = groupedInstalls[`room_${room.id}`] || [];

    for (let index = 0; index < installs.length; index++) {
      const install = installs[index]!;
      const actions = groupedActions[install.id];
      if (actions != null) {
        install.setActions(actions);
      }
    }
  }

  setModels(
    "truck_loads",
    (allJson.truck_loads || []).map(
      (tl) =>
        new TruckLoad(
          tl,
          schoolsById[tl.school_id]!,
          usersById[tl.creator_id]!,
          tl.driver_id == null ? null : usersById[tl.driver_id]!,
          tl.warehouse_id == null ? null : warehousesById[tl.warehouse_id]!
        )
    )
  );

  setModels(
    "bonus_bucks",
    (allJson.bonus_bucks || []).map((bb) => new BonusBuck(bb))
  );

  setModels(
    "room_lockings",
    (allJson.room_lockings || []).map(
      (rl) =>
        new RoomLocking(
          rl,
          unwrap(usersById[rl.set_by_id]),
          unwrap(roomsById[rl.room_id])
        )
    )
  );

  setModels(
    "payscales",
    (allJson.payscales || []).map((ps) => new Payscale(ps))
  );

  setModels(
    "field_notes",
    (allJson.field_notes || []).map(
      (n) => new FieldNote(n, usersById[n.creator_id]!)
    )
  );

  setModels(
    "tickets",
    (allJson.tickets || []).map((t) => {
      return new Ticket(
        t,
        unwrap(usersById[t.creator_id]),
        unwrap(
          roomsById[t.room_id],
          `${t.room_id} room for ticket ${t.id} is not here`
        ),
        t.resolved_by_id == null ? null : unwrap(usersById[t.resolved_by_id])
      );
    })
  );

  setModels(
    "paychecks",
    (allJson.paychecks || []).map((pc) => new Paycheck(pc))
  );
};
