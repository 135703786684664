import { getModels } from "../init_app/globals";
import { findModelById } from "../util/data_util";
import { then } from "../util/util";
import { Building } from "./Building";
import { Room } from "./Room";
import { School } from "./School";
import { User } from "./User";

export type TicketJSON = {
  id: number;
  text: string;
  creator_id: number;
  creator: User;
  room_id: number;
  room: Room;
  resolved_by_id: number | null;
  resolved_by: User | null;
  resolution_notes: string | null;
  resolved_at: string | null;
  created_at: string;
};

export class Ticket {
  id: number;
  text: string;
  creator_id: number;
  creator: User;
  room_id: number;
  room: Room;
  building_id: number;
  building: Building;
  school: School;
  resolved_by_id: number | null;
  resolved_by: User | null;
  resolution_notes: string | null;
  resolved_at: Date | null;
  created_at: Date;

  constructor(
    json: TicketJSON,
    creator: User,
    room: Room,
    resolved_by: User | null
  ) {
    this.id = json.id;
    this.text = json.text;
    this.creator_id = json.creator_id;
    this.creator = creator;
    this.room_id = json.room_id;
    this.room = room;
    this.building = room.building;
    this.building_id = this.building.id;
    this.school = this.building.school;
    this.resolved_by_id = json.resolved_by_id;
    this.resolved_by = resolved_by;
    this.resolution_notes = json.resolution_notes;
    this.resolved_at = then(json.resolved_at, (d) =>
      d == null ? null : new Date(d + "Z")
    );
    this.created_at = new Date(json.created_at + "Z");
  }

  getRoom(): Room {
    return findModelById(getModels("rooms"), this.room_id);
  }

  isBuilding(): this is Building {
    return false;
  }

  getSchool(): School {
    return findModelById(getModels("buildings"), this.building_id).school;
  }
}
