import { Payscale } from "../../models/Payscale";
import { User } from "../../models/User";
import { centsToDollarString } from "../../util/util";
import { money_shorts } from "../../constants/shorts";
import { install_status_colors } from "../../constants/colors";

let key1 = 1;

const renderPayscaleTd = (short: string, payscale: Payscale): JSX.Element => {
  const key = "payscale_" + key1++;
  const str = centsToDollarString(payscale.short_cents[short]!);

  return <td key={key}>{str}</td>;
};

type PayscalesTableProps = {
  user: User;
};

function PayscalesTable(props: PayscalesTableProps): JSX.Element {
  const uninstalled_payscale = Payscale.find("uninstalled", props.user);
  const removed_payscale = Payscale.find("removed", props.user);

  return (
    <div>
      <div>
        <span className="ms-2">
          <b>prices:</b>
        </span>
        <span className="ms-2">total</span>
        <span
          className="ms-2 "
          style={{ color: install_status_colors.uninstalled }}
        >
          uninstall
        </span>
        <span className="ms-2" style={{ color: install_status_colors.removed }}>
          removal
        </span>
      </div>
      <table className="muhtable">
        <thead>
          <tr>
            {money_shorts.map((s) => (
              <th key={key1++}>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr
            className="text_uninstalled"
            style={{ color: install_status_colors.uninstalled }}
          >
            {money_shorts.map((s) => {
              return renderPayscaleTd(s, uninstalled_payscale);
            })}
          </tr>
          <tr
            className="text_removed"
            style={{ color: install_status_colors.removed }}
          >
            {money_shorts.map((s) => {
              return renderPayscaleTd(s, removed_payscale);
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PayscalesTable;
