import { account_id } from "../init_app/globals";

export const shorts =
  account_id === 2
    ? ["MF7", "MF3", "LOF", "FFS", "MFS"]
    : ["PM", "BL", "MF", "FFC", "SR", "BS", "LBX", "TV32", "TV40", "DFT", "FT"];

export const short_shorts =
  account_id === 2
    ? ["MF7", "MF3", "LOF", "FFS", "MFS"]
    : ["PM", "BL", "MF", "FFC", "SR", "BS", "LBX", "TV", "FT"];

export const money_shorts =
  account_id === 2
    ? ["MF7", "MF3", "LOF", "FFS", "MFS"]
    : ["PM", "BL", "MF", "FFC", "SR", "BS", "LBX", "TV", "FT"];

export const short_order =
  account_id === 2
    ? ["MF7", "MF3", "LOF", "FFS", "MFS"]
    : ["PM", "BL", "SR", "BS", "MF", "FFC", "FT", "DFT", "LBX", "TV32", "TV40"];

type StringMap = {
  [key: string]: string;
};

const shortShortMap: StringMap = {
  PM: "PM",
  BL: "BL",
  MF: "MF",
  FFC: "FFC",
  SR: "SR",
  BS: "BS",
  LBX: "LBX",
  TV32: "TV",
  TV40: "TV",
  FT: "FT",
  DFT: "FT",
  MF7: "MF7",
  MFS: "MFS",
  FFS: "FFS",
  MF3: "MF3",
  LOF: "LOF",
};

export const getShortShort = (short: string): string => {
  const short_short = shortShortMap[short];

  if (short_short == null) {
    throw new Error(`${short} couldnt be mapped to short_short`);
  }

  return short_short;
};

export type Count =
  | "mf7_count"
  | "mf3_count"
  | "lof_count"
  | "bl_count"
  | "mf_count"
  | "ffc_count"
  | "sr_count"
  | "ft_count"
  | "dft_count"
  | "bs_count"
  | "lbx_count"
  | "pm_count"
  | "mfs_count"
  | "ffs_count"
  | "tv32_count"
  | "tv40_count";

export const counts: Count[] =
  account_id === 2
    ? ["mf3_count", "mf7_count", "lof_count", "mfs_count", "ffs_count"]
    : [
        "bl_count",
        "mf_count",
        "ffc_count",
        "sr_count",
        "ft_count",
        "dft_count",
        "bs_count",
        "lbx_count",
        "pm_count",
        "tv32_count",
        "tv40_count",
      ];
