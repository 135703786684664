import List from "@material-ui/core/List";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MuiDrawer from "@material-ui/core/Drawer";
import { router, store } from "../init_app/globals";
import PiggyBankIcon from "./icons/PiggyBankIcon";
import BuildIcon from "@material-ui/icons/Build";
import PeopleIcon from "@material-ui/icons/People";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Divider from "@material-ui/core/Divider";
import { toggleDarkmode } from "../util/app_util";
import { ApiRequest } from "../libs/ApiRequest";
import HomeIcon from "@material-ui/icons/Home";
import MoneyIcon from "@material-ui/icons/Money";
import RefreshIcon from "@material-ui/icons/Refresh";
import BarChartIcon from "@material-ui/icons/BarChart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const closeDrawer = (): void => {
  store.setState("drawerOpen", false);
};

const initGo = (path: string) => (): void => {
  closeDrawer();
  setTimeout(() => {
    router.go(path);
  }, 1);
};

const refreshApp = (): void => {
  window.location.reload();
};

const clickSearch = (): void => {
  const resp = window.prompt("search by student name");

  if (resp != null && resp.trim() !== "") {
    const path = `/jobs/search/${encodeURIComponent(resp)}`;
    window.resetList();
    initGo(path)();
  }
};

let working = false;

function logout(e: React.MouseEvent<HTMLElement>): void {
  e.preventDefault();

  if (working) {
    return;
  }

  working = true;
  ApiRequest.perform({
    url: "/users/sign_out.json",
    method: "DELETE",
    onSuccess: (): void => {
      window.location.reload();
    },
    onError: () => {
      alert("couldn't log out: an error occurred");
    },
    onDone: () => {
      working = false;
    },
  });
}

const list = (): JSX.Element | null => {
  const currentUser = store.getState("currentUser");
  if (currentUser == null) {
    return null;
  }
  return (
    <div id="list" role="presentation" style={{ width: 250 }}>
      <List>
        <ListItem button onClick={initGo("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={`Home`} />
        </ListItem>

        <ListItem button onClick={initGo("/jobs")}>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary={`Jobs`} />
        </ListItem>

        <ListItem button key={"search"} onClick={clickSearch}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary={"Search"} />
        </ListItem>

        <ListItem button onClick={initGo("/workers")}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={`Workers`} />
        </ListItem>

        <ListItem button key={"piggy_bank"} onClick={initGo("/your_work")}>
          <ListItemIcon>
            <PiggyBankIcon />
          </ListItemIcon>
          <ListItemText primary={"Piggy Bank"} />
        </ListItem>

        <ListItem button key={"stats"} onClick={initGo("/stats")}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={"Stats"} />
        </ListItem>

        <ListItem button key={"bonus_bucks"} onClick={initGo("/bonus_bucks")}>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary={"Bonus Bucks"} />
        </ListItem>

        <ListItem button key={"profile"} onClick={initGo("/profile")}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary={"Profile"} />
        </ListItem>

        <ListItem button key={"truck_loads"} onClick={initGo("/truck_loads")}>
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary={"Load Tickets"} />
        </ListItem>

        <ListItem
          button
          key={"needs_attention"}
          onClick={initGo("/needs_attention_tickets")}
        >
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText primary={"Needs Attention"} />
        </ListItem>

        {currentUser.admin && (
          <ListItem button key={"payscales"} onClick={initGo("/payscales")}>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary={"Payscales"} />
          </ListItem>
        )}

        <Divider />

        <List>
          <ListItem button key={"darkmode_toggle"} onClick={toggleDarkmode}>
            <ListItemIcon>
              <SettingsBrightnessIcon />
            </ListItemIcon>
            <ListItemText primary={"Toggle dark/light mode"} />
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem button key={"refresh_app"} onClick={refreshApp}>
            <ListItemIcon>
              <RefreshIcon />
            </ListItemIcon>
            <ListItemText primary={"Refresh App"} />
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem button key="logout" onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </List>
    </div>
  );
};

export const AppDrawer = (): JSX.Element | null => {
  const drawerOpen = store.useNonNullState("drawerOpen");

  return (
    <MuiDrawer
      open={drawerOpen}
      onClose={closeDrawer}
      style={{ maxWidth: 250 }}
    >
      {list()}
    </MuiDrawer>
  );
};
