import React from "react";
import strftime from "strftime";
import { Ticket } from "../../models/Ticket";
import { Building } from "../../models/Building";
import { getModels, router, store } from "../../init_app/globals";
import { intersperseBuildings } from "../../util/app_util";
import { filterModelsByAttr } from "../../util/data_util";

const clickRow = (ticket: Ticket): void => {
  router.go(`/tickets/${ticket.id}/room`);
};

const style = { minHeight: 60 };
const classes = "next-bottom-border d-flex align-items-center pl-1";
const renderTicket = (ticket: Ticket): JSX.Element => {
  const key = `ticket-${ticket.id}`;
  const room = ticket.getRoom();

  const created_at_str = strftime("%b %e %l:%M %p", ticket.created_at);

  return (
    <div
      key={key}
      style={style}
      className={classes}
      onClick={(): void => clickRow(ticket)}
    >
      <div>
        <span className="fw-500">{room.wingName()}:</span>
        {ticket.text}
        <span> -- created {created_at_str}</span>
      </div>
    </div>
  );
};

const renderBuilding = (b: Building): JSX.Element => {
  const key = `na-building-${b.id}`;

  return (
    <div
      key={key}
      className="building next-bottom-border d-flex align-items-center p-2"
      style={style}
    >
      <span className="fw-500">
        {b.shown_name} - {b.school.name}
      </span>
    </div>
  );
};

const TicketsList = (): JSX.Element => {
  let tickets = filterModelsByAttr(
    getModels("tickets"),
    "resolved_by_id",
    null
  );

  const filter = window.App.filter;

  const school_id = filter?.school_id;
  const building_ids = filter?.building_ids;

  if (school_id != null) {
    if (building_ids != null && building_ids.length > 0) {
      tickets = tickets.filter(
        (t) =>
          building_ids.includes(t.building_id) && t.getSchool().id === school_id
      );
    } else {
      tickets = tickets.filter((t) => t.getSchool().id === school_id);
    }
  }

  const length = tickets.length;

  React.useEffect(() => {
    const title = `Needs Attention Tickets (${length})`;

    const state = store.state;

    if (school_id == null) {
      state.title = title;
    } else {
      let subtitle = "filtering by school";

      if (building_ids != null && building_ids.length > 0) {
        subtitle = "filtering by school & buildings";
      }
      state.title = title;
      state.subtitle = subtitle;
    }

    store.forceUpdate("title");
  }, [length, school_id, building_ids]);

  const tickets_w_buildings = intersperseBuildings(
    tickets,
    getModels("buildings")
  );

  return (
    <div className="fit">
      {tickets_w_buildings.map((item: Ticket | Building) =>
        item.isBuilding() ? renderBuilding(item) : renderTicket(item)
      )}
    </div>
  );
};

export default TicketsList;
