import { getModels } from "../init_app/globals";
import { Filter } from "../models/Filter";
import { Room } from "../models/Room";
import { filterModelsByInclusion } from "../util/data_util";

export const getSearchedRooms = (search: string): Room[] => {
  return getModels("rooms").filter((r) => r.searchStudent(search));
};

export const getFilteredRooms = (filter: Filter): Room[] => {
  const school = filter.getSchool();

  let rooms = getModels("rooms").filter(
    (r) => r.building.school_id === school.id && r.installs!.length > 0
  );

  if (filter.building_ids.length) {
    rooms = filterModelsByInclusion(rooms, "building_id", filter.building_ids);
  }

  if (filter.has_field_notes) {
    rooms = rooms.filter((r) => r.getFieldNotes().length > 0);
  }

  if (filter.has_special_instructions) {
    rooms = rooms = rooms.filter((r) => r.hasSpecialInstructions());
  }

  if (filter.has_unresolved_tickets) {
    rooms = rooms = rooms.filter((r) => r.unresolved_tickets_count > 0);
  }

  if (filter.has_resolved_tickets) {
    rooms = rooms = rooms.filter(
      (r) => r.tickets_count > r.unresolved_tickets_count
    );
  }

  const statuses = filter.install_statuses;
  if (filter.product_ids.length) {
    if (filter.no_upgrades) {
      rooms = rooms.filter((r) =>
        r.hasProductsWithNoUpgrades(filter.product_ids, statuses)
      );
    } else {
      rooms = rooms.filter((r) => r.hasProducts(filter.product_ids, statuses));
    }
  } else if (statuses.length) {
    rooms = rooms.filter((r) => r.hasInstallStatuses(statuses));
  }

  if (filter.upgrade_ids.length) {
    rooms = rooms.filter((r) => r.hasUpgrades(filter.upgrade_ids));
  }

  if (filter.no_upgrades) {
    rooms = rooms.filter((r) => r.hasNoUpgrades());
  }

  if (filter.locked !== undefined) {
    rooms = rooms.filter((r) => r.locked === filter.locked);
  }

  if (filter.occupied) {
    rooms = rooms.filter((r) => r.occupied);
  }

  if (filter.only_available_jobs) {
    rooms = rooms.filter((r) => r.hasAvailableJobs());
  }

  const lb = filter.leaving_before;

  if (lb != null) {
    rooms = rooms.filter((r) => r.leavingBefore(lb));
  }

  return rooms;
};
