import React from "react";
import { centsToDollarString } from "../../util/util";
import { ShortShortMoneyStats } from "../../helpers/stats_helpers";
import { money_shorts } from "../../constants/shorts";
import { install_status_colors } from "../../constants/colors";

let key1 = 1;

const renderMoneyTd = (cents: number): JSX.Element => {
  const key = "brah" + key1++;

  return <td key={key}>{centsToDollarString(cents)}</td>;
};

interface MoneyTableProps {
  money: ShortShortMoneyStats;
}

function MoneyTable(props: MoneyTableProps): JSX.Element {
  const { money } = props;

  return (
    <React.Fragment>
      <table className="muhtable">
        <thead>
          <tr>
            <th></th>
            {money_shorts.map((s) => (
              <th key={key1++}>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            {money_shorts.map((s) => {
              return renderMoneyTd(money[s]!.total_amount);
            })}
          </tr>

          <tr style={{ color: install_status_colors.uninstalled }}>
            <td></td>
            {money_shorts.map((s) => {
              return renderMoneyTd(money[s]!.uninstalled_amount);
            })}
          </tr>
          <tr style={{ color: install_status_colors.removed }}>
            <td></td>
            {money_shorts.map((s) => {
              return renderMoneyTd(money[s]!.removed_amount);
            })}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default MoneyTable;
