import {
  blue,
  lightBlue,
  red,
  orange,
  lime,
  deepPurple,
  amber,
  blueGrey,
  green,
} from "@material-ui/core/colors";

export const colors = {
  primary: blue[500],
  secondary: lime[500],
  danger: red[500],
};

export const install_status_colors = {
  pending_delivery: green[500],
  delivered: amber[500],
  installed: blueGrey[500],
  pending_removal: red[500],
  uninstalled: orange[500],
  missing: deepPurple[500],
  removed: lightBlue[500],
};
