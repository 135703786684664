import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";

const CenterAll = (props: BoxProps): JSX.Element => {
  return (
    <Box
      {...props}
      display="flex"
      alignItems="center"
      justifyContent="center"
    ></Box>
  );
};

export default CenterAll;
