import { User } from "../models/User";
import { unwrapOr } from "../util/util";

export type SignupFormState = {
  email: string;
  password: string;
  password_confirmation: string;
};

export type LoginFormState = {
  email: string;
  password: string;
  emailError?: string;
  error: boolean;
};

export type BasicInfoFormState = {
  user_id: number | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  username: string | null;
  phone: string | null;
  agreed_to_terms: boolean;
  errors: string[];
  working: boolean;
};

const authRegex = /^\/(login|signup)/;
export const onAuthPage = (): boolean => {
  return authRegex.test(window.location.pathname);
};

export const getBasicInfoFormState = (
  user: User | null
): BasicInfoFormState => {
  return {
    user_id: unwrapOr(user?.id, null),
    email: unwrapOr(user?.email, null),
    first_name: unwrapOr(user?.first_name, null),
    last_name: unwrapOr(user?.last_name, null),
    username: unwrapOr(user?.username, null),
    phone: unwrapOr(user?.phone, null),
    agreed_to_terms: unwrapOr(user?.agreed_to_terms, false),
    working: false,
    errors: [],
  };
};
