import AlertTitle from "@material-ui/lab/AlertTitle";
import CloseableAlert from "./CloseableAlert";

interface ErrorAlertProps {
  errors: string[];
  closeAlert: () => void;
  className?: string;
}

const CloseableErrorAlert = (props: ErrorAlertProps): JSX.Element => {
  const errors = props.errors;

  return (
    <CloseableAlert
      open={errors.length > 0}
      closeAlert={props.closeAlert}
      className={props.className}
      variant="outlined"
      severity="error"
    >
      <AlertTitle>The following errors occurred:</AlertTitle>
      <ul>
        {errors.map((e, index) => (
          <li key={`signup_err_idx_${index}`}>{e}</li>
        ))}
      </ul>
    </CloseableAlert>
  );
};

export default CloseableErrorAlert;
