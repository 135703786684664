import { getModels, getStore, store } from "./globals";
import StateRouter from "../libs/StateRouter";
import { AppState } from "./store";
import { Required } from "utility-types";
import { findModelById } from "../util/data_util";
import { getFullProfileFormState } from "../helpers/profile_helpers";

const homeRoute = (): RouterState => {
  return { viewShown: "Home", rightIcon: "jobs" };
};

const loginRoute = (): RouterState => {
  return {
    title: "Login",
    rightIcon: null,
    viewShown: "Login",
    login_form: { email: "", password: "", error: false },
  };
};

const signupRoute = (): RouterState => {
  return {
    title: "Signup",
    viewShown: "Signup",
    rightIcon: null,
    errors: [],
    working: false,
    signup_form: { email: "", password: "", password_confirmation: "" },
  };
};

const jobsRoute = (): RouterState => {
  return { viewShown: "JobsView", rightIcon: "filter" };
};

const searchJobsRoute = (search: string): RouterState => {
  return {
    viewShown: "JobsView",
    search,
    rightIcon: "pencil",
  };
};

const workersRoute = (): RouterState => {
  return {
    viewShown: "Workers",
    title: "Workers",
    tab: 0,
    rightIcon: "jobs",
  };
};

const jobDetailRoute = (room_id: string): RouterState => {
  const room = findModelById(getModels("rooms"), parseInt(room_id));
  return {
    viewShown: "JobDetail",
    room,
    drill_href: "/jobs",
    rightIcon: "filter",
  };
};

const jobSearchDetailRoute = (room_id: string, search: string): RouterState => {
  const room = findModelById(getModels("rooms"), parseInt(room_id));

  return {
    viewShown: "JobDetail",
    room,
    search,
    drill_href: `/jobs/search/${search}`,
    rightIcon: "pencil",
  };
};

const buildingDetailRoute = (
  building_id: string,
  search?: string
): RouterState => {
  const building = findModelById(getModels("buildings"), parseInt(building_id));

  const drill_href = search == null ? "/jobs" : `/jobs/search/${search}`;
  const rightIcon = search == null ? "filter" : "pencil";

  return {
    viewShown: "BuildingDetail",
    building,
    search,
    tab: 0,
    drill_href,
    rightIcon,
  };
};

const workerDetailRoute = (user_id: string): RouterState => {
  const user = findModelById(getModels("users"), parseInt(user_id));
  const currentUser = store.getNonNullState("currentUser");
  const drill_href = currentUser.id === user.id ? undefined : "/workers";

  return {
    title: `${user.username}'s Work`,
    viewShown: "UserWork",
    rightIcon: "jobs",
    user,
    tab: 0,
    date: new Date(),
    subtab: 0,
    drill_href,
  };
};

const workerRoomRoute = (
  worker_id_str: string,
  room_id_str: string
): RouterState => {
  const user_id = parseInt(worker_id_str);
  const room = findModelById(getModels("rooms"), parseInt(room_id_str));

  return {
    room,
    rightIcon: "jobs",
    viewShown: "WorkerJobDetail",
    drill_href: `/workers/${user_id}/daily_earnings`,
  };
};

const workerRouteBonusBucks = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const date = new Date();
  const currentUser = store.getNonNullState("currentUser");
  const drill_href = currentUser.id === user.id ? undefined : "/workers";

  return {
    viewShown: "UserWork",
    rightIcon: "jobs",
    tab: 1,
    subtab: 0,
    user,
    date,
    drill_href,
  };
};

const workerRouteDailyEarnings = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const date = new Date();
  const currentUser = store.getNonNullState("currentUser");
  const drill_href = currentUser.id === user.id ? undefined : "/workers";

  return {
    viewShown: "UserWork",
    title: `${user.username}'s Work`,
    rightIcon: "jobs",
    tab: 0,
    subtab: 1,
    user,
    date,
    drill_href,
  };
};

const workerRouteReceived = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const date = new Date();
  const currentUser = store.getNonNullState("currentUser");
  const drill_href = currentUser.id === user.id ? undefined : "/workers";

  return {
    viewShown: "UserWork",
    rightIcon: "jobs",
    tab: 2,
    subtab: 0,
    user,
    date,
    drill_href,
  };
};

const yourWorkRoute = (): RouterState => {
  const user = store.getNonNullState("currentUser");
  const date = new Date();

  return {
    viewShown: "UserWork",
    rightIcon: "jobs",
    tab: 0,
    subtab: 0,
    user,
    date,
  };
};

const badRouterHandler = (): RouterState => {
  return { viewShown: "BadRoute", rightIcon: "jobs" };
};

const statsRoute = (): RouterState => {
  return { title: "Stats", viewShown: "Stats", tab: 0, rightIcon: "jobs" };
};

const bonusBucksRoute = (): RouterState => {
  return { title: "Bonus Bucks", viewShown: "BonusBucks", rightIcon: "jobs" };
};

const payscalesRoute = (): RouterState => {
  return { title: "Payscales", viewShown: "PayscaleList", rightIcon: "jobs" };
};

const payscaleDetailRoute = (ps_id_str: string): RouterState => {
  const payscale = findModelById(getModels("payscales"), parseInt(ps_id_str));

  return {
    title: "Payscale Detail",
    viewShown: "PayscaleDetail",
    drill_href: "/payscales",
    rightIcon: "jobs",
    payscale,
  };
};

const profileRoute = (): RouterState => {
  const user = store.getNonNullState("currentUser");

  return {
    title: "Update Your Profile",
    viewShown: "Profile",
    full_profile_form: getFullProfileFormState(user),
    rightIcon: "jobs",
  };
};

const ticketsRoute = (): RouterState => {
  return { title: "Tickets", viewShown: "TicketsList", rightIcon: "filter" };
};

const truckLoadsRoute = (): RouterState => {
  return { title: "Truck Loads", viewShown: "TruckLoads", rightIcon: "jobs" };
};

const ticketRoomRoute = (ticket_id_str: string): RouterState => {
  const ticket_id = parseInt(ticket_id_str);
  const ticket = findModelById(getModels("tickets"), ticket_id);
  const room = findModelById(getModels("rooms"), ticket.room_id);
  const title = room.building.school.name;

  return {
    title,
    viewShown: "JobDetail",
    rightIcon: "filter",
    drill_href: "/needs_attention_tickets",
    room,
  };
};

const truckLoadDetailRoute = (tl_id_str: string): RouterState => {
  const truck_load = findModelById(
    getModels("truck_loads"),
    parseInt(tl_id_str)
  );

  return {
    title: "Truck Load Detail",
    viewShown: "TruckLoadDetail",
    rightIcon: "jobs",
    truck_load,
    drill_href: "/truck_loads",
  };
};

const truckLoadDetailRouteForWorker = (
  user_id_str: string,
  tl_id_str: string
): RouterState => {
  const truck_load = findModelById(
    getModels("truck_loads"),
    parseInt(tl_id_str)
  );

  const user = findModelById(getModels("users"), parseInt(user_id_str));

  return {
    title: "Truck Load Detail",
    viewShown: "TruckLoadDetail",
    rightIcon: "jobs",
    truck_load,
    drill_href: `/workers/${user.id}/daily_earnings`,
  };
};

const routeMappers = {
  "/": homeRoute,
  "/login": loginRoute,
  "/signup": signupRoute,
  "/jobs": jobsRoute,
  "/jobs/search/:search": searchJobsRoute,
  "/workers": workersRoute,
  "/jobs/:id": jobDetailRoute,
  "/jobs/:id/search/:search": jobSearchDetailRoute,
  "/buildings/:id": buildingDetailRoute,
  "/buildings/:id/search/:search": buildingDetailRoute,
  "/stats": statsRoute,
  "/bonus_bucks": bonusBucksRoute,
  "/truck_loads": truckLoadsRoute,
  "/truck_loads/:id": truckLoadDetailRoute,
  "/workers/:id": workerDetailRoute,
  "/workers/:id/rooms/:id": workerRoomRoute,
  "/workers/:id/bonus_bucks": workerRouteBonusBucks,
  "/workers/:id/daily_earnings": workerRouteDailyEarnings,
  "/workers/:id/received": workerRouteReceived,
  "/workers/:id/truck_loads/:id": truckLoadDetailRouteForWorker,
  "/tickets/:id/room": ticketRoomRoute,
  "/your_work": yourWorkRoute,
  "/payscales": payscalesRoute,
  "/payscales/:id": payscaleDetailRoute,
  "/profile": profileRoute,
  "/needs_attention_tickets": ticketsRoute,
};

export const transformState = (
  { colormode, working, drawerOpen, currentUser }: AppState,
  state: RouterState
): AppState => {
  const nextState: AppState = { colormode, working, drawerOpen, currentUser };
  Object.assign(nextState, state);
  return nextState;
};

const handler = (state: RouterState): void => {
  const store = getStore();

  const nextState = transformState(store.state, state);
  store.state = nextState;
  store.forceUpdate("viewShown");
};

export type RouterState = Required<
  Partial<AppState>,
  "viewShown" | "rightIcon"
>;

export const router = new StateRouter<RouterState>({
  routeMappers,
  handler,
  badRouterHandler,
});
