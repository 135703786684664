import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { getBasicInfoFormState, onAuthPage } from "../helpers/auth_helpers";
import { getAllData, router, store } from "../init_app/globals";
import { RouterState, transformState } from "../init_app/router";
import { AppState } from "../init_app/store";
import { ApiRequest } from "../libs/ApiRequest";
import { User } from "../models/User";
import { AllJSONResponse } from "../types/data_types";
import { handleData } from "../util/handle_data";
import { then } from "../util/util";
import { AppDrawer } from "./AppDrawer";
import BasicInfoForm from "./auth/BasicInfoForm";
import { FilterModal } from "./filter/FilterModal";
import MyAppBar from "./my_app_bar/MyAppBar";
import { getView } from "./util/GetView";

const dataRequest = new ApiRequest({
  url: `/removals_api/all_worker_json.json`,
  method: "GET",
  onSuccess: (resp: unknown): void => {
    const json = resp as AllJSONResponse;
    if (json.auth?.currentUser != null && json.data != null) {
      handleData(json.data);
      window.D = getAllData();

      const currentUser = new User(json.auth.currentUser);
      store.state.currentUser = currentUser;

      const state = router.stateFromPath(window.location.pathname);
      const { colormode, working, drawerOpen } = store.state;

      const appState = then(state, (s: RouterState): AppState => {
        const hold: AppState = {
          colormode,
          working,
          drawerOpen,
          currentUser,
        };
        Object.assign(hold, s);
        return hold;
      });

      store.state = appState;
      store.forceUpdate("viewShown");
    } else {
      store.state.currentUser = undefined;

      if (onAuthPage()) {
        const routerState = router.stateFromPath(window.location.pathname);
        store.state = transformState(store.state, routerState);

        setTimeout(() => {
          store.forceUpdate("viewShown");
        });
      } else {
        router.go("/login");
      }
    }
  },
  onError: (): void => {
    alert("an error occurred while loading the data");
  },
});

function fetchData(): void {
  if (dataRequest.status > 0) {
    return;
  }

  dataRequest.perform();
}

export const Main = (): JSX.Element => {
  const viewShown = store.useState("viewShown");
  const currentUser = store.useState("currentUser");

  if (viewShown == null) {
    fetchData();
    return <div className="App"></div>;
  }

  if (currentUser == null) {
    return (
      <div className="App d-flex flex-column" style={{ height: "100vh" }}>
        <MyAppBar />
        <div
          id="container"
          className="flex-grow-1"
          style={{ overflow: "scroll" }}
        >
          <Box>{getView(viewShown)}</Box>
        </div>
        <AppDrawer />
      </div>
    );
  } else {
    if (currentUser.canPassGo()) {
      return (
        <div className="App d-flex flex-column" style={{ height: "100vh" }}>
          <MyAppBar />
          <div
            id="container"
            className="flex-grow-1"
            style={{ overflow: "scroll" }}
          >
            <Box>{getView(viewShown)}</Box>
          </div>
          <AppDrawer />
          <FilterModal />
        </div>
      );
    } else {
      store.state.basic_info_form = getBasicInfoFormState(currentUser);

      return (
        <div className="App">
          <Typography variant="h4">Fill-in Your Profile</Typography>
          <BasicInfoForm />
        </div>
      );
    }
  }
};
